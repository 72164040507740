import { envs } from 'application/envHandler'
import apiClient from 'core/api'
import { RTFSettingsTab } from '../../config/sidebar'
import { AxiosFetchDeviceResponse } from './types'

export async function fetchDevice(udid: string) {
  return apiClient.get('/device-hologram', {
    baseURL: envs.REACT_APP_API_URL,
    params: {
      query: `custom.udid:${udid}`,
      limit: 1,
    },
  }) as Promise<AxiosFetchDeviceResponse>
}

function isLegacyVersion(softwareVersion: string) {
  // Checks for Major and Minor version (the first numbers in the pattern "X.Y...")
  const majorAndMinorVersionRegex = new RegExp('^[0-9].[0-9]')
  const majorAndMinorVersion = Number(softwareVersion.match(majorAndMinorVersionRegex) || [][0])
  if(majorAndMinorVersion < 7.2) {
    return true
  }

  // Checks if version is 7.2 or 7.2.A...
  if(softwareVersion.match(new RegExp('^7.2+.A'))) {
    // Replace the pattern "7.2.A" for blank and parse it to number (patch version)
    const patchVersion = Number(softwareVersion.replace(new RegExp('^7.[0-9]+.[a-zA-Z]'), '').split('.')[0])
    return !patchVersion || patchVersion < 113
  }

  return false
}

function matchesHardwareModel(hardwareModel: string) {
  const targetHardwareModels = ['DI362-UHD', 'DCI362-UHD', 'DCIW387-UHD', 'DIW387-UHD', 'DTIW387-UHD']

  return targetHardwareModels.includes(hardwareModel)
}

export function getFirstActiveTab(hardwareModel: string, softwareVersion: string) {
  if(matchesHardwareModel(hardwareModel) && isLegacyVersion(softwareVersion)) {
    return RTFSettingsTab.logLevels
  }

  return RTFSettingsTab.logProfiles
}
