import { CloseModalFunction, OpenModalFunction } from 'core/modal/redux/types';
import { TFunction } from 'i18next';
import { showModalAndExecCommand } from 'modules/operational/rtf/redux/settings/actions';
import { ExecuteCommandType, JSONParameters, ResetStatesFunction, RTFLogProfilesSettings, RunCommandsFunction } from 'modules/operational/rtf/redux/settings/types';
import React, { useCallback, useState } from 'react';
import ButtonContainer from './common/ButtonContainer';
import CustomSelect from './common/CustomSelect';
import Field from './common/Field';
import GridContainer from './common/GridContainer';

type LogProfilesProps = {
  settings: RTFLogProfilesSettings
  t: TFunction
  deviceId: string
  connected: boolean
  openModal: OpenModalFunction
  closeModal: CloseModalFunction
  runCommands: RunCommandsFunction
  executeCommand: ExecuteCommandType
}

function turnOn(
  deviceId: string,
  openModal: OpenModalFunction,
  closeModal: CloseModalFunction,
  runCommands: RunCommandsFunction,
  appProfile: string,
  appProfileCustomValue: string,
  osProfile: string,
  osProfileCustomValue: string,
  window: string,
  connected: boolean,
  executeCommand: ExecuteCommandType,
  resetStates: ResetStatesFunction,
) {
  const jsonParameters: JSONParameters = { window: Number(window) }

  if(appProfile) {
    jsonParameters.appProfileLevel = appProfile === 'custom' ? appProfileCustomValue : appProfile
  }

  if(osProfile) {
    jsonParameters.osProfileLevel = osProfile === 'custom' ? osProfileCustomValue : osProfile
  }

  const commandConfig = {
    command: 'loggingEnable',
    key: 'log_profiles',
    jsonParameters,
  }

  showModalAndExecCommand(deviceId, openModal, closeModal, runCommands, commandConfig, connected, executeCommand, resetStates)
}

function turnOff(
  deviceId: string,
  openModal: OpenModalFunction,
  closeModal: CloseModalFunction,
  runCommands: RunCommandsFunction,
  connected: boolean,
  settings: RTFLogProfilesSettings,
  executeCommand: ExecuteCommandType,
) {
  const commandConfig = {
    command: 'loggingEnable',
    key: 'logging_enable',
    jsonParameters: {
      appProfileLevel: settings.appProfileLevel.options[1].value,
      osProfileLevel: settings.osProfileLevel.options[1].value,
      window: 0,
    }
  }

  showModalAndExecCommand(deviceId, openModal, closeModal, runCommands, commandConfig, connected, executeCommand)
}

export default function LogProfiles({ deviceId, settings, t, openModal, closeModal, runCommands, connected, executeCommand }: LogProfilesProps) {
  const [appProfile, setAppProfile] = useState(settings.appProfileLevel.options[0].value)
  const [appProfileCustomValue, setAppProfileCustomValue] = useState('')

  const [osProfile, setOsProfile] = useState(settings.osProfileLevel.options[0].value)
  const [osProfileCustomValue, setOsProfileCustomValue] = useState('')

  const [window, setWindow] = useState(settings.window.options[0].value)

  const resetStates = useCallback(() => {
    setAppProfile(settings.appProfileLevel.options[0].value)
    setAppProfileCustomValue('')
    setOsProfile(settings.osProfileLevel.options[0].value)
    setOsProfileCustomValue('')
    setWindow(settings.window.options[0].value)
  }, [])

  return (
    <GridContainer gridTemplateColumns='repeat(3, 1fr)'>
      <Field>
        <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
          <div className="column is-12">
            <h6 className="text is-h6 is-highlighted">{t('settings.logs.app_profile')}</h6>
          </div>
          <div className="column is-12 has-padding-top-none has-padding-bottom-none">
            <h6 className="text is-h6 is-bold">{t('settings.current_value')}</h6>
          </div>
        </div>
        <CustomSelect
          options={settings.appProfileLevel.options}
          selectValue={appProfile}
          setSelectValue={setAppProfile}
          customValue={appProfileCustomValue}
          setCustomValue={setAppProfileCustomValue}
        />
      </Field>
      <Field>
        <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
          <div className="column is-12">
            <h6 className="text is-h6 is-highlighted">{t('settings.logs.os_profile')}</h6>
          </div>
          <div className="column is-12 has-padding-top-none has-padding-bottom-none">
            <h6 className="text is-h6 is-bold">{t('settings.current_value')}</h6>
          </div>
        </div>
        <CustomSelect
          options={settings.osProfileLevel.options}
          selectValue={osProfile}
          setSelectValue={setOsProfile}
          customValue={osProfileCustomValue}
          setCustomValue={setOsProfileCustomValue}
        />
      </Field>
      <Field>
        <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
          <div className="column is-12">
            <h6 className="text is-h6 is-highlighted">{t(`settings.logs_window`)}</h6>
          </div>
          <div className="column is-12 has-padding-top-none has-padding-bottom-none">
            <h6 className="text is-h6 is-bold">{t(`settings.column_value`)}</h6>
          </div>
        </div>
        <div className="has-padding-left-xs has-padding-right-xs">
          <div className="select is-fullwidth">
            <select
              name="window"
              value={window}
              onChange={(e) => setWindow(e.target.value)}
            >
              {settings.window.options.map(
                (item, key: number) => (
                  <option key={key} value={item.value}>
                    {t(item.label)}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
      </Field>
      <ButtonContainer gridColumn={3}>
        <button
          className={`button-link dropdown-link has-padding-right-md ${isDirty(appProfile, osProfile, window, settings) ? 'button-action' : ''}`}
          disabled={!isDirty(appProfile, osProfile, window, settings)}
          onClick={() => resetStates()}
        >
          {t(`general.clear`)}
        </button>
        <button
          className="button is-rounded is-larger is-dark"
          onClick={() => turnOff(deviceId, openModal, closeModal, runCommands, connected, settings, executeCommand)}
          disabled={isDirty(appProfile, osProfile, window, settings)}
        >
          {t('settings.logs.button_turnoff')}
        </button>
        <button
          className="button is-rounded is-larger is-primary"
          onClick={() => {
            turnOn(
              deviceId,
              openModal,
              closeModal,
              runCommands,
              appProfile,
              appProfileCustomValue,
              osProfile,
              osProfileCustomValue,
              window,
              connected,
              executeCommand,
              resetStates
            )
          }}
          disabled={!isValid(appProfile, appProfileCustomValue, osProfile, osProfileCustomValue, window)}
        >
          {t('settings.logs.button_turnon')}
        </button>
      </ButtonContainer>
    </GridContainer>
  )
}

function isDirty(appProfile: string, osProfile: string, window: string, settings: RTFLogProfilesSettings) {
  const appProfileIsDirty = appProfile !== settings.appProfileLevel.options[0].value
  const osProfileIsDirty = osProfile !== settings.osProfileLevel.options[0].value
  const windowIsDirty = window !== settings.window.options[0].value
  return appProfileIsDirty || osProfileIsDirty || windowIsDirty
}

function isValid(
  appProfile: string,
  appProfileCustomValue: string,
  osProfile: string,
  osProfileCustomValue: string,
  window: string,
) {
  const appProfileExists = !!appProfile
  const osProfileExists = !!osProfile

  let appProfileCondition = false
  let osProfileCondition = false

  let fieldsCondition = false

  const windowCondition = Number(window) > 0

  if (appProfileExists) {
    appProfileCondition = appProfile === 'custom' ? !!appProfileCustomValue : true
  }

  if (osProfileExists) {
    osProfileCondition = osProfile === 'custom' ? !!osProfileCustomValue : true
  }

  if (appProfileExists && osProfileExists) {
    fieldsCondition = appProfileCondition && osProfileCondition
  }

  if (appProfileExists && !osProfileExists) {
    fieldsCondition = appProfileCondition
  }

  if (osProfileExists && !appProfileExists) {
    fieldsCondition = osProfileCondition
  }

  return fieldsCondition && windowCondition
}
