import Input from 'components/Input'
import { InputFieldTypes, SelectOptions } from 'components/Input/types'
import { getAvailableEventNames } from 'modules/operational/rtf/redux/logs/activity/services'
import { ActivityLog, ActivityLogState, ActivityLogStatusGroup, CancelCommandFunction, ClearActivityLogStateFunction, GetActivityLogFunction } from 'modules/operational/rtf/redux/logs/activity/types'
import React, { useEffect, useState } from 'react'
import Field from './common/Field'
import GridContainer from './common/GridContainer'
import { TFunction } from 'i18next'
import TaskPanelComponent from 'core/job-panel';
import Pagination from 'components/Pagination'
import PerPage from 'components/PerPage'
import { getLocalPaginatedList, getTotalPages } from 'components/Pagination/helpers'
import NoRecords from 'core/no-records'

const STATUS_OPTIONS = [
  {
    label: '',
    value: '',
  },
  {
    label: 'command.label.success',
    value: ActivityLogStatusGroup.success,
  },
  {
    label: 'command.label.in_progress',
    value: ActivityLogStatusGroup.inProgress,
  },
  {
    label: 'command.label.failed',
    value: ActivityLogStatusGroup.failed,
  },
]

type ActivityLogProps = {
  deviceId: string
  getActivityLog: GetActivityLogFunction
  activity: ActivityLogState
  t: TFunction
  cancelCommand: CancelCommandFunction
  clearActivityLogState: ClearActivityLogStateFunction
}

export default function ActivityLogComponent({ getActivityLog, deviceId, activity, t, cancelCommand, clearActivityLogState }: ActivityLogProps) {
  const [jobId, setJobId] = useState('')
  const [status, setStatus] = useState(STATUS_OPTIONS[0].value)
  const [eventName, setEventName] = useState('')
  const [eventNameOptions, setEventNameOptions] = useState<Array<SelectOptions>>([])
  const initialEventDateValue = { start: '', end: ''}
  const [eventDate, setEventDate] = useState(initialEventDateValue)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const isDirty = () => {
    return jobId || status || eventName || eventDate.start || eventDate.end
  }

  const clearFilters = () => {
    setJobId('')
    setStatus('')
    setEventName('')
    setEventDate(initialEventDateValue)
  }

  const onSearch = () => {
    getActivityLog(deviceId, { traceId: jobId, status, eventName, lastManagedStart: eventDate.start, lastManagedEnd: eventDate.end })
    setPage(1)
  }

  useEffect(() => {
    getActivityLog(deviceId)

    return () => {
      clearActivityLogState()
      setPage(1)
    }
  }, [])

  useEffect(() => {
    setEventNameOptions([{ label: '', value: ''}, ...getAvailableEventNames(activity.list)])
  }, [activity.list])

  useEffect(() => {
    setPage(1)
  }, [pageSize])

  return (
    <GridContainer>
      <Field>
        <div className="rtf-activity-logs-search-header">
          <div className="activity-log-search-input">
            <Input
              type={InputFieldTypes.text}
              value={jobId}
              setValue={setJobId}
              labelParams={{
                i18nTag: 'command.field_traceId',
                onClean: () => setJobId('')
              }}
            />
          </div>
          <div className="activity-log-search-input">
            <Input
              type={InputFieldTypes.select}
              value={status}
              setValue={setStatus}
              options={STATUS_OPTIONS}
              labelParams={{
                i18nTag: 'command.field_status',
                onClean: () => setStatus(STATUS_OPTIONS[0].value)
              }}
            />
          </div>
          <div className="activity-log-search-input">
            <Input
              type={InputFieldTypes.select}
              value={eventName}
              setValue={setEventName}
              options={eventNameOptions}
              labelParams={{
                i18nTag: 'command.field_eventName',
                onClean: () => setEventName('')
              }}
            />
          </div>
          <div className="activity-log-search-date-input">
            <Input
              type={InputFieldTypes.datepicker}
              value={eventDate}
              setValue={setEventDate}
              labelParams={{
                i18nTag: 'command.field_lastManaged',
                onClean: () => setEventDate(initialEventDateValue)
              }}
            />
          </div>
          <div className="activity-log-search-button">
            <button
              className="button is-larger is-primary"
              onClick={onSearch}
            >
              {t('general.search')}
            </button>
              <button
                className={`button-link dropdown-link has-padding-right-md ${isDirty() ? 'button-action' : ''}`}
                disabled={!isDirty()}
                onClick={() => clearFilters()}
              >
                {t('general.clear_filters')}
              </button>
          </div>
        </div>
      </Field>
      <Field>
        {activity.list.length ?
          <div className="activity-log-tasks-container">
            <PerPage pageSize={pageSize} setPageSize={setPageSize}/>
            <ul className="panel has-padding-top-xs">
              {getLocalPaginatedList(activity.list, page, pageSize).map((item, index) => (
                <TaskPanelComponent
                item={item}
                showProgressBar={false}
                key={index}
                cancel={() => cancelCommand(deviceId, item.commandTraceId)}
                t={t}
                backgroundGrey
                />))
              }
            </ul>
            <Pagination page={page} setPage={setPage} totalPages={getTotalPages(activity.totalItems, pageSize)}/>
          </div>
          :
          <NoRecords t={t}/>
        }
      </Field>
    </GridContainer>
  )
}
