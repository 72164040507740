import { connect } from 'react-redux'
import { ApplicationState } from 'application/application-redux'
import ContentComponent from './Component'
import { executeCommand } from '../../../redux/settings/actions'
import { open as openModal, close as closeModal } from 'core/modal/redux/actions'
import { runCommands } from 'modules/operational/device/detail/_settings/redux/actions'
import { getHistory, getDownloadURL, clearDownloadURL } from '../../../redux/logs/history/actions'
import { getActivityLog, cancelCommand, clearActivityLogState } from '../../../redux/logs/activity/actions'

const mapStateToProps = (state: ApplicationState) => ({
  device: state.rtf.rtfDevices.current,
  t: state.i18n.t,
  history: state.rtf.rtfLogs.history,
  activity: state.rtf.rtfLogs.activity,
})

export default connect(mapStateToProps, {
  openModal,
  closeModal,
  executeCommand,
  runCommands,
  getHistory,
  getDownloadURL,
  clearDownloadURL,
  getActivityLog,
  cancelCommand,
  clearActivityLogState,
})(ContentComponent)
