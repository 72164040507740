import { envs } from 'application/envHandler'
import apiClient from 'core/api'
import { ActivityLogFilter, ActivityLogStatus, AxiosFetchActivityResponse, ActivityLogStatusGroup, ActivityLogList, ActivityLogEventName, EVENT_NAME_FIELD_LABEL_MAP } from './types'

export async function fetchActivityLog(id: string, filter?: ActivityLogFilter) {
  for(let key in filter){
    const castKey = key as keyof ActivityLogFilter
    if (!filter[castKey]) {
      delete filter[castKey]
    }
  }

  return apiClient.get(`/device/${id}/activity-log`, {
    baseURL: envs.REACT_APP_API_URL,
    params: {
      ...filter,
      includeAssets: 'loggingEnable,loggingFlush'
    },
  }) as Promise<AxiosFetchActivityResponse>
}

export function getStatusGroup(status: ActivityLogStatus) {
  const successStatuses = [ActivityLogStatus.completed, ActivityLogStatus.succeeded]
  if(successStatuses.includes(status)) {
    return ActivityLogStatusGroup.success
  }

  const inProgressStatuses = [ActivityLogStatus.inProgress, ActivityLogStatus.queued, ActivityLogStatus.received, ActivityLogStatus.started]
  if (inProgressStatuses.includes(status)) {
    return ActivityLogStatusGroup.inProgress
  }

  return ActivityLogStatusGroup.failed
}

export function getAvailableEventNames(list: ActivityLogList) {
  const eventNames: Array<string> = []
  list.forEach(entry => {
    if(!eventNames.includes(entry.eventName)) {
      eventNames.push(entry.eventName)
    }
  })

  return eventNames.map(entry => ({ label: EVENT_NAME_FIELD_LABEL_MAP[entry], value: entry }))
}

export function sendCommandCancel(deviceId: string, traceId: string) {
  return apiClient.post(`/device/${deviceId}/activity-log`, {
    deviceId,
    traceId,
  }, {
    baseURL: envs.REACT_APP_API_URL
  })
}
