import { t } from 'i18next'
import React from 'react'
import { SelectInputProps } from '../types'

export default function Select({ options, value, setValue, children }: SelectInputProps) {
  return (
    <div className="input-wrap select">
      <select
        className='input-select'
        value={value} 
        onChange={e => setValue(e.target.value)}
        name="select"
        data-value={value}
      > 
        {options.map(option => <option key={option.value} value={option.value}>{t(option.label)}</option>)}
      </select>
      {children}
    </div>
  )
}
