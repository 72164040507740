import React from 'react'
import Card from 'core/card/card-component'

type FieldProps = {
  children: React.ReactNode
  gridRow?: number
}

export default function Field({ children, gridRow = 1 }: FieldProps) {
  return (
    <div style={{ gridRow: `span ${gridRow}` }}>
      <Card isFullHeight>
        {children}
      </Card>
    </div>
  )
}
