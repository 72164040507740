import { Dispatch } from 'redux'
import { add, remove } from 'core/spinner/redux/actions'
import { addToaster } from 'core/toaster/redux/actions'
import { CommandProps, ExecuteCommandType, GetAssetsValuesSuccessPayload, ResetStatesFunction, RTFSettingsActions, RTF_ASSETS_VALUES_FETCH, RunCommandsFunction } from './types'
import { fetchAssetsValues, filterAssetsValues } from './services'
import authService from 'core/auth/auth-service'
import { t } from 'i18next'
import { CloseModalFunction, OpenModalFunction } from 'core/modal/redux/types'

function getAssetsValuesSuccess(payload: GetAssetsValuesSuccessPayload) {
  return {
    type: RTFSettingsActions.setAssetsValues,
    payload,
  }
}

export function setSettings() {
  return {
    type: RTFSettingsActions.setSettings,
  }
}

export function getAssetsValuesAndSetSettings() {
  return async function (dispatch: Dispatch) {
    try {
      dispatch(add(RTF_ASSETS_VALUES_FETCH, {}))
      const { data } = await fetchAssetsValues()
      dispatch(getAssetsValuesSuccess({ assetsValues: filterAssetsValues(data), assetsValuesOPCO: authService.getCurrentOpco() || '' }))
      dispatch(setSettings())
    } catch (error) {
      dispatch(addToaster({
        title: 'all_device_pages.menu_settings',
        message: 'error_messages.VIPOC_ERROR_040',
        type: 'danger'
      }))
    } finally {
      dispatch(remove(RTF_ASSETS_VALUES_FETCH))
    }
  }
}

export function showModalAndExecCommand(
  deviceId: string,
  openModal: OpenModalFunction,
  closeModal: CloseModalFunction,
  runCommands: RunCommandsFunction,
  commandConfig: CommandProps,
  connected: boolean,
  executeCommand: ExecuteCommandType,
  resetStates?: ResetStatesFunction
) {
    const commandName = 'modal.title.confirm_action'
    openModal({
      title: commandName,
      active: false,
      content: 'modal.content.confirm_action',
      execute: () => {
        closeModal()
        executeCommand(connected, commandName, () => runCommands(deviceId, commandConfig, 'home.icon_rtf'), resetStates)
      }
    })
}

export function executeCommand(...[connected, commandName, commandCallback, resetStates]: Parameters<ExecuteCommandType>) {
  return function(dispatch: Dispatch) {
    if(connected) {
      commandCallback()

      if(resetStates) {
        resetStates()
      }

      return
    }

    return dispatch(
      addToaster({
        title: t(commandName),
        message: t('error_messages.VIPOC_ERROR_068'),
        type: 'danger'
      })
    )
  }
}
