import { TFunction } from 'i18next'
import { RTFSettingsTab } from 'modules/operational/rtf/config/sidebar'
import React from 'react'
import './index.scss'

type SidebarProps = {
  t: TFunction
  tab: RTFSettingsTab
  setTab: React.Dispatch<React.SetStateAction<RTFSettingsTab>>
}

const i18nMap = {
  [RTFSettingsTab.logProfiles]: 'settings.tab_logs_profile',
  [RTFSettingsTab.logLevels]: 'settings.tab_manage_logs',
  [RTFSettingsTab.flushLogs]: 'settings.tab_flush_logs',
  [RTFSettingsTab.activityLog]: 'all_device_pages.menu_activity_log',
  [RTFSettingsTab.logsHistory]: 'all_device_pages.menu_logs',
}

function getSidebarItems() {
  return Object.keys(RTFSettingsTab)
}

export default function Sidebar({ t, tab, setTab }: SidebarProps) {
  const sidebarItems = getSidebarItems() as Array<RTFSettingsTab>

  return (
    <div className="rtf-side-menu-container">
      <div className="columns flex-wrap">
        {sidebarItems.map((sidebarItem) =>
          <div
            key={sidebarItem}
            className={`column is-12 is-relative rtf-side-menu-subcategory ${
              sidebarItem === tab && 'rtf-side-menu-subcategory-active'
            }`}
            onClick={() => setTab(sidebarItem)}
          >
              <span className="has-padding-left-md">
                {t(i18nMap[sidebarItem])}
              </span>
          </div>
        )}
      </div>
    </div>
  );
}
