import { TFunction } from 'i18next'
import React, { useCallback, useEffect } from 'react'
import Device from './Device'
import { ClearCurrentDeviceFunction, GetDeviceFunction, RTFDeviceData } from './redux/devices/types'
import SettingsComponent from './Settings/SettingsComponent'
import NoRecords from 'core/no-records'
import authService from 'core/auth/auth-service'
import { GetAssetsValuesAndSetSettingsFunction, RTFSettingsState } from './redux/settings/types'
import { useLocation } from 'react-router-dom'
import qs from 'qs'

type RTFProps = {
  getDevice: GetDeviceFunction
  clearCurrentDevice: ClearCurrentDeviceFunction
  currentDevice: RTFDeviceData
  t: TFunction
  getAssetsValuesAndSetSettings: GetAssetsValuesAndSetSettingsFunction
  settings: RTFSettingsState
  clearHistory: () => void
}

export type OnGetDeviceCallback = (deviceId: string) => void

function getDeviceIdFromLocation(search: string) {
  if (search) {
    const searchObject = qs.parse(search)
    return { deviceId: searchObject['?deviceId'] || '' } as { deviceId: string }
  }

  return { deviceId: '' }
}

export default function rtfComponent({ getDevice, currentDevice, t, clearCurrentDevice, settings, getAssetsValuesAndSetSettings, clearHistory }: RTFProps) {
  const location = useLocation()
  const { deviceId } = getDeviceIdFromLocation(location.search)
  
  useEffect(() => {
    if(authService.getCurrentOpco() !== settings.assetsValuesOPCO) {
      getAssetsValuesAndSetSettings()
    }

    if(deviceId) {
      getDevice(deviceId)
    }

    return () => {
      clearCurrentDevice()
    }
  }, [deviceId])

  const onGetDevice = useCallback((deviceId: string) => {
    window.location.href = `/operational/rtf?deviceId=${deviceId}`
    clearHistory()
  }, [])
  
  
  return (
    <div className="tile is-parent">
      <div className="tile is-child">
        <Device onGetDevice={onGetDevice} t={t} deviceIdQuery={deviceId}/>
        <div>
          {currentDevice.udid ? 
            <SettingsComponent currentDevice={currentDevice} t={t} settings={settings}/> :
            <NoRecords t={t} subtitle={t('rtf.search_device')}/>}
        </div>
      </div>
    </div>
  )
}
