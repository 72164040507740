import React from 'react'
import DatePicker from 'core/date/datepicker'
import { DatepickerInputProps, DatepickerValue } from '../types'
import { t } from 'i18next'

export default function Datepicker({ value, setValue, children, labelParams }: DatepickerInputProps) {
  const label = labelParams?.i18nTag && !value.start && !value.end ? t(labelParams.i18nTag) : ''
  return (
    <div className={`input-wrap ${!label ? 'date-picker-input' : ''}`}>
      <DatePicker
        isRange
        opened
        value={{
          start: value.start,
          end: value.end,
        }}
        label={label}
        onChangePeriod={({ start, end }: DatepickerValue) => {
          setValue({ start, end })
        }}
      />
      {!label && children}
    </div>
  )
}
