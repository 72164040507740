export const defaultColor = '#4A4A4A'
export const successColor = '#019900'
export const dangerColor = '#E60100'
export const warningcolor = '#EB9700'
export const iconSize = .67

export const oneHourInMs = 3600000

export const twentyMinutesInMs = oneHourInMs / 3
export const eighteenHoursInMs = 18 * oneHourInMs
export const twentyHoursInMs = 20 * oneHourInMs
export const twentyTwoHoursInMs = 22 * oneHourInMs
export const thirtySixHoursInMs = 36 * oneHourInMs

