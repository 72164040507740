import React from 'react'
import { t } from 'i18next'
import { addOffsetToEntry, getCheckedEntriesOfCurrentPage } from '../helpers'
import SortIcon from '../components/SortIcon'
import { SelectMultipleRowsType, TableProps } from '../types'

type WithCheckBoxProps<T, K> = TableProps<T, K> & {
  configMap: Array<string>
  selectMultipleRows: SelectMultipleRowsType
}

function RowCheckbox(index: number, selectMultipleRows: SelectMultipleRowsType, label: string) {
  const { selectedRows, currentPage, recordsPerPage } = selectMultipleRows
  const checkedRows = getCheckedEntriesOfCurrentPage(selectedRows, currentPage, recordsPerPage)
  return (
    <div className="has-checkbox checkbox">
      <input
        className='table-checkbox'
        type="checkbox"
        name={label}
        checked={checkedRows.includes(index)}
        readOnly
      />
      <label className='simple-table-label'>{label}</label>
    </div>
  )
}

function handleSelectAllRows<T>(selectMultipleRows: SelectMultipleRowsType) {
  const { selectedRows, setSelectedRows, totalRecords } = selectMultipleRows
  if(selectedRows.length === totalRecords) {
    return setSelectedRows([])
  }

  setSelectedRows(Array.from(Array(totalRecords).keys()))
}

function CheckboxHeader<T>(label: string, selectMultipleRows: SelectMultipleRowsType, items: Array<T>) {
  const { selectedRows, totalRecords } = selectMultipleRows
  return (
    <div className="has-checkbox checkbox cursor-pointer" onClick={() => {
      handleSelectAllRows(selectMultipleRows)}
    }>
      <input
        className='table-checkbox'
        type="checkbox"
        name={label}
        checked={selectedRows.length === totalRecords}
        readOnly
      />
      <label className='simple-table-header-label'>{label}</label>
    </div>
  )
}

export default function WithCheckBox<T, K>({ header, items, selectMultipleRows, configMap, onSortCallback, sort }: WithCheckBoxProps<T, K>) {
  const { handler, selectedRows, setSelectedRows, currentPage, recordsPerPage } = selectMultipleRows

  return (
    <div className="simple-table-with-checkbox">
      <div className="simple-table-header">
        <div className="columns">
          {header.map((config, index) => <div className='column' key={config.key}>
            {index === 0 ? CheckboxHeader(t(config.label), selectMultipleRows, items) : t(config.label)}
            {onSortCallback ? SortIcon({ field: config, sort, onSortCallback }) : ''}
          </div>)}
        </div>
      </div>
      <div>
        <div className="simple-table-body">
          {items.map((item: any, rowIndex) => <div 
          className={`columns ${rowIndex % 2 !== 0 && 'stripped'} simple-table-with-checkbox-row`}
          key={rowIndex}
          onClick={() => handler(addOffsetToEntry(rowIndex, currentPage, recordsPerPage), selectedRows, setSelectedRows)}>
            {configMap.map((key, columnIndex) => <div className='column' key={item[key]}>
              {columnIndex === 0 ? RowCheckbox(rowIndex, selectMultipleRows, item[key]) : item[key]}
            </div>)}
          </div>)}
        </div>
      </div>
    </div>
  )
}
