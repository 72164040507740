export const RTF_HISTORY_FETCH = 'RTF_HISTORY_FETCH'
export const RTF_HISTORY_LINK_GENERATION = 'RTF_HISTORY_LINK_GENERATION'

export type RawLogsHistory = {
  dayFile: number
  filename: string
  hhid: string
  monthFile: number
  udid: string
  yearFile: number
}

export type LogsHistory = {
  date: string
  filename: string
  link: string
}

export type RawLogsHistoryList = Array<RawLogsHistory>

export type LogsHistoryList = Array<LogsHistory>

export type LogsHistoryResponse = {
  recordCount: string
  records: RawLogsHistoryList
  requestID: string
}

export type LogsHistoryState = {
  list: LogsHistoryList
  totalItems: number
  requestId: string
  download: DownloadURL
}

export enum LogsHistoryActions {
  setList = 'SET_LIST',
  clearList = 'CLEAR_LIST',
  setURL = 'SET_URL',
  clearURL = 'CLEAR_URL'
}

export type LogsHistoryPayload = {
  type: LogsHistoryActions.setList
  payload: LogsHistoryResponse
} | {
  type: LogsHistoryActions.clearList
  payload: undefined
} | {
  type: LogsHistoryActions.setURL
  payload: DownloadURLResponse
} | {
  type: LogsHistoryActions.clearURL
  payload: undefined
}

export type AxiosFetchHistoryResponse = {
  data: LogsHistoryResponse
}

export type HistoryFilter = {
  start: string
  end: string
}

export type GetHistoryFunction = (id: string, filter: HistoryFilter) => void

export type GetDownloadURLFunction = (id: string, selectedEntries: Array<LogsHistory>) => void

export type ClearDownloadURLFunction = () => void

export type AxiosGenerateDownloadURLResponse = {
  data: DownloadURLResponse
}

export type DownloadURLResponse = {
  downloadLink: string
  timeToExpire: string
}

export type DownloadURL = {
  link: string
  expiration: number
}