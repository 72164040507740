import { envs } from 'application/envHandler';
import { deviceTabs, spectrumTabs } from './config';
import {
  NavbarAction,
  NavbarState,
  NAVBAR_CHANGE_CURRENT_TAB,
  NAVBAR_GET_SELECTED_SUBTABS
} from './types';

let initialState = {} as NavbarState

export function initializeState() {
  const initialNavbar = new Map();
  initialNavbar.set('device', deviceTabs());
  initialNavbar.set('spectrum', spectrumTabs);

  initialState = {
    navbar: initialNavbar
  }
}

export function getNavbar(state: NavbarState, id: string) {
  return state && state.navbar.get(id);
}

export default function(state = initialState, { type, payload }: NavbarAction) {
  switch (type) {
    case NAVBAR_CHANGE_CURRENT_TAB:
      const navbar = getNavbar(state, payload.id);

      if (navbar) {
        const newTab = navbar.available.find(item => item.value === payload.tab);
        if (newTab) {
          navbar.current = newTab;
        }
      }

      return { ...state };

    case NAVBAR_GET_SELECTED_SUBTABS:
      const nav = getNavbar(state, payload.id);
      let tabHovered;
      if (payload.selectedTab) {
        tabHovered = nav && nav.available.find((element) => element.value === payload.selectedTab);
      }
      if (nav) {
        nav.subtabstoshow = tabHovered;
        state.navbar.delete(payload.id);
        state.navbar.set(payload.id, nav);
      }
      return { ...state };

    default:
      return { ...state };
  }
}
