import { connectRouter, push, routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createBrowserHistory, History } from 'history';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { StateType } from 'typesafe-actions';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { pendingTasksReducer, remove } from 'core/indicator/pending-tasks-redux';
import modalReducer from 'core/modal/redux';
import uploadReducer from 'core/file-upload/redux';
import spinnerReducer from 'core/spinner/redux';
import searchReducer from 'core/search/redux';
import toasterReducer from 'core/toaster/redux';
import paginationReducer from 'core/pagination-optimized/redux';
import devicesReducer from 'modules/operational/device';
import notificationReducer from 'core/notifications/redux';
import managementReducer from 'modules/management';
import carouselReducer from 'core/carousel/redux';
import tableReducer from 'core/table/redux';
import sidebarReducer from 'core/sidebar/redux';
import { routesReducer, loadRoutes } from 'application/application-routes';
import AuthService from 'core/auth';
import apiClient from 'core/api';
import { add } from 'core/spinner/redux/actions';
import { loadDeviceCustomFields } from 'modules/operational/device/advanced-search/redux/action';
import multipleSearchReducer from 'core/multiple-search/redux';
import navbarReducer from 'application/header/navbar/reducers';
import progressBarReducer from 'core/progress-bar/redux';
import accordionReducer from '../core/table-accordion/redux';
import { addToaster } from 'core/toaster/redux/actions';
import sideMenuReducer from 'core/side-menu/redux';
import { envs } from './envHandler';
import RtfReducer from 'modules/operational/rtf/redux'
export interface ApplicationParameters {
  loaded: boolean;
  map: Map<string, string>;
}

export interface ApplicationInfoState {
  name: string;
  parameters: ApplicationParameters;
  offset: number;
  loadedOffset: boolean;
}

const applicationInfoInitialState: ApplicationInfoState = {
  name: 'VTV Portal Client',
  parameters: { loaded: false, map: new Map() },
  offset: 0,
  loadedOffset: false
};

export function getParametersByOpco(currentOpco: string | null) {
  const promise = new Promise((resolve, reject) => {
    apiClient
      .get(
        `${envs.REACT_APP_API_URL}/parameters?type=device_custom_fields&name=${currentOpco}`
      )
      .then((response: any) => {
        resolve(response.data || []);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
}

export function getOffset() {
  const promise = new Promise((resolve, reject) => {
    apiClient
      .get(`${envs.REACT_APP_API_URL}/timezone-offset`)
      .then((response: any) => {
        resolve(response.data || 0);
      })
      .catch((error) => {
        reject(error);
      });
  });

  return promise;
}

export const LOAD_DEVICE_CUSTOM_FIELDS = 'LOAD_DEVICE_CUSTOM_FIELDS';
export const LOAD_OFFSET_VALUE = 'LOAD_OFFSET_VALUE';
export const INITIALIZE_CUSTOM_FIELDS = 'INITIALIZE_CUSTOM_FIELDS';
export function initializeParameters(callback?: any) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    if (!getState().application.loadedOffset) {
      getOffset()
        .then((data) => {
          dispatch({ type: LOAD_OFFSET_VALUE, payload: { data: data, loaded: true } });
          remove(INITIALIZE_CUSTOM_FIELDS);

          if (callback) {
            dispatch(callback());
          }
        })
        .catch(() => {
          remove(INITIALIZE_CUSTOM_FIELDS);
        });
    }

    if (!getState().application.parameters.loaded) {
      add(INITIALIZE_CUSTOM_FIELDS, {});

      getParametersByOpco(AuthService.getCurrentOpco())
        .then((data) => {
          dispatch({ type: LOAD_DEVICE_CUSTOM_FIELDS, payload: data });
          remove(INITIALIZE_CUSTOM_FIELDS);

          if (callback) {
            dispatch(callback());
          }
        })
        .catch(() => {
          remove(INITIALIZE_CUSTOM_FIELDS);
        });
    } else {
      dispatch(loadDeviceCustomFields());
      dispatch({ type: LOAD_DEVICE_CUSTOM_FIELDS, payload: getState().application.parameters.map });
    }
  };
}

const applicationReducer = (
  state: ApplicationInfoState = applicationInfoInitialState,
  action: any
): ApplicationInfoState => {
  switch (action.type) {
    case LOAD_DEVICE_CUSTOM_FIELDS: {
      return { ...state, parameters: { loaded: true, map: action.payload || [] } };
    }

    case LOAD_OFFSET_VALUE: {
      return { ...state, offset: action.payload.data || 0, loadedOffset: action.payload.loaded };
    }

    default: {
      return { ...state };
    }
  }
};

export const hist = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

const i18NInitialState = {
  t: (key: string) => key
};

const i18nReducer = (state = i18NInitialState, action: any) => {
  switch (action.type) {
    case 'I18N_LOAD': {
      return {
        t: action.payload
      };
    }
    default: {
      if (!state.t) {
        return { ...i18NInitialState };
      }
      return state;
    }
  }
};

const createRootReducer = (history: History) =>
  combineReducers({
    i18n: i18nReducer,
    accordion: accordionReducer,
    routes: routesReducer,
    router: connectRouter(history),
    application: applicationReducer,
    pendingTasks: pendingTasksReducer,
    devices: devicesReducer,
    modal: modalReducer,
    spinner: spinnerReducer,
    toasters: toasterReducer,
    navbar: navbarReducer,
    search: searchReducer,
    pagination: paginationReducer,
    management: managementReducer,
    carousel: carouselReducer,
    notification: notificationReducer,
    table: tableReducer,
    sidebar: sidebarReducer,
    progressBar: progressBarReducer,
    multipleSearch: multipleSearchReducer,
    upload: uploadReducer,
    sideMenu: sideMenuReducer,
    rtf: RtfReducer, 
  });

export type ApplicationState = StateType<ReturnType<typeof createRootReducer>>;

const redirectNoAuthMiddleware = (store: any) => (next: any) => (action: any) => {
  const allowedUrls = ['/login', '/logout']

  const currentUser = AuthService.getCurrentUser()

  if (action.type === '@@router/LOCATION_CHANGE') {
    if (action.payload.location && !allowedUrls.includes(action.payload.location.pathname)) {
      const currentOpco = AuthService.getCurrentOpco();
      const userKeys = Object.keys(currentUser);

      const userProperties = Object.values(currentUser);
      const userGroups = currentUser.groups.some(
        (el: any) =>
          el.moduleName !== undefined &&
          el.moduleName.includes(action.payload.location.pathname.split('/')[2]) &&
          el.opco === currentOpco
      );

      if (action.payload.location.pathname !== '/' && !userGroups) {
        store.dispatch(push(`${process.env.PUBLIC_URL}`));
        store.dispatch(
          addToaster({
            title: 'home.home_icon',
            message: 'error_messages.VIPOC_ERROR_057',
            type: 'danger'
          })
        );
      }

      for (let i = 0; i < userProperties.length; i++)
        if (
          userKeys[i] !== 'opco' &&
          userKeys[i] !== 'lang' &&
          userProperties &&
          userProperties[i].length === 0
        ) {
          AuthService.logoutAndRedirect()
        }

      store.dispatch(loadRoutes(currentUser, currentOpco));
    }
  }

  if (action.checkUser) {
    if (Date.now() >= parseInt(currentUser.exp) * 1000) {
      store.dispatch(
        addToaster({
          title: 'datagrabber.session_popup_title',
          message: 'datagrabber.session_popup_message',
          type: 'danger'
        })
      );
      return setTimeout(() => {
        AuthService.logoutAndRedirect()
      }, 4000);
    }

    return next(action.function);
  }

  return next(action);
};

const composition =
  envs.REACT_APP_DEBUG
    ? composeWithDevTools(
        applyMiddleware(redirectNoAuthMiddleware, thunk, routerMiddleware(hist), logger)
      )
    : applyMiddleware(redirectNoAuthMiddleware, thunk, routerMiddleware(hist));

export const configureStore = (preloadedState: any | {}): any =>
  createStore(createRootReducer(hist), preloadedState, composition);
