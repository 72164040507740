import React, { Component, Fragment } from 'react';
import { TFunction } from 'i18next';
import DeviceDetailInfoActions from './device-detail-info/device-detail-actions.component';
import { DEVICE_OS, GeneralDeviceState } from '../general/redux/types';
import { DeviceOverview } from './redux/types';
import './device-detail-styles.scss';
import Carousel from 'core/carousel';
import { OverlayModalState } from 'core/modal/redux/types';
import Topbar from '../topbar/topbar-container';
import CardComponent from 'core/card';
import { OpenInNew, Sync } from 'assets/svg';
import DateUtils from 'core/utils/date-utils';
import { isEmpty } from 'core/utils/general-utils';
import moment from 'moment';
import { CardPaddings } from 'core/card/enum';
import { ExecuteCommandType } from 'core/utils/deviceUtils';
import { Link } from 'react-router-dom';

interface DeviceDetailOverviewProps {
  id: string;
  isFetching: boolean;
  general: GeneralDeviceState;
  overview: DeviceOverview;
  hhid: string;
  t: TFunction;
  open: (options: OverlayModalState) => void;
  execute: (params: { command: string; id: string }) => void;
  refreshEmm: (hhid: string) => void;
  close: any;
  executeCommand: ExecuteCommandType
  serialNr: string
}

export default class DeviceDetailOverviewComponent extends Component<DeviceDetailOverviewProps> {
  executeAction(commandId: string, commandName: string) {
    const { id, open, execute, close, general, executeCommand } = this.props;

    open({
      title: commandName,
      active: false,
      content: 'modal.content.confirm_action',
      execute: () => {
        close()
        executeCommand(general, commandName, () => execute({ command: commandId, id }))
      }
    });
  }

  ConfirmEMMRefreshMessage({ t }: { t: TFunction }) {
    return (
      <div>
        {t('modal.content.confirm_action')}
        <br />
        <b>{t('modal.content.confirm_action_household')}</b>
      </div>
    );
  }

  refreshEmm() {
    const { open, close, refreshEmm, hhid, t, general, executeCommand } = this.props
    const { ConfirmEMMRefreshMessage } = this
    const commandTitle = 'all_device_pages.button_emm_refresh'
    open({
      title: commandTitle,
      active: false,
      children: <ConfirmEMMRefreshMessage t={t} />,
      execute: () => {
        close();
        refreshEmm(hhid);
      }
    });
  }

  validRCUName(generalDeviceInfo: GeneralDeviceState, t: TFunction) {
    return !isEmpty(generalDeviceInfo.shadow?.custom.RCURN)
      ? generalDeviceInfo.shadow?.custom.RCURN
      : t('general.not_available');
  }

  validSwdUptDate(generalDeviceInfo: GeneralDeviceState, t: TFunction) {
    const swupd = !isEmpty(generalDeviceInfo.shadow?.custom.SwUpd)
      ? generalDeviceInfo.shadow?.custom.SwUpd
      : t('general.not_available');

    if (swupd === t('general.not_available') || swupd === 'undefined') {
      return t('general.not_available');
    } else {
      return DateUtils.formatDate(swupd);
    }
  }

  validRcu(generalDeviceInfo: GeneralDeviceState, t: TFunction) {
    const rcu = !isEmpty(generalDeviceInfo.shadow)
      ? generalDeviceInfo.shadow && generalDeviceInfo.shadow.reported.config.RCM
      : undefined;

    if (rcu) {
      if (rcu === t('general.not_available')) {
        return rcu;
      }

      let rcuLabel = generalDeviceInfo.shadow?.auxProperties?.isRCMConnected
        ? t('overview.value_rcu_connected_yes')
        : t('overview.value_rcu_connected_no');
      return `${rcuLabel} (${rcu})`;
    }

    return t('overview.value_rcu_connected_no');
  }

  getStandbyStatus(standByValue: string, os: string) {
    if(os === DEVICE_OS.HORIZON) {
      return standByValue === 'Standby' ? 'Standby' : 'On'
    }

    return standByValue === 'off' ? 'Standby' : 'On'
  }

  validConnected(generalDeviceInfo: GeneralDeviceState, t: TFunction) {
    if (generalDeviceInfo?.shadow?.reported?.status.connected === t('general.not_available')) {
      return generalDeviceInfo?.shadow?.reported?.status.connected
    }

    if (generalDeviceInfo?.shadow?.reported?.status?.connected === 'true') {
      return this.getStandbyStatus(generalDeviceInfo?.shadow?.reported?.config.standBy, generalDeviceInfo?.shadow?.reported?.software?.os)
    }

    return 'Off'
  }

  validConnectedFor(generalDeviceInfo: GeneralDeviceState, t: TFunction) {
    return !isEmpty(generalDeviceInfo.shadow) &&
      generalDeviceInfo.shadow &&
      generalDeviceInfo.shadow.reported.status.connectedFor === t('general.not_available')
      ? t('general.not_available')
      : DateUtils.convertConnectedFor(
          generalDeviceInfo.shadow && generalDeviceInfo.shadow.reported.status.connectedFor
        );
  }

  validLastConnection(lastConnection: string, t: TFunction) {
    if (lastConnection === 'undefined') {
      return t('general.not_available');
    } else {
      return moment
        .utc(
          new Date(
            lastConnection && lastConnection.length > 10
              ? parseFloat(lastConnection)
              : parseFloat(lastConnection) * 1000
          )
        )
        .format('YYYY-MM-DD HH:mm:ss');
    }
  }

  connectedOrLastConnection(generalDeviceInfo: GeneralDeviceState, t: TFunction) {
    if (generalDeviceInfo.shadow) {
      return (
        <Fragment>
          <p className="has-text-weight-bold text is-h6 is-bold">
            {generalDeviceInfo.shadow.reported.status.connected !== 'false'
              ? t('overview.label_connected_for')
              : t('overview.label_last_connection')}
          </p>
          <p className="text is-h6 has-margin-top-xxs">
            {generalDeviceInfo.shadow.reported.status.connected !== 'false'
              ? this.validConnectedFor(generalDeviceInfo, t)
              : this.validLastConnection(
                  generalDeviceInfo.shadow.reported.status.lastConnectionDate,
                  t
                )}
          </p>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <p className="has-text-weight-bold text is-h6 is-bold">
            {t('overview.label_last_connection')}
          </p>
          <p className="text is-h6 has-margin-top-xxs">{t('general.not_available')}</p>
        </Fragment>
      );
    }
  }

  allowWifiHyperlink() {
    const { general, id, hhid, serialNr } = this.props
    if(general.shadow?.reported.config.nType && general?.shadow?.reported.config.nType === 'WIFI') {
      return <Link to={`/operational/device/${id}/wifi_dashboards/${hhid}/${serialNr}`}><OpenInNew className='wifi-dashboards-hyperlink'/></Link>
    }
  }

  render() {
    const { id, general, t } = this.props;

    return (
      <Fragment>
        <div className="columns width-full">
          <div className="column">
            <Topbar id={id}></Topbar>
          </div>

          <div className="column is-narrow has-padding-right-none">
            <button
              className="button is-rounded is-larger"
              onClick={() => this.executeAction('reboot', 'all_device_pages.button_reboot')}
            >
              {t('all_device_pages.button_reboot')}
            </button>

            <button
              className="button is-rounded is-larger"
              onClick={() => this.executeAction('powerOn', 'all_device_pages.button_power_on')}
            >
              {t('all_device_pages.button_power_on')}
            </button>

            <button
              className="button is-rounded is-larger"
              onClick={() => this.executeAction('standBy', 'all_device_pages.button_stand_by')}
            >
              {t('all_device_pages.button_stand_by')}
            </button>

            <button
              className="button is-rounded is-larger"
              onClick={() =>
                this.executeAction('runRegionDiscovery', 'all_device_pages.button_region_discovery')
              }
            >
              {t('all_device_pages.button_region_discovery')}
            </button>
            <button className="button is-rounded is-larger" onClick={() => this.refreshEmm()}>
              {t('all_device_pages.button_emm_refresh')}
            </button>
          </div>
        </div>

        <div className="tile is-vertical has-padding-left-none is-8">
          <div className="tile is-parent is-vertical flex-zero has-padding-sm">
            <div className="tile is-child">
              <CardComponent isFullHeight={true} padding={CardPaddings.MEDIUM}>
                <div className="columns has-margin-bottom-none has-padding-md">
                  <div className="column">
                    <h5 className="text is-h5">{t('advanced_search.column_connection')}</h5>
                  </div>
                  <div className="column is-narrow has-padding-right-none">
                    <Sync className="icon icon-svg icon-x-small is-hidden" />
                  </div>
                </div>

                <div className="columns flex-wrap has-padding-right-md has-padding-left-md has-padding-bottom-md">
                  <div className="column column column-border-bottom is-4">
                    <p className="has-text-weight-bold text is-h6 is-bold">
                      {t('overview.label_status')}
                    </p>
                    <p className="text is-h6 has-margin-top-xxs">
                      {this.validConnected(general, t)}
                    </p>
                  </div>
                  <div className="column column column-border-bottom is-4">
                    {this.connectedOrLastConnection(general, t)}
                  </div>

                  <div className="column column column-border-bottom is-4">
                    <p className="has-text-weight-bold text is-h6 is-bold">
                      {t('overview.label_type')}
                      {this.allowWifiHyperlink()}
                    </p>
                    <p className="text is-h6 has-margin-top-xxs">
                      {general.shadow &&
                      general.shadow.reported &&
                      general.shadow.reported.config &&
                      general.shadow.reported.config.nType !== 'undefined' &&
                      general.shadow.reported.config.nType
                        ? general.shadow.reported.config.nType
                        : t('general.not_available')}
                    </p>
                  </div>

                  <div className="column column-border-bottom is-4">
                    <p className="has-text-weight-bold text is-h6 is-bold">
                      {t('connectivity.label_cablemodem_mac')}
                    </p>
                    <p className="text is-h6 has-margin-top-xxs">
                      {general.shadow &&
                      general.shadow.custom &&
                      general.shadow.custom.CGMAC !== 'Not available' &&
                      general.shadow.custom.CGMAC !== 'undefined'
                        ? general.shadow.custom.CGMAC
                        : t('general.not_available')}
                    </p>
                  </div>

                  <div className="column column-border-bottom is-4">
                    <p className="has-text-weight-bold text is-h6 is-bold">
                      {t('connectivity.label_ethernet_mac')}
                    </p>
                    <p className="text is-h6 has-margin-top-xxs">
                      {general.shadow &&
                      general.shadow.custom &&
                      general.shadow.custom.MACEt !== 'undefined'
                        ? general.shadow.custom.MACEt
                        : t('general.not_available')}
                    </p>
                  </div>

                  <div className="column is-4 column-border-bottom">
                    <p className="has-text-weight-bold text is-h6 is-bold">
                      {t('overview.label_nic_mac_address')}
                    </p>
                    <p className="text is-h6 has-margin-top-xxs">
                      {general.shadow &&
                      general.shadow.custom &&
                      general.shadow.custom.WFMAC !== 'undefined'
                        ? general.shadow.custom.WFMAC
                        : t('general.not_available')}
                    </p>
                  </div>
                  <div className="column column-border-bottom is-4">
                    <p className="has-text-weight-bold text is-h6 is-bold">
                      {t('connectivity.label_ip_assignment')}
                    </p>
                    <p className="text is-h6 has-margin-top-xxs">
                      {general.shadow &&
                      general.shadow.custom &&
                      general.shadow.custom.NetCg !== 'undefined'
                        ? defineNetCgField(general.shadow.custom.NetCg, t)
                        : t('general.not_available')}
                    </p>
                  </div>

                  <div className="column column column-border-bottom is-4">
                    <p className="has-text-weight-bold text is-h6 is-bold">
                      {t('connectivity.label_primary_dns')}
                    </p>
                    <p className="text is-h6 has-margin-top-xxs">
                      {general.shadow &&
                      general.shadow.custom &&
                      general.shadow.custom.PrDNS !== 'undefined'
                        ? general.shadow.custom.PrDNS
                        : t('general.not_available')}
                    </p>
                  </div>
                  <div className="column column column-border-bottom is-4">
                    <p className="has-text-weight-bold text is-h6 is-bold">
                      {t('connectivity.label_secondary_dns')}
                    </p>
                    <p className="text is-h6 has-margin-top-xxs">
                      {general.shadow &&
                      general.shadow.custom &&
                      general.shadow.custom.ScDNS !== 'undefined'
                        ? general.shadow.custom.ScDNS
                        : t('general.not_available')}
                    </p>
                  </div>

                  <div className="column is-4">
                    <p className="has-text-weight-bold text is-h6 is-bold">
                      {t('connectivity.label_ip_address')}
                    </p>
                    <p className="text is-h6 has-margin-top-xxs">
                      {general.shadow &&
                      general.shadow.custom &&
                      general.shadow.custom.PrvIP !== 'undefined'
                        ? general.shadow.custom.PrvIP
                        : t('general.not_available')}
                    </p>
                  </div>

                  <div className="column is-4">
                    <p className="has-text-weight-bold text is-h6 is-bold">
                      {t('overview.label_public_ip')}
                    </p>
                    <p className="text is-h6 has-margin-top-xxs">{getPublicIpField(general, t)}</p>
                  </div>

                  <div className="column is-4">
                    <p className="has-text-weight-bold text is-h6 is-bold">
                      {t('connectivity.label_subnet_mask')}
                    </p>
                    <p className="text is-h6 has-margin-top-xxs">
                      {general.shadow &&
                      general.shadow.custom &&
                      general.shadow.custom.IPMsk !== 'undefined'
                        ? general.shadow.custom.IPMsk
                        : t('general.not_available')}
                    </p>
                  </div>
                </div>
              </CardComponent>
            </div>
          </div>
          <div className="tile has-padding-left-none">
            <div className="tile is-vertical has-padding-left-none">
              <div className="tile has-padding-left-none">
                <div className="tile is-parent has-padding-sm">
                  <div className="tile is-child">
                    <CardComponent isFullHeight={true} padding={CardPaddings.MEDIUM}>
                      <div className="columns has-margin-bottom-none has-padding-md">
                        <div className="column">
                          <h5 className="text is-h5">{t('overview.label_rcu')}</h5>
                        </div>
                      </div>

                      <div className="columns flex-wrap has-padding-right-md has-padding-left-md has-padding-bottom-md">
                        <div className="column is-12 column-border-bottom">
                          <p className="has-text-weight-bold text is-h6 is-bold">
                            {t('overview.label_connected')}
                          </p>
                          <p className="text is-h6 has-margin-top-xxs">
                            {this.validRcu(general, t)}
                          </p>
                        </div>

                        <div className="column is-12 column-border-bottom">
                          <p className="has-text-weight-bold text is-h6 is-bold">
                            {t('overview.label_software_version')}
                          </p>
                          <p className="text is-h6 has-margin-top-xxs">
                            {general.shadow &&
                            general.shadow.reported &&
                            general.shadow.reported.software &&
                            general.shadow.reported.software.RCVer !== 'undefined'
                              ? general.shadow.reported.software.RCVer
                              : t('general.not_available')}
                          </p>
                        </div>

                        <div className="column is-12 column-border-bottom">
                          <p className="has-text-weight-bold text is-h6 is-bold">
                            {t('overview.label_revision_name')}
                          </p>
                          <p className="text is-h6 has-margin-top-xxs">
                            {this.validRCUName(general, t)}
                          </p>
                        </div>
                      </div>
                    </CardComponent>
                  </div>
                </div>

                <div className="tile is-parent has-padding-sm">
                  <div className="tile is-child">
                    <CardComponent isFullHeight={true} padding={CardPaddings.MEDIUM}>
                      <div className="columns has-margin-bottom-none has-padding-md">
                        <div className="column">
                          <h5 className="text is-h5">{t('overview.subtitle_software')}</h5>
                        </div>
                      </div>

                      <div className="columns flex-wrap has-padding-right-md has-padding-left-md has-padding-bottom-md">
                        <div className="column is-12 column-border-bottom">
                          <p className="has-text-weight-bold text is-h6 is-bold">
                            {t('overview.label_version')}
                          </p>
                          <p className="text is-h6 has-margin-top-xxs">
                            {general.shadow &&
                            general.shadow.reported &&
                            general.shadow.reported.software &&
                            general.shadow.reported.software.ver &&
                            general.shadow.reported.software.ver !== 'undefined'
                              ? general.shadow.reported.software.ver
                              : t('general.not_available')}
                          </p>
                        </div>

                        <div className="column is-12 column-border-bottom">
                          <p className="has-text-weight-bold text is-h6 is-bold">
                            {t('overview.label_last_updated')}
                          </p>
                          <p className="text is-h6 has-margin-top-xxs">
                            {this.validSwdUptDate(general, t)}
                          </p>
                        </div>

                        <div className="column is-12 column-border-bottom">
                          <p className="has-text-weight-bold text is-h6 is-bold">
                            {t('overview.label_ui')}
                          </p>
                          <p className="text is-h6 has-margin-top-xxs">
                            {general.shadow &&
                            general.shadow.reported &&
                            general.shadow.reported.software &&
                            general.shadow.reported.software.UI !== 'undefined' &&
                            general.shadow.reported.software.UI
                              ? general.shadow.reported.software.UI
                              : t('general.not_available')}
                          </p>
                        </div>

                        <div className="column is-12 column-border-bottom">
                          <p className="has-text-weight-bold text is-h6 is-bold">
                            {t('overview.label_cversion')}
                          </p>
                          <p className="text is-h6 has-margin-top-xxs">
                            {general?.shadow?.custom?.CVER || t('general.not_available')}
                          </p>
                        </div>
                      </div>
                    </CardComponent>
                  </div>
                </div>
                <div className="tile is-vertical has-padding-left-none is-4">
                  <div className="tile is-parent has-padding-sm">
                    <div className="tile is-child">
                      <CardComponent padding={CardPaddings.MEDIUM}>
                        <div className="columns has-margin-bottom-none has-padding-md">
                          <div className="column">
                            <h5 className="text is-h5">{t('overview.subtitle_hardware')}</h5>
                          </div>
                        </div>

                        <div className="columns flex-wrap has-padding-right-md has-padding-left-md has-padding-bottom-md">
                          <div className="column is-12 column-border-bottom">
                            <p className="has-text-weight-bold text is-h6 is-bold">
                              {t('overview.label_version')}
                            </p>
                            <p className="text is-h6 has-margin-top-xxs">
                              {general &&
                              general.shadow &&
                              general.shadow.custom.HWV !== 'undefined'
                                ? general.shadow.custom.HWV
                                : t('general.not_available')}
                            </p>
                          </div>

                          <div className="column is-12 column-border-bottom">
                            <p className="has-text-weight-bold text is-h6 is-bold">
                              {t('overview.label_brand')}
                            </p>
                            <p className="text is-h6 has-margin-top-xxs">
                              {general.shadow &&
                              general.shadow.reported &&
                              general.shadow.reported.hardware &&
                              general.shadow.reported.hardware.brn &&
                              general.shadow.reported.hardware.brn !== 'undefined'
                                ? general.shadow.reported.hardware.brn
                                : t('general.not_available')}
                            </p>
                          </div>

                          <div className="column is-12 column-border-bottom">
                            <p className="has-text-weight-bold text is-h6 is-bold">
                              {t('overview.label_model')}
                            </p>
                            <p className="text is-h6 has-margin-top-xxs">
                              {general.shadow &&
                              general.shadow.reported &&
                              general.shadow.reported.hardware &&
                              general.shadow.reported.hardware.mod &&
                              general.shadow.reported.hardware.mod !== 'undefined'
                                ? general.shadow.reported.hardware.mod
                                : t('general.not_available')}
                            </p>
                          </div>

                          <div className="column is-12 column-border-bottom">
                            <p className="has-text-weight-bold text is-h6 is-bold">
                              {t('overview.label_serial_nr')}
                            </p>
                            <p className="text is-h6 has-margin-top-xxs">
                              {general.shadow &&
                              general.shadow.reported &&
                              general.shadow.reported.hardware &&
                              general.shadow.reported.hardware.SN &&
                              general.shadow.reported.hardware.SN !== 'undefined'
                                ? general.shadow.reported.hardware.SN
                                : t('general.not_available')}
                            </p>
                          </div>
                        </div>
                      </CardComponent>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tile is-parent has-padding-sm is-8">
                <div className="tile is-child">
                  <CardComponent isFullHeight={true} padding={CardPaddings.MEDIUM}>
                    <div className="columns has-margin-bottom-none has-padding-md">
                      <div className="column">
                        <h5 className="text is-h5 ">{t('overview.label_channels')}</h5>
                      </div>
                      <div className="column is-narrow">
                        <Sync className="icon icon-svg icon-x-small" style={{ display: 'none' }} />
                      </div>
                    </div>

                    <div className="columns flex-wrap has-padding-right-md has-padding-left-md has-padding-bottom-md">
                      <div className="column ">
                        <p className="has-text-weight-bold text is-h6 is-bold">
                          {t('overview.label_current')}
                        </p>
                        <p className="text is-h6 has-margin-top-xxs">
                          {general.shadow &&
                          general.shadow.userConversion &&
                          general.shadow.userConversion.channelName !== 'undefined'
                            ? general.shadow.userConversion.channelName
                            : t('general.not_available')}
                        </p>
                      </div>
                      <div className="column">
                        <p className="has-text-weight-bold text is-h6 is-bold">
                          {t('overview.label_previous')}
                        </p>
                        <p className="text is-h6 has-margin-top-xxs">
                          {general.shadow &&
                          general.shadow.userConversion &&
                          general.shadow.userConversion.pChannelName !== 'undefined'
                            ? general.shadow.userConversion.pChannelName
                            : t('general.not_available')}
                        </p>
                      </div>
                    </div>
                  </CardComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tile is-parent has-padding-sm is-vertical is-4">
          <div className="tile is-child flex-zero margin-override">
            <CardComponent isFullHeight={true} padding={CardPaddings.MEDIUM}>
              <Carousel />
            </CardComponent>
          </div>

          <div className="tile is-child">
            <CardComponent padding={CardPaddings.MEDIUM}>
              <DeviceDetailInfoActions vip={general.vip} id={id} shadow={general.shadow} />
            </CardComponent>
          </div>
        </div>
      </Fragment>
    );
  }
}

function defineNetCgField(netCg: any, t: TFunction) {
  if (netCg === 'Manual') {
    return t('connectivity.label_static_ip');
  } else if (netCg === 'Auto (default)') {
    return t('connectivity.label_dhcp');
  }
  return netCg;
}

function getPublicIpField(general: GeneralDeviceState, t: TFunction) {
  if (
    general.shadow &&
    general.shadow.custom &&
    general.shadow.custom.WanIP !== t('general.not_available')
  ) {
    return general.shadow.custom.WanIP;
  } else if (
    general.shadow &&
    general.shadow.rcglifecycleevents &&
    general.shadow.rcglifecycleevents.sourceIp !== t('general.not_available')
  ) {
    return general.shadow.rcglifecycleevents.sourceIp;
  }
  return t('general.not_available');
}
