import { ActivityLogActions, ActivityLogPayload, ActivityLogState, ActivityLogStatus } from './types'

const initialState: ActivityLogState = {
  list: [],
  totalItems: 0
}

export default function (state = initialState, action: ActivityLogPayload) {
  switch (action.type) {
    case ActivityLogActions.setList:
      return { list: action.payload.items, totalItems: Number(action.payload.totalElements) }

    case ActivityLogActions.clearList:
      return { list: [], totalItems: 0}

    case ActivityLogActions.cancelCommand:
      return { ...state, list: state.list.map(entry => {
        return entry.commandTraceId === action.payload ?
          {...entry, status: ActivityLogStatus.canceled } :
          { ...entry }
      })}

    default:
      return state
  }
}
