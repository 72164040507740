import { connect } from 'react-redux';
import { ApplicationState, initializeParameters } from 'application/application-redux';
import AdvancedSearchComponent from './advanced-search-component';
import {
  fetchAdvancedSearch,
  onChangeAdvancedSearchPage,
  loadDeviceCustomFields
} from './redux/action';
import { clearCacheVod } from '../detail/vod/redux/actions';
import { clearCacheDvr } from '../detail/dvr/redux/actions';
import { clearCacheSpeedTest } from '../detail/speed-test/redux/actions';
import { clearCacheSubscriptions } from '../detail/subscriptions/redux/actions';
import { clearCacheProvDevices } from '../detail/provisioned-devices/redux/actions';
import { clearCacheRFTuning } from '../detail/rf-tuning/status/redux/actions';
import { resetPageNumber } from 'core/pagination-optimized/redux/actions';
import { clearAllFilters } from 'core/search/redux/action';
import { clearTableCache } from 'core/table/redux/actions';
import { clearCacheGeneralDeviceInfo } from '../detail/general/redux/actions';
import { stopSpeedTest } from '../detail/speed-test/redux/actions';
import { stopChannelScan } from '../detail/channels/redux/actions';
import { stopChannelStats } from '../detail/rf-tuning/status/redux/actions';
import { clearCacheOverview } from '../detail/overview/redux/actions';
import { cleanChannelsResult } from '../detail/channels/redux/actions';
import { cleanScanResult } from '../detail/channels/scan/redux/actions';
import { clearAccordionCache } from 'core/table-accordion/redux/actions';
import { clearSettingsCache } from '../detail/_settings/redux/actions';
import { sideMenuClearCache } from 'core/side-menu/redux/action';
import { clearCacheTabRFtuning } from '../detail/rf-tuning/redux/actions';
import { clearScanCache, stopSingleRftuning } from '../detail/rf-tuning/scan/redux/actions';
import { clearThreshold } from '../detail/hard-disk/redux/actions';

const mapStateToProps = (state: ApplicationState) => ({
  application: state.application,
  results: state.devices.advancedSearch.execution.results,
  resultsToShow: state.devices.advancedSearch.execution.resultsToShow,
  totalElements: state.devices.advancedSearch.execution.totalElements,
  lastEvaluatedData: state.devices.advancedSearch.execution.lastEvaluatedData,
  currentHistory: state.devices.advancedSearch.history.current,
  applicationParameters: state.application.parameters.map,
  pagination: state.pagination,
  multipleSearch: state.multipleSearch,
  t: state.i18n.t
});
export default connect(mapStateToProps, {
  fetchAdvancedSearch,
  initializeParameters,
  loadDeviceCustomFields,
  clearCacheVod,
  clearCacheDvr,
  clearCacheSpeedTest,
  clearCacheSubscriptions,
  clearCacheProvDevices,
  clearCacheRFTuning,
  clearAllFilters,
  resetPageNumber,
  clearTableCache,
  onChangeAdvancedSearchPage,
  clearCacheGeneralDeviceInfo,
  clearCacheOverview,
  stopSpeedTest,
  stopChannelScan,
  stopChannelStats,
  cleanChannelsResult,
  cleanScanResult,
  clearAccordionCache,
  clearSettingsCache,
  sideMenuClearCache,
  clearCacheTabRFtuning,
  clearScanCache,
  stopSingleRftuning,
  clearThreshold,
})(AdvancedSearchComponent);
