import { ChevronDoubleLeft, ChevronDoubleRight, ChevronLeftReal, ChevronRight } from 'assets/svg'
import React from 'react'
import './index.scss'

type ExtraPages = {
  pages: Array<number>
  displayReticence: boolean
}

type ExtraPagesGroup = {
  displayToTheLeft: ExtraPages
  displayToTheRight: ExtraPages
}

type PaginationProps = {
  page: number
  totalPages: number
  setPage: React.Dispatch<React.SetStateAction<number>>
  displayExtraPage?: number
}

export default function Pagination({ page, totalPages, setPage, displayExtraPage = 2 }: PaginationProps) {
  const extraPages = getExtraPagesToBeRendered(page, totalPages, displayExtraPage)

  return (
    <div className='pagination-container'>
      <i onClick={() => handleGoToFirstPage(page, setPage)} className={`local-pagination-button ${page > 1 ? 'active' : 'disabled'}`}> <ChevronDoubleLeft /> </i>
      <i onClick={() => handlePreviousPage(page, setPage)} className={`local-pagination-button ${page > 1 ? 'active' : 'disabled'}`}> <ChevronLeftReal /> </i>
      <PageNumbers currentPage={page} totalPages={totalPages} extraPages={extraPages} setPage={setPage}/>
      <i onClick={() => handleNextPage(page, totalPages, setPage)} className={`local-pagination-button ${page < totalPages ? 'active' : 'disabled'}`}> <ChevronRight /> </i>
      <i onClick={() => handleGoToLastPage(page, totalPages, setPage)} className={`local-pagination-button ${page < totalPages ? 'active' : 'disabled'}`}> <ChevronDoubleRight /> </i>
    </div>
  )
}

type PageNumbersProps = {
  currentPage: number, 
  totalPages: number,
  extraPages: ExtraPagesGroup
  setPage: React.Dispatch<React.SetStateAction<number>>
}

function PageNumbers({ currentPage, totalPages, extraPages: { displayToTheLeft, displayToTheRight }, setPage}: PageNumbersProps) {
  return <div className="pagination-indicators-container">
    {currentPage !== 1 && <div className='page-indicator' onClick={() => handleGoToFirstPage(currentPage, setPage)}>1</div>}
    {displayToTheLeft.displayReticence && <div className='page-indicator reticence'>...</div>}
    {displayToTheLeft.pages.map(pageNumber => <div className='page-indicator' key={pageNumber} onClick={() => goToPage(currentPage, pageNumber, setPage)}>{pageNumber}</div>)}
    <div className='page-indicator current'> {currentPage} </div>
    {displayToTheRight.pages.map(pageNumber => <div className='page-indicator' key={pageNumber} onClick={() => goToPage(currentPage, pageNumber, setPage)}>{pageNumber}</div>)}
    {displayToTheRight.displayReticence && <div className='page-indicator reticence'>...</div>}
    {currentPage !== totalPages && <div className='page-indicator' onClick={() => handleGoToLastPage(currentPage, totalPages, setPage)}>{totalPages}</div>}
  </div>
}


function getExtraPagesToBeRendered(currentPage: number, totalPages: number, extraPagesToBeRendered: number) {
  const firstPage = 1
  const lastPage = totalPages
  
  const pagesToTheLeft = Array.from(Array(extraPagesToBeRendered).keys(), (number) => (currentPage - 1) - number).reverse()
  const displayToTheLeft = {
    pages: pagesToTheLeft.filter(page => page > firstPage),
    displayReticence: Math.min(...pagesToTheLeft) > firstPage + 1,
  }

  const pagesToTheRight = Array.from(Array(extraPagesToBeRendered).keys(), (number) => (currentPage + 1) + number)

  const displayToTheRight = {
    pages: pagesToTheRight.filter(page => page < lastPage),
    displayReticence: Math.max(...pagesToTheRight) < (lastPage - 1),
  }

  return {
    displayToTheLeft,
    displayToTheRight,
  }
}

function handlePreviousPage(currentPage: number, setPage: React.Dispatch<React.SetStateAction<number>>) {
  if (currentPage > 1) {
    setPage(currentPage - 1)
  }
}

function handleNextPage(currentPage: number, totalPages: number, setPage: React.Dispatch<React.SetStateAction<number>>) {
  if(currentPage < totalPages) {
    setPage(currentPage + 1)
  }
}

function handleGoToLastPage(currentPage: number, totalPages: number, setPage: React.Dispatch<React.SetStateAction<number>>) {
  if (currentPage !== totalPages) {
    setPage(totalPages)
  }
}

function handleGoToFirstPage(currentPage: number, setPage: React.Dispatch<React.SetStateAction<number>>) {
  if (currentPage !== 1) {
    setPage(1)
  }
}

function goToPage(currentPage: number, desiredPage: number, setPage: React.Dispatch<React.SetStateAction<number>>) {
  if (currentPage !== desiredPage) {
    setPage(desiredPage)
  }
}
