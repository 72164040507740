export enum InputFieldTypes {
  text = 'text',
  select = 'select',
  datepicker = 'datepicker'
}

type CommonInputProps = {
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
  children?: React.ReactNode
}

export type TextInputProps = CommonInputProps & {
  type: InputFieldTypes.text
}

export type SelectOptions = {
  value: string
  label: string
}

export type SelectInputProps = CommonInputProps & {
  type: InputFieldTypes.select
  options: Array<SelectOptions>
}

export type DatepickerValue = {
  start: string
  end: string
}

export type DatepickerInputProps = Omit<CommonInputProps, 'value' | 'setValue'> & {
  type: InputFieldTypes.datepicker
  value: DatepickerValue
  setValue: React.Dispatch<React.SetStateAction<DatepickerValue>>
  labelParams?: LabelParams
}

export type LabelParams = {
  i18nTag: string
  onClean?: () => void
}

export type InputProps = (TextInputProps | SelectInputProps | DatepickerInputProps) & {
  labelParams?: LabelParams
}
