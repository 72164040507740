import {
  ActivityLogState,
  ActivityLogAction,
  ACTIVITY_LOG_FAILURE,
  ACTIVITY_LOG_SUCCESS,
  ACTIVITY_LOG_SCAN,
  ACTIVITY_LOG_CANCEL,
  ACTIVITY_LOG_CHANGE_PAGE,
  ACTIVITY_LOG_CLEAR_CACHE,
  ACTIVITY_LOG_SET_COMMANDS_LIST
} from './types';

const initialState: ActivityLogState = {
  results: undefined,
  commandList: undefined
};

export default function (state = initialState, { type, payload }: ActivityLogAction) {
  switch (type) {
    case ACTIVITY_LOG_SCAN:
    case ACTIVITY_LOG_FAILURE: {
      return { ...state };
    }
    case ACTIVITY_LOG_SUCCESS: {
      return {
        ...state,
        results: {
          totalElements: payload.data.totalElements,
          items: payload.data.items,
          itemsToshow: payload.data.items.slice(payload.startPage, payload.endPage)
        }
      };
    }
    case ACTIVITY_LOG_CANCEL: {
      return {
        ...state,
        results: payload
      };
    }

    case ACTIVITY_LOG_CHANGE_PAGE:
      return {
        ...state,
        results: {
          totalElements: state.results && state.results.totalElements,
          items: state.results && state.results.items,
          itemsToshow:
            state.results && state.results.items.slice(payload.startPage, payload.endPage)
        }
      };

    case ACTIVITY_LOG_SET_COMMANDS_LIST:
      const commands = payload.data.items.map((result: any) => result.eventName);
      const onlyUnique = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      };

      return {
        ...state,
        commandList: commands.filter(onlyUnique)
      };
    default: {
      return { ...state };
    }
  }
}