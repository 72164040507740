import { TFunction } from 'i18next'
import React from 'react'
import { OnGetDeviceCallback } from '../../RtfComponent'
import './index.scss'

type SearchProps = {
  udid: string
  setUdid: React.Dispatch<React.SetStateAction<string>>
  onGetDevice: OnGetDeviceCallback
  t: TFunction
}

function handleOnEnter(event: React.KeyboardEvent<HTMLInputElement>, udid: string, onGetDevice: OnGetDeviceCallback) {  
  if(['Enter', 'NumpadEnter'].includes(event.key) && (udid && udid !== '*')) {
    onGetDevice(udid)
  }
}

export default function SearchComponent({ udid, setUdid, onGetDevice, t }: SearchProps) {
  return (
  <div className="rtf-container">
    <div className="input-wrap">
      <input
        type="text"
        className="input-text"
        placeholder=""
        value={udid}
        onChange={(event) => setUdid(event.target.value)}
        onKeyDown={(event) => handleOnEnter(event, udid, onGetDevice)}
      />
      <span className="floating-label">
        <span className="has-padding-right-xs">
          {t('advanced_search.column_udid')}
        </span>
      </span>
    </div>
    <button
      className="button is-larger is-primary"
      disabled={!udid || udid === '*'}
      onClick={() => onGetDevice(udid)}
    >
      {t('general.search')}
    </button>
  </div>
  )
}
