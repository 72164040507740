import { envs } from 'application/envHandler'
import apiClient from 'core/api'
import { AxiosFetchHistoryResponse, AxiosGenerateDownloadURLResponse, DownloadURL, DownloadURLResponse, HistoryFilter, LogsHistory, RawLogsHistoryList } from './types'

function handleDatetime(startDateString: string, endDateString: string) {
  const startDateQuery = new Date(startDateString).toISOString().replace('T', ' ').slice(0, -5)
  const endDateQuery = new Date(endDateString).toISOString().replace('T', ' ').slice(0, -5)

  return {
    startDate: startDateQuery,
    endDate: endDateQuery,
  }
}

function editDate(filter: HistoryFilter) {
  return handleDatetime(filter.start, filter.end)  
}

export async function fetchHistory(deviceId: string, filter: HistoryFilter) {
  return apiClient.get('/stb-logs', {
    baseURL: envs.REACT_APP_API_URL,
    params: {
      deviceId,
      ...editDate(filter),
    },
  }) as Promise<AxiosFetchHistoryResponse>
}

export function logsHistoryMapper(list: RawLogsHistoryList): Array<LogsHistory> {
  return list.map(listItem => {
    const { dayFile, monthFile, yearFile, filename } = listItem
    return {
      date: `${dayFile}-${monthFile}-${yearFile}`,
      filename: filename.split('/')[10],
      link: filename,
    }
  })
}

export async function generateDownloadURL(deviceId: string, data: Array<string>) {
  return apiClient.post('/stb-logs/generateLink', data, {
    baseURL: envs.REACT_APP_API_URL,
    params: {
      deviceId,
    }
  }) as Promise<AxiosGenerateDownloadURLResponse>
}

export function downloadURLMapper(data: DownloadURLResponse): DownloadURL {
  return {
    link: data.downloadLink,
    expiration: Date.now() + Number(data.timeToExpire),
  }
}

export function getSelectedLogsHistoryEntries(list: Array<LogsHistory>, selectedEntries: Array<number>) {
  return selectedEntries.map(entryIndex => list[entryIndex])
}
