import React from 'react'

type GridContainerProps = {
  children: React.ReactNode
  gridTemplateColumns?: string
  gridTemplateRows?: string
  columnGap?: string
  rowGap?: string
}

export default function GridContainer({
  children,
  gridTemplateColumns = '1fr',
  gridTemplateRows = '1fr',
  columnGap = '20px',
  rowGap = '20px',
}: GridContainerProps) {
  return (
    <div style={{
      display: 'grid', 
      gridTemplateColumns,
      gridTemplateRows,
      columnGap,
      rowGap
    }}>
      {children}
    </div>
  )
}
