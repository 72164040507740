import { Close } from 'assets/svg'
import React from 'react'

type CleanFieldProps = {
  onClean: () => void
}

export default function CleanField({ onClean }: CleanFieldProps) {
  return (
    <>
      <div className="tooltip-circle" onClick={onClean}>
        <Close className="icon-xx-small icon-white"/>
      </div>
    </>
  )
}
