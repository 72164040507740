import { connect } from 'react-redux'
import { ApplicationState } from 'application/application-redux'
import RtfComponent from './RtfComponent'
import { getDevice, clearCurrentDevice } from './redux/devices/actions'
import { getAssetsValuesAndSetSettings } from './redux/settings/actions'
import { clearHistory } from './redux/logs/history/actions'

const mapStateToProps = (state: ApplicationState) => ({
  currentDevice: state.rtf.rtfDevices.current,
  t: state.i18n.t,
  settings: state.rtf.rtfSettings,
})

export default connect(mapStateToProps, {
  getDevice,
  clearCurrentDevice,
  getAssetsValuesAndSetSettings,
  clearHistory,
})(RtfComponent)
