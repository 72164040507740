import { envs } from 'application/envHandler';
import { settingsSideMenu } from './config';
import {
  SideMenu,
  SideMenuAction,
  SideMenuState,
  SIDE_MENU_CLEAR_CACHE,
  SIDE_MENU_TOGGLE_CATEGORY,
  SIDE_MENU_TOGGLE_SUBCATEGORY
} from './types';


let initialState = {} as SideMenuState

export function initializeState() {
  const inicialSideMenu = new Map()
  inicialSideMenu.set('settings', settingsSideMenu())

  initialState = {
    sideMenu: inicialSideMenu
  }
}

export default function (state = initialState, { type, payload }: SideMenuAction) {
  switch (type) {
    case SIDE_MENU_TOGGLE_CATEGORY: {
      const { sideMenu } = state;
      const selectedMenu = sideMenu.get(payload.id);

      if (selectedMenu) {
        const currentSideM: SideMenu = {
          ...selectedMenu,
          categories: selectedMenu.categories.map((category: any) => {
            if (category.name === payload.category) {
              if (category.subCategories) {
                return {
                  ...category,
                  isActive: true,
                  subCategories: category.subCategories.map((subCategory: any) => {
                    const someActive = category.subCategories.some(
                      (subCa: any) => subCa.isActive === true
                    );
                    if (!someActive) {
                      return (category.subCategories[0].isActive = true);
                    } else {
                      return subCategory;
                    }
                  })
                };
              } else {
                return { ...category, isActive: true };
              }
            } else {
              return { ...category, isActive: false };
            }
          })
        };

        sideMenu.delete(payload.id);
        sideMenu.set(payload.id, currentSideM);
      }

      return {
        ...state,
        sideMenu
      };
    }

    case SIDE_MENU_TOGGLE_SUBCATEGORY: {
      const { sideMenu } = state;
      const selectedMenu = state.sideMenu.get(payload.id);

      if (selectedMenu) {
        const currentSideM: SideMenu = {
          ...selectedMenu,
          categories: selectedMenu.categories.map((category: any) => {
            if (category.name === payload.category) {
              if (category.subCategories) {
                return {
                  ...category,
                  subCategories: category.subCategories.map((subCategory: any) => {
                    if (subCategory.name === payload.subCategory) {
                      return { name: subCategory.name, isActive: true };
                    } else {
                      return { name: subCategory.name, isActive: false };
                    }
                  })
                };
              }
            } else {
              return { ...category };
            }
          })
        };

        sideMenu.delete(payload.id);
        sideMenu.set(payload.id, currentSideM);
      }

      return {
        ...state,
        sideMenu
      };
    }

    case SIDE_MENU_CLEAR_CACHE: {
      const { sideMenu } = state;
      sideMenu.delete('settings');
      sideMenu.set('settings', settingsSideMenu())

      return {
        ...state,
        sideMenu
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}
