import { t } from 'i18next'
import React from 'react'
import { DatepickerValue, LabelParams } from '../types'
import CleanField from './CleanField'

type FloatingLabelProps = LabelParams & { value: string | DatepickerValue }

export default function FloatingLabel({ i18nTag, onClean, value }: FloatingLabelProps) {
  return (
    <span className="floating-label">
      <span className="has-padding-right-xs">
        {t(i18nTag)}
      </span>
      {onClean && value && <span className="has-padding-top-xxs input-wrap-tooltip">
        <CleanField onClean={onClean}/>
      </span>}
    </span>
  )
}
