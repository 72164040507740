import { downloadURLMapper, logsHistoryMapper } from "./services"
import { LogsHistoryActions, LogsHistoryPayload, LogsHistoryState } from "./types"

const initialDownloadState = {
  link: '',
  expiration: 0,
}

const initialState: LogsHistoryState = {
  list: [],
  download: initialDownloadState,
  totalItems: 0,
  requestId: '',
}

export default function (state = initialState, action: LogsHistoryPayload) {
  switch (action.type) {
    case LogsHistoryActions.setList:
      const { records, recordCount, requestID } = action.payload
      return { ...state, list: logsHistoryMapper(records), totalItems: Number(recordCount), requestId: requestID }

    case LogsHistoryActions.clearList:
      return { ...initialState }

    case LogsHistoryActions.setURL:
      return { ...state, download: downloadURLMapper(action.payload) }

    case LogsHistoryActions.clearURL:
      return { ...state, download: initialDownloadState }
  
    default:
      return state
  }
}
