import { ModuleRoute } from 'core/router/module-route-component';

import LoginHandler from 'modules/login';
import Home from 'modules/home';
import DeviceDetail from 'modules/operational/device/detail';
import AdvancedSearch from 'modules/operational/device/advanced-search';
import IdMapping from 'modules/management/id-mapping';
import CollisionIdMapping from 'modules/management/id-mapping/collision';
import EditableIdMapping from 'modules/management/id-mapping/editable-mapping';
import IpGeolocation from 'modules/management/ip-geolocation';
import OnnetOffnet from 'modules/management/onnet-offnet';
import EsnMapping from 'modules/management/esn-mapping';
import Spectrum from 'modules/management/spectrum';
import { VipocGroup } from 'core/auth/constants';
import SpectrumChannelsAssociation from 'modules/management/spectrum/logical-channels/channels/channels-associations';
import Anonymizer from 'modules/management/anonymizer'
import RTF from 'modules/operational/rtf';

const allowedRoutes: Array<ModuleRoute> = [
  {
    path: '/login',
    component: LoginHandler,
    exact: true,
    name: 'Login Handler'
  },
]

const routes: Array<ModuleRoute> = [
  {
    path: '/',
    component: Home,
    exact: true,
    name: 'Home'
  },
  {
    path: '/operational/device',
    component: AdvancedSearch,
    exact: true,
    name: 'Advanced Search',
    group: 'devices'
  },

  {
    path: '/operational/device/:id/:type/:hhid/:serialNr',
    component: DeviceDetail,
    exact: true,
    name: 'Device Detail',
    group: 'devices'
  },
  {
    path: '/management/id-mapping',
    component: IdMapping,
    exact: true,
    name: 'ID Mapping',
    group: 'id-mapping'
  },

  {
    path: '/management/id-mapping/:id/collision',
    component: CollisionIdMapping,
    exact: true,
    name: 'Collision Resolution',
    group: 'id-mapping'
  },

  {
    path: '/management/id-mapping/editable-mapping',
    component: EditableIdMapping,
    exact: true,
    name: 'Editable Id Mapping',
    group: 'id-mapping'
  },

  {
    path: '/management/ip-geolocation',
    component: IpGeolocation,
    exact: true,
    name: 'Ip Geolocation',
    group: 'ip-geolocation'
  },

  {
    path: '/management/on-net-off-net',
    component: OnnetOffnet,
    exact: true,
    name: 'Onnet Offnet',
    group: 'on-net-off-net'
  },

  {
    path: '/management/esn-mapping',
    component: EsnMapping,
    exact: true,
    name: 'ESN Mapping',
    group: 'esn-mapping'
  },

  {
    path: '/management/alexa-capability',
    component: IdMapping,
    exact: true,
    name: 'Alexa',
    group: 'alexa-capability'
  },

  {
    path: '/management/spectrum/:currentPage',
    component: Spectrum,
    exact: true,
    name: 'Spectrum',
    group: 'spectrum'
  },

  {
    path: '/management/spectrum/:currentPage/:mediaId/channels-associations',
    component: SpectrumChannelsAssociation,
    exact: true,
    name: 'Channels Associations',
    group: 'spectrum'
  },  
  {
    path: '/management/anonymizer',
    component: Anonymizer,
    exact: true,
    name: 'Anonymizer',
    group: 'anonymizer'
  },
  {
    path: '/operational/rtf',
    component: RTF,
    exact: true,
    name: 'RTF',
    group: 'rtf',
  }
];

export function preLoadRoutes() {
  return {
    type: 'LOAD_ROUTES',
    payload: allowedRoutes
  };
}

export function loadRoutes(currentUser: any, currentOpco: any) {
  if (currentUser) {
    const filteredRoutes = routes.filter(
      route =>
        !route.group ||
        currentUser.groups.filter(
          (group: VipocGroup) => group.moduleName === route.group && group.opco === currentOpco
        ).length > 0
    );

    return {
      type: 'LOAD_ROUTES',
      payload: filteredRoutes.concat(allowedRoutes)
    };
  }

  return {
    type: 'LOAD_ROUTES',
    payload: []
  };
}

export function routesReducer(state = {}, action: any) {
  switch (action.type) {
    case 'LOAD_ROUTES':
      return { ...state, loaded: action.payload };
    default:
      return { ...state };
  }
}
