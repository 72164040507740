import {
  Asset,
  AssetValue,
  SettingsAction,
  SettingsField,
  SettingsState,
  SETTINGS_ACTIONS_RESET,
  SETTINGS_ACTIONS_TOGGLE_ACCORDION,
  SETTINGS_ACTIONS_TOGGLE_OPTIONS,
  SETTINGS_CLEAR_CACHE,
  SETTINGS_CLEAR_FIELDS,
  SETTINGS_COMMAND_EXECUTION,
  SETTINGS_FETCH_ASSET_VALUES,
  SETTINGS_PARENTAL_PIN_ERROR,
  SETTINGS_SET_FIELDS,
  SETTINGS_UPDATE_FIELDS,
} from './types';

const initialState: SettingsState = {
  assetsValues: undefined,
  configurations: undefined,
  hasChanged: false,
  actions: undefined
};

export default function (state = initialState, { type, payload }: SettingsAction) {
  switch (type) {
    case SETTINGS_FETCH_ASSET_VALUES:
      const setUILanguage: AssetValue[] = [];
      setUILanguage.push({ label: 'settings.label_select', value: '', active: false });
      payload.data.filter((asset: Asset) => {
        if (asset.assetName === 'setUILanguage') {
          return setUILanguage.push({
            label: asset.label,
            value: asset.value
          });
        }
      });

      const setPreferredAudioLanguage: AssetValue[] = [];
      setPreferredAudioLanguage.push({ label: 'settings.label_select', value: '', active: false });
      payload.data.filter((asset: Asset) => {
        if (asset.assetName === 'setPreferredAudioLanguage') {
          return setPreferredAudioLanguage.push({
            label: asset.label,
            value: asset.value
          });
        }
      });

      const setParentalMode: AssetValue[] = [];
      setParentalMode.push({ label: 'settings.label_select', value: '', active: false });
      payload.data.filter((asset: Asset) => {
        if (asset.assetName === 'setParentalMode') {
          return setParentalMode.push({
            label: asset.label,
            value: asset.value
          });
        }
      });

      const hideSetParentalMode: AssetValue[] = [];
      payload.data.filter((asset: Asset) => {
        if (asset.assetName === 'hideSetParentalMode') {
          return hideSetParentalMode.push({
            label: asset.label,
            value: asset.value
          });
        }
      });

      const setActiveStandbyTimer: AssetValue[] = [];
      setActiveStandbyTimer.push({ label: 'settings.label_select', value: '', active: false });
      payload.data.filter((asset: Asset) => {
        if (asset.assetName === 'setActiveStandbyTimer') {
          return setActiveStandbyTimer.push({
            label: asset.label,
            value: asset.value
          });
        }
      });

      const setLocalPaddingTime: AssetValue[] = [];
      setLocalPaddingTime.push({ label: 'settings.label_select', value: '', active: false });
      payload.data.filter((asset: Asset) => {
        if (asset.assetName === 'setLocalPaddingTime') {
          return setLocalPaddingTime.push({
            label: asset.label,
            value: asset.value
          });
        }
      });

      const changeChannelVisibility: AssetValue[] = [];
      payload.data.filter((asset: Asset) => {
        if (asset.assetName === 'changeChannelVisibility') {
          return changeChannelVisibility.push({
            label: asset.label,
            value: asset.value,
            selected: false
          });
        }
      });

      const setPreferredSubtitleLanguage: AssetValue[] = [];
      setPreferredSubtitleLanguage.push({
        label: 'settings.label_select',
        value: '',
        active: false
      });
      payload.data.filter((asset: Asset) => {
        if (asset.assetName === 'setPreferredSubtitleLanguage') {
          return setPreferredSubtitleLanguage.push({
            label: asset.label,
            value: asset.value
          });
        }
      });

      const audioState: AssetValue[] = [];
      audioState.push(
        { value: 'true', label: 'settings.label_aud_state_muted' },
        { value: 'false', label: 'settings.label_aud_state_unmuted' }
      );

      const subtitles: AssetValue[] = [];
      subtitles.push(
        { label: 'settings.label_select', value: '', active: false },
        { label: 'settings.value_on', value: 1 },
        { label: 'settings.value_off', value: 0 }
      );

      const hdmiAudioMode: AssetValue[] = [];
      hdmiAudioMode.push(
        { label: 'settings.label_select', value: '', active: false },
        { label: 'settings.value_auto', value: 'auto' },
        { label: 'settings.value_pcm', value: 'pcm' },
        { label: 'settings.value_dolby', value: 'dolby' }
      );

      const unsubscribedChannels: AssetValue[] = [];
      unsubscribedChannels.push(
        { label: 'settings.label_select', value: '', active: false },
        { label: 'settings.value_show', value: 'show' },
        { label: 'settings.value_hide', value: 'hide' }
      );

      const parentalModeVisibility: AssetValue[] = [];
      parentalModeVisibility.push(
        { label: 'settings.label_select', value: '', active: false },
        { label: 'settings.value_show', value: 'show' },
        { label: 'settings.value_pin', value: 'pin' },
        { label: 'settings.value_hide', value: 'hide' }
      );

      const visualImpAudio: AssetValue[] = [];
      visualImpAudio.push(
        { value: 'true', label: 'settings.button_enabled' },
        { value: 'false', label: 'settings.button_disabled' }
      );

      const setPowerSavingMode: AssetValue[] = [];
      setPowerSavingMode.push(
        { value: 'active', label: 'settings.value_active' },
        { value: 'passive', label: 'settings.value_passive' }
      );

      const hearingDisabilities: AssetValue[] = [];
      hearingDisabilities.push(
        { value: 'true', label: 'settings.button_enabled' },
        { value: 'false', label: 'settings.button_disabled' }
      );

      const updateToSpecificFirmware: AssetValue[] = [];
      payload.data.filter((asset: Asset) => {
        if (asset.assetName === 'updateToSpecificFirmware') {
          return updateToSpecificFirmware.push({
            label: asset.label,
            value: asset.value
          });
        }
      });

      return {
        ...state,
        assetsValues: {
          setUILanguage,
          setPreferredAudioLanguage,
          setParentalMode,
          hideSetParentalMode,
          setActiveStandbyTimer,
          setLocalPaddingTime,
          changeChannelVisibility,
          setPreferredSubtitleLanguage,
          audioState,
          subtitles,
          hdmiAudioMode,
          unsubscribedChannels,
          parentalModeVisibility,
          visualImpAudio,
          setPowerSavingMode,
          hearingDisabilities,
          updateToSpecificFirmware
        }
      };

    case SETTINGS_SET_FIELDS:
      payload.allFieldsData &&
        payload.allFieldsData.configurations.map((comp: any) => {
          return {
            component: comp.component,
            fields: comp.fields.map((field: SettingsField) => {
              if (field.type === 'check') {
                field.available &&
                  field.available.map((available: any) => {
                    return (available.selected = false);
                  });
              }
              return { ...field, currentValue: field.value };
            }),
            hasChanged: false
          };
        });

      return {
        ...state,
        configurations: payload.allFieldsData && payload.allFieldsData.configurations,
        actions: payload.allFieldsData && payload.allFieldsData.actions
      };

    case SETTINGS_CLEAR_FIELDS:
      let config: any = [];
      if (payload.componentId) {
        config =
          state.configurations &&
          state.configurations.map((component: any) => {
            if (component.component === payload.componentId) {
              return {
                component: component.component,
                fields: component.fields.map((field: SettingsField) => {
                  if (field === undefined || Object.keys(field).length === 0) {
                    return {};
                  } else {
                    if (field.type === 'check') {
                      return {
                        ...field,
                        currentValue: '',
                        available:
                          field.available &&
                          field.available.map((available: AssetValue) => {
                            return { ...available, selected: false };
                          })
                      };
                    } else if (field.error) {
                      return {
                        currentValue: field.value,
                        label: field.label,
                        name: field.name,
                        type: field.type,
                        value: field.value
                      };
                    } else {
                      return { ...field, currentValue: field.value };
                    }
                  }
                }),
                hasChanged: false
              };
            } else {
              return component;
            }
          });
      } else {
        config =
          state.configurations &&
          state.configurations.map((component: any) => {
            return {
              component: component.component,
              fields: component.fields.map((field: SettingsField) => {
                if (field === undefined || Object.keys(field).length === 0) {
                  return {};
                } else {
                  if (field.type === 'check') {
                    return {
                      ...field,
                      currentValue: '',
                      available:
                        field.available &&
                        field.available.map((available: AssetValue) => {
                          return { ...available, selected: false };
                        })
                    };
                  }
                  return { ...field, currentValue: field.value };
                }
              }),
              hasChanged: false
            };
          });
      }

      return {
        ...state,
        configurations: config,
        hasChanged:
          (config && config.some((it: any) => it.hasChanged)) ||
          (state.actions && state.actions.hasChanged)
      };

    case SETTINGS_CLEAR_CACHE:
      return {
        ...state,
        assetsValues: undefined,
        configurations: undefined,
        actions: undefined,
        hasChanged: false
      };

    case SETTINGS_UPDATE_FIELDS:
      const conf =
        state.configurations &&
        state.configurations.map((component: any) => {
          if (component.component === payload.componentId) {
            return {
              component: component.component,
              fields: component.fields.map((field: SettingsField) => {
                if (field.name === payload.field.name) {
                  if (field.type === 'check') {
                    const currentValue: any = [];

                    field.available &&
                      field.available.filter((item: AssetValue) => {
                        if (item.label === payload.field.newValue) {
                          return (item.selected = !item.selected);
                        }
                      });

                    field.available &&
                      field.available.filter((element: AssetValue) => {
                        if (element.selected === true) {
                          currentValue.push(element.value);
                        }
                      });

                    return {
                      ...field,
                      currentValue
                    };
                  }
                  return {
                    ...field,
                    currentValue:
                      typeof payload.field.value === 'number'
                        ? parseInt(payload.field.newValue)
                        : payload.field.newValue
                  };
                } else {
                  return field;
                }
              })
            };
          } else {
            return component;
          }
        });

      const configurations =
        conf &&
        conf.map((item: any) => {
          if (item.component === payload.componentId) {
            return {
              ...item,
              hasChanged: item.fields.some((field: SettingsField) => {
                if (field.value != field.currentValue) {
                  return true;
                } else {
                  return false;
                }
              })
            };
          } else {
            return item;
          }
        });

      return {
        ...state,
        configurations,
        hasChanged:
          (configurations && configurations.some((it: any) => it.hasChanged)) ||
          (state.actions && state.actions.hasChanged)
      };

    case SETTINGS_COMMAND_EXECUTION:
      const comp =
        state.configurations &&
        state.configurations.find((component: any) =>
          component.fields.find(
            (field: SettingsField) => field !== undefined && field.label === payload.field
          )
        );

      const con =
        state.configurations &&
        state.configurations.map((component: any) => {
          if (comp && comp.component === component.component) {
            return {
              component: component.component,
              fields: component.fields.map((field: SettingsField) => {
                if (field === undefined || Object.keys(field).length === 0) {
                  return {};
                } else {
                  if (!field.error) {
                    field.value = field.currentValue;
                    return { ...field };
                  } else if (field.error) {
                    return {
                      currentValue: field.currentValue,
                      label: field.label,
                      name: field.name,
                      type: field.type,
                      value: field.currentValue
                    };
                  }
                }
              }),
              hasChanged: false
            };
          } else {
            return component;
          }
        });

      const isAction =
        payload.field === 'partial_reset' ||
        payload.field === 'factory_reset' ||
        payload.field === 'reboot';
      const actionsCommands = isAction
        ? state.actions &&
          state.actions.fields.map((action: SettingsField) => {
            return {
              ...action,
              selected: false,
              available:
                action.available &&
                action.available.map((field: AssetValue) => {
                  return {
                    ...field,
                    value: false
                  };
                })
            };
          })
        : state.actions && state.actions.fields;

      return {
        ...state,
        configurations: con,
        actions: {
          fields: actionsCommands,
          hasChanged: isAction ? false : state.actions && state.actions.hasChanged
        },
        hasChanged:
          (con && con.some((it: any) => it.hasChanged)) ||
          (isAction ? false : state.actions && state.actions.hasChanged)
      };

    case SETTINGS_PARENTAL_PIN_ERROR:
      const confg =
        state.configurations &&
        state.configurations.map((component: any) => {
          if (component.component === 'parental_mode') {
            return {
              component: component.component,
              fields: component.fields.map((field: SettingsField) => {
                if (field.name === 'parentalPinValue') {
                  field.error = 'settings.parental_mode.parental_pin.error.length';
                  return { ...field };
                } else {
                  return { ...field };
                }
              }),
              hasChanged: component.hasChanged
            };
          } else {
            return component;
          }
        });

      return {
        ...state,
        configurations: confg
      };

    case SETTINGS_ACTIONS_TOGGLE_ACCORDION:
      const actions =
        state &&
        state.actions &&
        state.actions.fields &&
        state.actions.fields.map((item: SettingsField, index: number) => {
          if (index === payload.index) {
            return {
              ...item,
              selected: !item.selected,
              available:
                item.available &&
                item.available.map((el: AssetValue) => {
                  return {
                    ...el,
                    selected: false
                  };
                })
            };
          } else {
            return {
              ...item,
              selected: false,
              available:
                item.available &&
                item.available.map((el: AssetValue) => {
                  return {
                    ...el,
                    selected: false
                  };
                })
            };
          }
        });

      const isDifferent =
        actions && actions.some((act: any) => act.available.some((it: any) => it.selected));

      return {
        ...state,
        actions: {
          fields: actions,
          hasChanged: isDifferent
        },
        hasChanged:
          isDifferent ||
          (state.configurations && state.configurations.some((it: any) => it.hasChanged))
      };

    case SETTINGS_ACTIONS_TOGGLE_OPTIONS:
      const someItem =
        state.actions &&
        state.actions.fields.some((item: SettingsField) => {
          if (item && item.name === 'partial_reset') {
            return (
              item.available &&
              item.available.some((el: AssetValue) => {
                if (el.label === payload.option) {
                  return true;
                }
              })
            );
          }
        });

      const action =
        state &&
        state.actions &&
        state.actions.fields.map((item: SettingsField) => {
          return {
            ...item,
            available:
              item.available &&
              item.available.map((el: AssetValue) => {
                if (someItem) {
                  if (el.label === payload.option) {
                    return {
                      ...el,
                      selected: !el.selected
                    };
                  } else {
                    return {
                      ...el
                    };
                  }
                } else {
                  if (el.label === payload.option) {
                    return {
                      ...el,
                      selected: !el.selected
                    };
                  } else {
                    return {
                      ...el,
                      selected: false
                    };
                  }
                }
              })
          };
        });

      const hasChanged =
        action && action.some((act: any) => act.available.some((it: any) => it.selected));

      return {
        ...state,
        actions: { fields: action, hasChanged },
        hasChanged:
          hasChanged ||
          (state.configurations && state.configurations.some((it: any) => it.hasChanged))
      };

    case SETTINGS_ACTIONS_RESET:
      const act =
        state.actions &&
        state.actions.fields.map((action: SettingsField) => {
          return {
            ...action,
            available:
              action.available &&
              action.available.map((option: AssetValue) => {
                return {
                  ...option,
                  selected: false
                };
              })
          };
        });
      return {
        ...state,
        actions: { fields: act, hasChanged: false },
        hasChanged:
          false ||
          (state.configurations && state.configurations.some((it: any) => it.hasChanged))
      };
    default: {
      return { ...state };
    }
  }
}
