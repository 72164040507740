import React from 'react'
import { TextInputProps } from '../types'

export default function Text({ type, value, setValue, disabled, children }: TextInputProps) {
  return (
    <div className="input-wrap">
      <input
        type={type}
        className="input-text"
        placeholder=" "
        value={value}
        onChange={(event) => setValue(event.target.value)}
        disabled={disabled}
      />
      {children}
    </div>
  )
}
