export const RTF_ACTIVITY_FETCH = 'RTF_ACTIVITY_FETCH'
export const RTF_ACTIVITY_CANCEL_COMMAND = 'RTF_ACTIVITY_CANCEL_COMMAND'

export type ActivityLogState = {
  list: ActivityLogList
  totalItems: number
}

export type CustomDeviceFields = {}

export enum ActivityLogEventName {
  changeChannelVisibility = 'change_channel_visibility',
  deleteLocalPlannedRecording = 'delete_local_planned_recording',
  deleteLocalRecording = 'delete_local_recording',
  deleteRemoveUnmanagedDevices = 'delete_remove_unmanaged_devices',
  doFactoryReset = 'do_factory_reset',
  doPartialReset = 'do_partial_reset',
  getFrequencyDetails = 'get_frequency_details',
  goToPowerState = 'go_to_power_state',
  loggingEnable = 'logging_enable',
  loggingFlush = 'logging_flush',
  reboot = 'reboot',
  runChannelScan = 'run_channel_scan',
  runChannelsStats = 'run_channels_stats',
  runRegionDiscovery = 'run_region_discovery',
  runSpeedTest = 'run_speed_test',
  setActiveStandbyTimer = 'set_active_standby_timer',
  setAudioDescription = 'set_audio_description',
  setAudioVolume = 'set_audio_volume',
  setHDMIAudioMode = 'set_hdmi_audio_mode',
  setHdmiMode = 'set_hdmi_mode',
  setHearingDisabilityMode = 'set_hearing_disability_mode',
  setLocalPaddingTime = 'set_local_padding_time',
  setParentalMode = 'set_parental_mode',
  setParentalPin = 'set_parental_pin',
  setPowerSavingMode = 'set_power_saving_mode',
  setPreferredAudioLanguage = 'set_preferred_audio_language',
  setPreferredSubtitleLanguage = 'set_preferred_subtitle_language',
  setStandbyMode = 'set_standby_mode',
  setSubtitleMode = 'set_subtitle_mode',
  setUiLanguage = 'set_ui_language',
  updateToSpecificFirmware = 'update_to_specific_firmware',
}

export enum ActivityLogStatus {
  completed = 'COMPLETED',
  succeeded = 'SUCCEEDED',
  inProgress = 'IN_PROGRESS',
  queued = 'QUEUED',
  received = 'RECEIVED',
  started = 'STARTED',
  failed = 'FAILED',
  timeExceeded = 'TIME_EXCEEDED',
  canceled = 'CANCELED',
  rejected = 'REJECTED',
}

export enum ActivityLogStatusGroup {
  success = 'success',
  inProgress = 'inProgress',
  failed = 'failed',
} 

export type ActivityLog = {
  commandTraceId: string
  createdBy: string
  customDeviceFields: CustomDeviceFields
  deviceModel: string
  eventName: ActivityLogEventName
  eventType?: string
  household: string
  id: string
  lastManaged: string
  logRequest?: string
  logResponse?: string
  status: ActivityLogStatus
  udid: string
}

export type ActivityLogList = Array<ActivityLog>

export type ActivityLogResponse = {
  totalElements: string
  items: ActivityLogList
}

export type AxiosFetchActivityResponse = {
  data: ActivityLogResponse
}

export type ActivityLogFilter = {
  traceId?: string
  status?: string
  eventName?: string
  lastManagedStart?: string
  lastManagedEnd?: string
}

export enum ActivityLogActions {
  setList = 'setList',
  clearList = 'clearList',
  cancelCommand = 'cancelCommand',
}

export type ActivityLogPayload = {
  type: ActivityLogActions.setList
  payload: ActivityLogResponse
} | {
  type: ActivityLogActions.clearList
  payload: undefined
} | {
  type: ActivityLogActions.cancelCommand
  payload: string
}

export type GetActivityLogFunction = (id: string, filter?: ActivityLogFilter) => void

export type CancelCommandFunction = (deviceId: string, jobId: string) => void

export type ClearActivityLogStateFunction = () => void

export const EVENT_NAME_FIELD_LABEL_MAP = {
  changeChannelVisibility: 'command.label.change_channel_visibility',
  deleteLocalPlannedRecording: 'command.label.delete_local_planned_recording',
  deleteLocalRecording: 'command.label.delete_local_recording',
  deleteRemoveUnmanagedDevices: 'command.label.delete_remove_unmanaged_devices',
  doFactoryReset: 'command.label.do_factory_reset',
  doPartialReset: 'command.label.do_partial_reset',
  getFrequencyDetails: 'command.label.get_frequency_details',
  goToPowerState: 'command.label.go_to_power_state',
  loggingEnable: 'command.label.logging_enable',
  loggingFlush: 'command.label.logging_flush',
  reboot: 'command.label.reboot',
  runChannelScan: 'command.label.run_channel_scan',
  runChannelsStats: 'command.label.run_channels_stats',
  runRegionDiscovery: 'command.label.run_region_discovery',
  runSpeedTest: 'command.label.run_speed_test',
  setActiveStandbyTimer: 'command.label.set_active_standby_timer',
  setAudioDescription: 'command.label.set_audio_description',
  setAudioVolume: 'command.label.set_audio_volume',
  setHDMIAudioMode: 'command.label.set_hdmi_audio_mode',
  setHdmiMode: 'command.label.set_hdmi_mode',
  setHearingDisabilityMode: 'command.label.set_hearing_disability_mode',
  setLocalPaddingTime: 'command.label.set_local_padding_time',
  setParentalMode: 'command.label.set_parental_mode',
  setParentalPin: 'command.label.set_parental_pin',
  setPowerSavingMode: 'command.label.set_power_saving_mode',
  setPreferredAudioLanguage: 'command.label.set_preferred_audio_language',
  setPreferredSubtitleLanguage: 'command.label.set_preferred_subtitle_language',
  setStandbyMode: 'command.label.set_standby_mode',
  setSubtitleMode: 'command.label.set_subtitle_mode',
  setUiLanguage: 'command.label.set_ui_language',
  updateToSpecificFirmware: 'command.label.update_to_specific_firmware',
} as {
  [k: string]: string
}
