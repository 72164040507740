import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import Header from '../Device/components/Header'
import { getFirstActiveTab } from '../redux/devices/services'
import { RTFDeviceData } from '../redux/devices/types'
import { RTFSettingsState } from '../redux/settings/types'
import Content from './components/Content'
import Sidebar from './components/Sidebar'

type SettingComponentProps = {
  currentDevice: RTFDeviceData
  t: TFunction
  settings: RTFSettingsState
}

export default function SettingsComponent({ currentDevice, t, settings }: SettingComponentProps) {
  const { hardwareModel, softwareVersion, udid } = currentDevice
  const [tab, setTab] = useState(getFirstActiveTab(hardwareModel, softwareVersion))

  useEffect(() => {
    setTab(getFirstActiveTab(hardwareModel, softwareVersion))
  }, [udid])

  return (
    <>
      <Header {...currentDevice} t={t}/>
      <div className="columns has-margin-top-md">
        <div className="column is-2"><Sidebar t={t} tab={tab} setTab={setTab}/></div>
        <div className="column is-10"><Content tab={tab} settings={settings.settings}/></div>
      </div>
    </>
  )
}
