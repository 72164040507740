import { CloseModalFunction, OpenModalFunction } from 'core/modal/redux/types'
import { TFunction } from 'i18next'
import { showModalAndExecCommand } from 'modules/operational/rtf/redux/settings/actions'
import { ExecuteCommandType, ResetStatesFunction, RTFLogLevelsSettings, RunCommandsFunction } from 'modules/operational/rtf/redux/settings/types'
import React, { useCallback, useState } from 'react'
import ButtonContainer from './common/ButtonContainer'
import Field from './common/Field'
import GridContainer from './common/GridContainer'

type LogLevelsProps = {
  settings: RTFLogLevelsSettings
  t: TFunction
  deviceId: string
  connected: boolean
  openModal: OpenModalFunction
  closeModal: CloseModalFunction
  runCommands: RunCommandsFunction
  executeCommand: ExecuteCommandType
}

function handleCheckboxOnChange(levelsSelected: Array<string>, setLevelsSelected: React.Dispatch<React.SetStateAction<string[]>>, value: string) {
  if(levelsSelected.includes(value)) {
    return setLevelsSelected(levelsSelected.filter(level => level !== value))
  }

  setLevelsSelected([...levelsSelected, value])
}

function turnOn(
  deviceId: string,
  connected: boolean,
  levelsSelected: Array<string>,
  ui: string,
  window: string,
  openModal: OpenModalFunction,
  closeModal: CloseModalFunction,
  runCommands: RunCommandsFunction,
  executeCommand: ExecuteCommandType,
  settings: RTFLogLevelsSettings,
  resetStates: ResetStatesFunction,
) {
  const parsedUi = Number(ui)
  let logLevel = 0

  if(levelsSelected.length) {
    settings.levels.options.forEach(level => {
      if(levelsSelected.includes(level.label)) {
        logLevel += Number(level.value)
      }
    })
  }

  if(parsedUi) {
    logLevel += parsedUi
  }

  const commandConfig = {
    command: 'loggingEnable',
    key: 'log_levels',
    jsonParameters: {
      logLevel,
      window: Number(window),
    }
  }

  showModalAndExecCommand(deviceId, openModal, closeModal, runCommands, commandConfig, connected, executeCommand, resetStates)
}

function turnOff(
  deviceId: string,
  connected: boolean,
  openModal: OpenModalFunction,
  closeModal: CloseModalFunction,
  runCommands: RunCommandsFunction,
  executeCommand: ExecuteCommandType,
) {
  const commandConfig = {
    command: 'loggingEnable',
    key: 'logging_enable',
    jsonParameters: {
      logLevel: 0,
      window: 0,
    }
  }

  showModalAndExecCommand(deviceId, openModal, closeModal, runCommands, commandConfig, connected, executeCommand)
}

export default function LogLevels({
  settings,
  t,
  deviceId,
  connected,
  openModal,
  closeModal,
  runCommands,
  executeCommand,
 }: LogLevelsProps) {
  const [ui, setUi] = useState(settings.ui.options[0].value)
  const [window, setWindow] = useState(settings.window.options[0].value)
  const [levelsSelected, setLevelsSelected] = useState<Array<string>>([])

  const resetStates = useCallback(() => {
    setUi(settings.ui.options[0].value)
    setWindow(settings.window.options[0].value)
    setLevelsSelected([])
  }, [])

  return (
    <GridContainer gridTemplateColumns='repeat(2, 1fr)'>
      <Field gridRow={2}>
        <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
          <div className="column is-12">
            <h6 className="text is-h6 is-highlighted">{t(`settings.label_log_levels`)}</h6>
          </div>
          <div className="column is-12 has-padding-top-none has-padding-bottom-none">
            <h6 className="text is-h6 is-bold">{t(`settings.column_value`)}</h6>
          </div>
        </div>
        <div className="columns flex-wrap has-padding-md">
          {settings.levels.options.map((item, key: number) => (
              <div key={key} className="column has-padding-top-lg is-4">
                <div className="has-checkbox checkbox">
                  <input
                    className="is-small"
                    type="checkbox"
                    name={t(item.label)}
                    checked={levelsSelected.includes(item.label)}
                    onChange={() => handleCheckboxOnChange(levelsSelected, setLevelsSelected, item.label)}
                  />
                  <label>{t(item.label)}</label>
                </div>
              </div>
            ))}
        </div>
      </Field>
      <Field>
        <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
          <div className="column is-12">
            <h6 className="text is-h6 is-highlighted">{t(`advanced_search.select_ui`)}</h6>
          </div>
          <div className="column is-12 has-padding-top-none has-padding-bottom-none">
            <h6 className="text is-h6 is-bold">{t(`settings.column_value`)}</h6>
          </div>
        </div>
        <div className="has-padding-left-xs has-padding-right-xs">
          <div className="select is-fullwidth">
            <select
              name="ui"
              value={ui}
              onChange={(e) => setUi(e.target.value)}
            >
              {settings.ui.options.map(
                (item, key: number) => (
                  <option key={key} value={item.value}>
                    {t(item.label)}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
      </Field>
      <Field>
        <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
          <div className="column is-12">
            <h6 className="text is-h6 is-highlighted">{t(`settings.logs_window`)}</h6>
          </div>
          <div className="column is-12 has-padding-top-none has-padding-bottom-none">
            <h6 className="text is-h6 is-bold">{t(`settings.column_value`)}</h6>
          </div>
        </div>
        <div className="has-padding-left-xs has-padding-right-xs">
          <div className="select is-fullwidth">
            <select
              name="window"
              value={window}
              onChange={(e) => setWindow(e.target.value)}
            >
              {settings.window.options.map(
                (item, key: number) => (
                  <option key={key} value={item.value}>
                    {t(item.label)}
                  </option>
                )
              )}
            </select>
          </div>
        </div>
      </Field>
      <ButtonContainer gridColumn={2}>
        <button
          className={`button-link dropdown-link has-padding-right-md ${isDirty(ui, window, levelsSelected, settings) ? 'button-action' : ''}`}
          disabled={!isDirty(ui, window, levelsSelected, settings)}
          onClick={() => resetStates()}
        >
          {t(`general.clear`)}
        </button>
        <button
          className="button is-rounded is-larger is-dark"
          onClick={() => turnOff(deviceId, connected, openModal, closeModal, runCommands, executeCommand)}
          disabled={isDirty(ui, window, levelsSelected, settings)}
        >
          {t('settings.logs.button_turnoff')}
        </button>
        <button
          className="button is-rounded is-larger is-primary"
          onClick={() => {
            turnOn(
              deviceId,
              connected,
              levelsSelected,
              ui,
              window,
              openModal, 
              closeModal, 
              runCommands,  
              executeCommand,
              settings,
              resetStates,
            )
          }}
          disabled={!isValid(ui, window, levelsSelected, settings)}
        >
          {t('settings.logs.button_turnon')}
        </button>
      </ButtonContainer>
    </GridContainer>
  )
}

function isDirty(ui: string, window: string, levelsSelected: Array<string>, settings: RTFLogLevelsSettings) {
  const uiIsDirty = ui !== settings.ui.options[0].value
  const windowIsDirty = window !== settings.window.options[0].value
  const logLevelsIsDirty = levelsSelected.length

  return Boolean(uiIsDirty || windowIsDirty || logLevelsIsDirty)
}

function isValid(ui: string, window: string, levelsSelected: Array<string>, settings: RTFLogLevelsSettings) {
  const windowIsSet = Number(window) > 0
  const selectedValues = Boolean(levelsSelected.length || ui !== settings.ui.options[0].value)

  return windowIsSet && selectedValues
}
