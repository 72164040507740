import { RTFFlushLogsSettings, RTFLogProfilesSettings, RTFSettingsFieldsType } from '../redux/settings/types'

export function getLogProfilesFields(): RTFLogProfilesSettings {
  return {
    osProfileLevel: {
      type: RTFSettingsFieldsType.select,
      options: [
        { label: 'settings.label_select', value: '' },
        { label: 'settings.logs_low', value: 'osProfileLow' },
        { label: 'settings.logs_mid', value: 'osProfileMid' },
        { label: 'settings.logs_high', value: 'osProfileHigh' },
        { label: 'settings.logs_custom', value: 'custom' },
      ],
    },
    appProfileLevel: {
      type: RTFSettingsFieldsType.select,
      options: [
        { label: 'settings.label_select', value: '' },
        { label: 'settings.logs_low', value: 'appProfileLow' },
        { label: 'settings.logs_mid', value: 'appProfileMid' },
        { label: 'settings.logs_high', value: 'appProfileHigh' },
        { label: 'settings.logs_custom', value: 'custom' },
      ],
    },
    window: {
      type: RTFSettingsFieldsType.select,
      options: [
        { label: '0', value: '0' },
        { label: 'settings.logs_1hour', value: '60' },
        { label: 'settings.logs_2hours', value: '120' },
        { label: 'settings.logs_8hours', value: '480' },
        { label: 'settings.logs_24hours', value: '1440' },
        { label: 'settings.logs_7days', value: '10080' },
      ],
    },
  }
}

export function getLogLevelsFields() {
  return {
    levels: {
      type: RTFSettingsFieldsType.check,
      options: [
        { label: 'settings.logs_dvb', value: '128' },
        { label: 'settings.logs_ccl', value: '64' },
        { label: 'settings.tab_hdmi', value: '32' },
        { label: 'settings.logs_player', value: '16' },
        { label: 'settings.logs_gp', value: '8' },
        { label: 'settings.logs_mw', value: '4' },
      ]
    }
  }
}

export function getFlushLogsFields(): RTFFlushLogsSettings {
  return {
    flush: {
      type: RTFSettingsFieldsType.check,
      options: [
        { label: 'settings.column_value_now', value: 'now' },
        { label: 'settings.column_value_in5min', value: 'in5min' },
      ]
    }
  }
}
