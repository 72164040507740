import {
  tableDvrSelected,
  tablechannelAscRegionsSelected,
  tablechannelSelected,
  tablechannelAscNodesSelected,
  tableRegionalizationDisassociateSelected,
  tableRegionalizationAssociateSelected,
  tableAdvancedSearchSelected,
  tableProvisionDevices
} from './config';
import {
  TableAction,
  TableState,
  TABLE_ASSIGN_ALL_ROWS,
  TABLE_GET_SELECTED_ROW_INDEX,
  TABLE_GET_SELECTED_ROWS,
  TABLE_CLEAR_CACHE,
  TABLE_RESET_AFTER_DOWNLOAD,
  TABLE_EDIT_FIELD,
  TABLE_UPDATE_SORT,
  TABLE_ORDER_OWN_ITEMS
} from './types';

const initialTables = new Map();
initialTables.set('dvr', tableDvrSelected);
initialTables.set('spectrum.regions', tablechannelAscRegionsSelected);
initialTables.set('spectrum.channels', tablechannelSelected);
initialTables.set('spectrum.nodes', tablechannelAscNodesSelected);
initialTables.set(
  'spectrum.regionalization.disassociate',
  tableRegionalizationDisassociateSelected
);
initialTables.set('spectrum.regionalization.associate', tableRegionalizationAssociateSelected);
initialTables.set('advanced.search', tableAdvancedSearchSelected);
initialTables.set('account.provisioned_devices', tableProvisionDevices);

const initialState: TableState = {
  items: initialTables
};

export function getSelected(state: TableState, id: string) {
  return state.items.get(id);
}

export default function (state = initialState, { type, payload }: TableAction) {
  switch (type) {
    case TABLE_GET_SELECTED_ROWS:
      const selectedItem = getSelected(state, payload.table);
      let selectedItemsNo = 1;
      if (selectedItem) {
        if (payload.field === 'selectAll') {
          selectedItem.selectAll = !selectedItem.selectAll;
          selectedItem.hasSelectedItem = !selectedItem.hasSelectedItem;
          selectedItem.selectedRows.forEach((element: any) => {
            !selectedItem.selectAll ? (element.selected = false) : (element.selected = true);
          });
          if (selectedItem.selectAll) {
            selectedItemsNo =
              selectedItem.selectedRows.length - (selectedItem.selectedItemsNo || 0);
          } else {
            selectedItemsNo = -selectedItem.selectedRows.length;
          }
        } else {
          const selectedRow = selectedItem.selectedRows.find(
            (element: any) => element.rowId === payload.index
          );

          if (selectedRow?.selected) {
            selectedItemsNo *= -1;
          }

          if (selectedRow) {
            selectedRow.selected = !selectedRow.selected;
          }
        }
        selectedItem.selectedItemsNo = (selectedItem.selectedItemsNo || 0) + selectedItemsNo;
      }

      const hasSelectedItem =
        selectedItem && selectedItem.selectedRows.find((element: any) => element.selected);

      const hasNoSelectedItem =
        selectedItem &&
        selectedItem.selectedRows.find((element: any) => element.selected === false);

      if (selectedItem && hasSelectedItem) {
        selectedItem.hasSelectedItem = true;
      } else if (selectedItem) {
        selectedItem.hasSelectedItem = false;
      }

      if (selectedItem && hasNoSelectedItem) {
        selectedItem.selectAll = false;
      } else if (selectedItem) {
        selectedItem.selectAll = true;
      }

      return {
        ...state
      };

    case TABLE_GET_SELECTED_ROW_INDEX:
      const selectedTableItem = getSelected(state, payload.table);

      if (selectedTableItem && selectedTableItem.selectedRows) {
        selectedTableItem.selectedRow = payload.index;
      }

      return {
        ...state
      };

    case TABLE_ASSIGN_ALL_ROWS:
      const selectedTable = getSelected(state, payload.table);
      if (selectedTable) {
        Array.isArray(payload.data) &&
          payload.data.map((element: any) => {
            selectedTable.selectedRows.push({
              rowId: element[payload.id],
              field: element[payload.field] ? element[payload.field] : '',
              selected: false
            });
          });
      }

      return {
        ...state
      };

    case TABLE_CLEAR_CACHE:
      const table = state.items.get(payload.table);

      if (table) {
        table.selectedRows = [];
        table.hasSelectedItem = false;
        table.selectAll = false;
        return { ...state };
      }

      state.items.forEach((item: any) => {
        item.selectedRows = [];
        item.hasSelectedItem = false;
        item.selectAll = false;
      });

      return { ...state };

    case TABLE_RESET_AFTER_DOWNLOAD:
      state.items.forEach((item: any) => {
        item.hasSelectedItem = false;
        item.selectAll = false;
        item.selectedRows.map((element: any) => {
          element.selected = false;
        });
      });

      return { ...state };

    case TABLE_EDIT_FIELD:
      const tableEdit = getSelected(state, payload.table);
      if (tableEdit) {
        tableEdit.selectedRows.map((element: any, index: number) => {
          Object.keys(element).forEach((key) => {
            if (key === 'rowId' && element[key] == payload.id) {
              tableEdit.selectedRows[index].field = payload.data;
              return tableEdit.selectedRows[index].field;
            }
          });
        });
      }
      return {
        ...state
      };

    case TABLE_UPDATE_SORT:
      const updatedTable = getSelected(state, payload.table);

      if (updatedTable) {
        updatedTable.sort = payload.sort;
      }

      return { ...state };
    default: {
      return { ...state };
    }
  }
}
