import { getSettings } from './services'
import { RTFSettings, RTFSettingsAction, RTFSettingsActions, RTFSettingsState } from './types'

function getInitialState(): RTFSettingsState {
  return {
    assetsValuesOPCO: '',
    assetsValues: [],
    settings: <RTFSettings>{}
  }
}

export default function(state = getInitialState(), action: RTFSettingsAction) {
  switch (action.type) {
    case RTFSettingsActions.setAssetsValues:
      return { ...state, ...action.payload }
  
    case RTFSettingsActions.setSettings:
      return { ...state, settings: getSettings(state.assetsValues)}
  
    case RTFSettingsActions.clearState: 
      return { ...getInitialState() }
      
    default:
      return { ...state }
  }
}
