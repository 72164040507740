import {
  DEVICE_DETAIL_SETTING_SWITCH_TAB_FIRST_ROW,
  DEVICE_DETAIL_SETTING_SWITCH_TAB_SECOND_ROW,
  DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER,
  DEVICE_DETAIL_SETTINGS_SELECT_ACTION,
  DEVICE_DETAIL_SETTINGS_CHANGE_CLEAR,
  DEVICE_DETAIL_SETTINGS_VIP_FETCH_SUCCESS,
  DEVICE_DETAIL_SETTINGS_VIP_FETCH_REQUEST,
  DEVICE_DETAIL_SETTINGS_VIP_FETCH_FAILURE,
  DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_SUCCESS,
  DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_REQUEST,
  DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_FAILURE,
  DEVICE_DETAIL_SETTINGS_ACTION_RUN_REQUEST,
  DEVICE_DETAIL_SETTINGS_ACTION_RUN_SUCCESS,
  DEVICE_DETAIL_SETTINGS_ACTION_RUN_FAILURE,
  DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER_ACTION,
  DEVICE_DETAIL_SETTINGS_TRANSLATE,
  DeviceDetailSettingParameter,
  DeviceSettingActionParameter,
  SETTINGS_VIP_CLEAR_CACHE,
  SETTINGS_SHADOW_CLEAR_CACHE,
  DEVICE_DETAIL_SETTINGS_CLEAR_PARAMETERS,
} from './types';
import apiClient from 'core/api';
import { differenceWith } from 'ramda';
import { add as addSpinner, remove as removeSpinner } from 'core/spinner/redux/actions';
import { add, remove } from 'core/indicator/pending-tasks-redux';
import authService from 'core/auth';
import { getSettingExecutorsMap } from './utils/device-shadow-mapper';
import { addToaster } from 'core/toaster/redux/actions';
import { ApplicationState } from 'application/application-redux';
import { TFunction } from 'i18next';
import { VipDeviceDetail } from '../../general/redux/types';
import { handleCommandError } from '../../../../../../core/utils/error-utils';
import { envs } from 'application/envHandler';

export function clearCacheSettings() {
  return async function(dispatch: any, getState: () => ApplicationState) {
    dispatch(clear());
    dispatch(clearCacheVip(getState().i18n.t, {}));
    dispatch(clearCacheShadow(getState().i18n.t, {}, {}));
  };
}

export function clearCacheVip(t: TFunction, data: any) {
  return { type: SETTINGS_VIP_CLEAR_CACHE, payload: { ...data, t } };
}

export function clearCacheShadow(t: TFunction, data: any, assetsValues: any) {
  return {
    type: SETTINGS_SHADOW_CLEAR_CACHE,
    payload: {
      t,
      data,
      assetsValues
    }
  };
}

export function translateFields() {
  return async function(dispatch: any, getState: () => ApplicationState) {
    dispatch({
      type: DEVICE_DETAIL_SETTINGS_TRANSLATE,
      payload: { t: getState().i18n.t }
    });
  };
}

export function switchFirstTab(tab: String) {
  return {
    type: DEVICE_DETAIL_SETTING_SWITCH_TAB_FIRST_ROW,
    payload: tab
  };
}

export function switchSecondTab(tab: String) {
  return {
    type: DEVICE_DETAIL_SETTING_SWITCH_TAB_SECOND_ROW,
    payload: tab
  };
}

export function request() {
  return {
    type: DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_REQUEST
  };
}

export function failure(t: TFunction, data: any, assetsValues: any) {
  return {
    type: DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_FAILURE,
    payload: {
      t,
      data,
      assetsValues
    }
  };
}

export function requestPrevious() {
  return {
    type: DEVICE_DETAIL_SETTINGS_VIP_FETCH_REQUEST
  };
}

export function failurePrevious(data: any, t: TFunction) {
  return { type: DEVICE_DETAIL_SETTINGS_VIP_FETCH_FAILURE, payload: { ...data, t } };
}

export function success(t: TFunction, data: any, assetsValues: any) {
  return {
    type: DEVICE_DETAIL_SETTINGS_SHADOW_FETCH_SUCCESS,
    payload: {
      t,
      data,
      assetsValues
    }
  };
}

export function successVip(data: VipDeviceDetail, t: TFunction, shadow: any) {
  return { type: DEVICE_DETAIL_SETTINGS_VIP_FETCH_SUCCESS, payload: { ...data, t, shadow } };
}

export function handlerVipResponse(dataLake: VipDeviceDetail) {
  return async function(dispatch: any, getState: () => ApplicationState) {
    return dispatch(
      successVip(dataLake, getState().i18n.t, getState().devices.detail.settings.shadow)
    );
  };
}

export function fetch(udid: string) {
  return async function(dispatch: any, getState: () => ApplicationState) {
    dispatch(addSpinner('DEVICE_DETAIL_SETTINGS_VIP', {}));
    dispatch(request());
    await authService.refreshToken();
    return apiClient
      .get(`${envs.REACT_APP_API_URL}/device-hologram/${udid}/settings?query=custom.udid:${udid}`)
      .then((response: any) => {
        const deviceHologram = response.data.devices[0];
        if (!deviceHologram) {
          dispatch(failure(getState().i18n.t, deviceHologram, deviceHologram.assetsValues));
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_settings',
              message: 'error_messages.VIPOC_ERROR_028',
              type: 'danger'
            })
          );
        }
        dispatch(removeSpinner('DEVICE_DETAIL_SETTINGS_VIP'));
        return dispatch(fetchAssetValuesFields(deviceHologram));
      })
      .catch((error: any) => {
        console.error(error);
        dispatch(removeSpinner('DEVICE_DETAIL_SETTINGS_VIP'));
        dispatch(
          addToaster({
            title: 'all_device_pages.menu_settings',
            message: 'error_messages.VIPOC_ERROR_029',
            type: 'danger'
          })
        );
        return dispatch(fetchAssetValuesFields({}));
      });
  };
}

export function onSelectSettingsAction(value: any) {
  return async function(dispatch: any, getState: () => ApplicationState) {
    return dispatch({
      type: DEVICE_DETAIL_SETTINGS_SELECT_ACTION,
      payload: { ...value, t: getState().i18n.t }
    });
  };
}

export function onChangeParameter(
  option: DeviceDetailSettingParameter,
  value: any,
  key: string,
  targetEventOptions?: any
) {
  return {
    type: DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER,
    payload: { option, value, key, targetEventOptions }
  };
}

export function getCurrentSettingsChanges(settings: any) {
  const { hasChanged, oldParameters, parameters } = settings;
  if (hasChanged) {
    const currentParameters = Array.from(parameters.entries());
    const oldParametersArray = Array.from(oldParameters.entries());
    return differenceWith(
      (currParamEntry: any, oldParamEntry: any) => {
        const currParamKey = currParamEntry[0] as string;
        const currParam = currParamEntry[1] as DeviceDetailSettingParameter;

        const oldParamKey = oldParamEntry[0] as string;
        const oldParam = oldParamEntry[1] as DeviceDetailSettingParameter;

        return (
          (currParamKey === oldParamKey && currParam.value === oldParam.value) ||
          (currParam.type === 'multiSelect' &&
            currParamKey === oldParamKey &&
            currParam.value.length === oldParam.value.length &&
            currParam.value.toString() === oldParam.value.toString())
        );
      },
      currentParameters,
      oldParametersArray
    );
  }
  return [];
}

export function applySettings(id: string, t: TFunction, hhid?: string) {
  return async function(dispatch: Function, getState: any) {
    await authService.refreshToken();
    const executors = getSettingExecutorsMap();
    const { hasChanged, oldParameters, parameters } = getState().devices.detail.settings;
    if (hasChanged) {
      const currentParameters = Array.from(parameters.entries());
      const oldParametersArray = Array.from(oldParameters.entries());
      let paramsToApply = differenceWith(
        (currParamEntry: any, oldParamEntry: any) => {
          const currParamKey = currParamEntry[0] as string;
          const currParam = currParamEntry[1] as DeviceDetailSettingParameter;
          const oldParamKey = oldParamEntry[0] as string;
          const oldParam = oldParamEntry[1] as DeviceDetailSettingParameter;
          return (
            (currParamKey === oldParamKey && currParam.value === oldParam.value) ||
            (currParam.type === 'multiSelect' &&
              currParamKey === oldParamKey &&
              currParam.value.length === oldParam.value.length &&
              currParam.value.toString() === oldParam.value.toString())
          );
        },
        currentParameters,
        oldParametersArray
      );
      //To execute commands with two parameters
      handlerParameterGroup(
        id,
        dispatch,
        paramsToApply,
        executors,
        parameters,
        'parentalModeRating',
        'parentalModeVisibility'
      );
      handlerParameterGroup(
        id,
        dispatch,
        paramsToApply,
        executors,
        null,
        'unsubchannels',
        'cpfilter'
      );
      handlerParameterGroup(
        id,
        dispatch,
        paramsToApply,
        executors,
        null,
        'audiostate',
        'audiovolume'
      );
      paramsToApply.forEach((settingParamEntry: any) => {
        const key: string = settingParamEntry[0] as string;
        const settingParam: DeviceDetailSettingParameter = settingParamEntry[1] as DeviceDetailSettingParameter;
        if (key === 'parentalPinValue') {
          settingParam.householdId = hhid;
          if (settingParam.value.length < 4) {
            settingParam.error = t('settings.parental_mode.parental_pin.error.length');
            dispatch(onChangeParameter(settingParam, settingParam.value, key));
            return;
          }
        }
        dispatch(add('DEVICE_DETAIL_SETTINGS_APPLY', {}));
        // this if is for commands with 2 parameters
        if (
          key !== 'parentalModeRating' &&
          key !== 'parentalModeVisibility' &&
          key !== 'unsubchannels' &&
          key !== 'cpfilter' &&
          key !== 'audiostate' &&
          key !== 'audiovolume'
        ) {
          const executor = executors.get(key);
          if (executor) {
            dispatch(executor(id, settingParam));
          } else {
            console.log('executor not found for {} key', key);
          }
        }
      });
    }
    dispatch(clearPendingExecutions());
    dispatch(clearSettingsParameters());
  };
}

function handlerParameterGroup(
  id: string,
  dispatch: any,
  paramsToApply: any,
  executors: any,
  parameters: any,
  firstParameter: string,
  secondParameter: string
) {
  const paramGroup = new Map<string, DeviceDetailSettingParameter>();
  const FIRST_PARAMETER_KEY = firstParameter;
  const SECOND_PARAMETER_KEY = secondParameter;
  paramsToApply.forEach((settingParamEntry: any) => {
    const key: string = settingParamEntry[0] as string;
    const settingParam: DeviceDetailSettingParameter = settingParamEntry[1] as DeviceDetailSettingParameter;

    if (key === FIRST_PARAMETER_KEY || key === SECOND_PARAMETER_KEY) {
      paramGroup.set(key, settingParam);
    }
  });
  if (paramGroup && paramGroup.size > 0) {
    const executor = executors.get(FIRST_PARAMETER_KEY);
    let firstParameter;
    //When the first parameter is required and second is no
    if (FIRST_PARAMETER_KEY === 'parentalModeRating') {
      firstParameter = parameters.get(FIRST_PARAMETER_KEY);
    } else {
      firstParameter = paramGroup.get(FIRST_PARAMETER_KEY);
    }
    if (executor) {
      delete paramsToApply[FIRST_PARAMETER_KEY];
      delete paramsToApply[SECOND_PARAMETER_KEY];
      dispatch(executor(id, firstParameter, paramGroup.get(SECOND_PARAMETER_KEY)));
    } else {
      console.log('executor not found for {} / {} key', FIRST_PARAMETER_KEY, SECOND_PARAMETER_KEY);
    }
  }
}

export function clear() {
  return async function(dispatch: any) {
    dispatch(clearPendingExecutions());
    dispatch({
      type: DEVICE_DETAIL_SETTINGS_CHANGE_CLEAR
    });
  };
}

export function clearPendingExecutions() {
  return async function(dispatch: any, getState: () => ApplicationState) {
    dispatch(remove('DEVICE_DETAIL_SETTINGS'));
    dispatch(remove('DEVICE_DETAIL_SETTINGS_APPLY'));
  };
}

export function clearSettingsParameters() {
  return async function(dispatch: any, getState: () => ApplicationState) {
    dispatch({
      type: DEVICE_DETAIL_SETTINGS_CLEAR_PARAMETERS,
      payload: { t: getState().i18n.t }
    });
  };
}

export function runAction(id: string) {
  return async function(dispatch: any, getState: any) {
    dispatch({ type: DEVICE_DETAIL_SETTINGS_ACTION_RUN_REQUEST });
    const actions = getState().devices.detail.settings.actions;
    const { t } = getState().i18n;
    if (actions.selected) {
      const selected: DeviceSettingActionParameter = actions.selected as DeviceSettingActionParameter;
      let isValid = true;
      if (selected.parameters) {
        if (actions.selected.value === 'doPartialReset') {
          let partialResetArray = Array.from(selected.parameters.entries()).filter(
            (entry: any, index: number) => entry[1].value === true
          );
          if (partialResetArray.length === 0) {
            isValid = false;
          }
        } else if (actions.selected.value === 'updateToSpecificFirmware') {
          let updateFirmwareArray = Array.from(selected.parameters.entries()).filter(
            (entry: any, index: number) => entry[1].value
          );
          if (updateFirmwareArray.length === 0) {
            isValid = false;
          }
        } else if (actions.selected.value === 'setPowerSavingMode') {
          let setPowerSavingMode = Array.from(selected.parameters.entries()).filter(
            (entry: any, index: number) => entry[1].value
          );
          if (setPowerSavingMode.length === 0) {
            isValid = false;
          }
        } else {
          selected.parameters.forEach((entry, key) => {
            if (!entry || entry.value === undefined || entry.value.length === 0) {
              isValid = false;
            }
          });
        }
      } else {
        isValid = false;
      }
      if (isValid) {
        let jsonParameters: any = {};
        if (selected.value === 'doPartialReset' && selected.parameters) {
          Array.from(selected.parameters.entries())
            .filter((entry: any, index: number) => entry[1].value === true)
            .forEach((entry: any, index: number) => {
              jsonParameters[entry[0]] = entry[1].value;
            });
        } else if (selected.value === 'updateToSpecificFirmware' && selected.parameters) {
          Array.from(selected.parameters.entries())
            .filter((entry: any, index: number) => entry[1].value)
            .forEach((entry: any, index: number) => {
              jsonParameters[entry[0]] = entry[1].value;
            });
        } else {
          Array.from(actions.selected.parameters.entries()).forEach((entry: any, index: number) => {
            jsonParameters[entry[0]] = entry[1].value;
          });
        }
        apiClient
          .post(`${envs.REACT_APP_API_URL}/command`, {
            command: selected.value,
            deviceId: id,
            jsonParameters: jsonParameters
          })
          .then((result: any) => {
            const { jobId } = result.data.responseJson;
            if (result.data.httpCode === '400') {
              handleCommandError(dispatch, jobId, 'settings.tab_actions', t);
            } else {
              dispatch({ type: DEVICE_DETAIL_SETTINGS_ACTION_RUN_SUCCESS, payload: result });
              dispatch(
                addToaster({
                  title: 'settings.tab_actions',
                  message: t('alert_info_messages.VIPOC_INFO_013')
                    .replace('{0}', selected.value)
                    .replace('{1}', jobId),
                  type: 'success'
                })
              );
            }
          })
          .catch((rejection: any) => {
            dispatch({ type: DEVICE_DETAIL_SETTINGS_ACTION_RUN_FAILURE });
            const message: string =
              rejection === 'error_messages.permission_denied'
                ? rejection
                : 'error_messages.VIPOC_ERROR_022';
            dispatch(
              addToaster({
                title: 'settings.tab_actions',
                message,
                type: 'danger'
              })
            );
          });
      } else {
        dispatch(
          addToaster({
            title: 'settings.tab_actions',
            message: 'error_messages.VIPOC_ERROR_035',
            type: 'info'
          })
        );
      }
    }
  };
}

export function onChangeActionField(key: string, option: DeviceDetailSettingParameter, value: any) {
  return async function(dispatch: any, getState: () => ApplicationState) {
    return dispatch({
      type: DEVICE_DETAIL_SETTINGS_CHANGE_PARAMETER_ACTION,
      payload: { key, option, value, t: getState().i18n.t }
    });
  };
}

export function fetchAssetValuesFields(deviceHologram: any) {
  return async function(dispatch: any, getState: () => ApplicationState) {
    await authService.refreshToken();
    const loadedAssets: any = getState().devices.detail.settings.assetsValues;
    if ( loadedAssets ){
      return dispatch(success(
        getState().i18n.t,
        deviceHologram,
        loadedAssets
      ));
    }

    dispatch(addSpinner('DEVICE_DETAIL_SETTINGS', {}));
    return apiClient
      .get(`${envs.REACT_APP_API_URL}/assets-values`)
      .then((response: any) => {
        const assetsValues = response.data;
        if (!assetsValues) {
          dispatch(
            addToaster({
              title: 'all_device_pages.menu_settings',
              message: 'error_messages.VIPOC_ERROR_040',
              type: 'danger'
            })
          );
        }
        getState().devices.detail.settings.assetsValues = assetsValues;
        dispatch(removeSpinner('DEVICE_DETAIL_SETTINGS'));
        return dispatch(success(getState().i18n.t, deviceHologram, assetsValues));
      })
      .catch((error: any) => {
        console.error(error);
        dispatch(removeSpinner('DEVICE_DETAIL_SETTINGS'));
        dispatch(
          addToaster({
            title: 'all_device_pages.menu_settings',
            message: 'error_messages.VIPOC_ERROR_040',
            type: 'danger'
          })
        );
        return dispatch(failure(getState().i18n.t, deviceHologram, deviceHologram.assetsValues));
      });
  };
}
