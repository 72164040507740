import { envs } from 'application/envHandler'
import apiClient from 'core/api'
import { getFlushLogsFields, getLogLevelsFields, getLogProfilesFields } from '../../config/settingsFields'
import { AssetsValuesBody, AxiosAssetsValuesResponse, RTFLogLevelsSettings, RTFSettings, RTFSettingsFieldsType } from './types'

function getSelectedAssetsNames(): { [k: string]: Array<{ label: string, value: string }> } {
  return {
    lumberjack_window: [],
    lumberjack_ui: []
  }
}

export async function fetchAssetsValues() {
  return apiClient.get('/assets-values', {
    baseURL: envs.REACT_APP_API_URL,
  }) as Promise<AxiosAssetsValuesResponse>
}

export function filterAssetsValues(assets: AssetsValuesBody) {
  return assets.filter(asset => ['lumberjack_window', 'lumberjack_ui'].includes(asset.assetName))
}

export function sortAssetsValues (assets: AssetsValuesBody): RTFLogLevelsSettings {
  const selectedAssetsNames = getSelectedAssetsNames()
  const logLevelsFields = getLogLevelsFields()

  assets.forEach(asset => {
    const { assetName, label, value } = asset
    if(selectedAssetsNames[assetName]) {
      selectedAssetsNames[assetName].push({ label: label, value: value })
    }
  })

  return {
    levels: logLevelsFields.levels,
    ui: {
      type: RTFSettingsFieldsType.select,
      options: selectedAssetsNames.lumberjack_ui
    },
    window: {
      type: RTFSettingsFieldsType.select,
      options: selectedAssetsNames.lumberjack_window
    },
  }
}

export function getSettings(assetsValues: AssetsValuesBody): RTFSettings {
  const sortedAssetsValues = sortAssetsValues(assetsValues)

  return {
    logProfilesFields: getLogProfilesFields(),
    flushLogsFields: getFlushLogsFields(),
    logLevelsFields: sortedAssetsValues,
  }
}
