import { CloseModalFunction, OpenModalFunction } from 'core/modal/redux/types'

export const RTF_ASSETS_VALUES_FETCH = 'RTF_ASSETS_VALUES_FETCH'

export enum RTFSettingsActions {
  setSettings = 'setSettings',
  setAssetsValues = 'setAssetsValues',
  clearState = 'clearState',
}

export type AssetsValues = {
  assetName: string
  opCo: string
  label: string
  value: string
}

export type AssetsValuesBody = Array<AssetsValues>

export type AxiosAssetsValuesResponse = {
  data: AssetsValuesBody
}

enum RTFLogsProfileFields {
  osProfileLevel = 'osProfileLevel',
  appProfileLevel = 'appProfileLevel',
  window = 'window',
}

enum RTFLogsLevelFields {
  levels = 'levels',
  ui = 'ui',
  window = 'window',
}

enum RTFFlushLogsFields {
  flush = 'flush',
}

export enum RTFSettingsFieldsType {
  select = 'select',
  check = 'check',
}

export type RTFSettingsOption = {
  label: string
  value: string
}

export type SettingsField = {
  type: RTFSettingsFieldsType
  options: Array<RTFSettingsOption>
}

export type RTFLogProfilesSettings = {
  [k in RTFLogsProfileFields]: SettingsField
}

export type RTFLogLevelsSettings = {
  [k in RTFLogsLevelFields]: SettingsField
}

export type RTFFlushLogsSettings = {
  [k in RTFFlushLogsFields]: SettingsField
}

export type RTFSettings = {
  logProfilesFields: RTFLogProfilesSettings
  logLevelsFields: RTFLogLevelsSettings
  flushLogsFields: RTFFlushLogsSettings
}

export type RTFSettingsState = {
  assetsValuesOPCO: string
  assetsValues: Array<AssetsValues>
  settings: RTFSettings
}

export type GetAssetsValuesSuccessPayload = {
  assetsValuesOPCO: string
  assetsValues: Array<AssetsValues>
}

export type RTFSettingsAction = {
  type: RTFSettingsActions.setAssetsValues
  payload: GetAssetsValuesSuccessPayload
} | {
  type: RTFSettingsActions.setSettings
} | {
  type: RTFSettingsActions.clearState
}

export type GetAssetsValuesAndSetSettingsFunction = () => Promise<void>

export type RunCommandsFunction = (deviceId: string, command: CommandProps, toasterTitle?: string) => void

export type CommandProps = {
  command: string
  key: string
  jsonParameters: JSONParameters
}

export type JSONParameters = {
  window?: number
  appProfileLevel?: string
  osProfileLevel?: string
  mode?: string
}

export type ExecuteCommandType = (
  connected: boolean,
  commandName: string,
  commandCallback: Function,
  resetStates?: ResetStatesFunction,
) => void

export type ShowModalAndExecuteCommandFunction = (
  deviceId: string,
  openModal: OpenModalFunction,
  closeModal: CloseModalFunction,
  runCommands: RunCommandsFunction,
  commandConfig: CommandProps,
  connected: boolean,
) => void

export type ResetStatesFunction = () => void
