import { TFunction } from 'i18next'
import React from 'react'
import { RTFDeviceData } from '../../redux/devices/types'
import Card from 'core/card/card-component'
import AuthService from 'core/auth'
import { envs } from 'application/envHandler'

type HeaderProps = RTFDeviceData & {
  t: TFunction
}

function showKDSN() {
  const currentOpco = AuthService.getCurrentOpco()
  return currentOpco === 'vfde' || currentOpco === 'vfuk'
}

function KDSNField(kdsn: string, notApplicableLabel: string, notAvailable: string) {
  const isNotApplicable = kdsn && kdsn === 'N/A'

  return (<p className={`is-inline-block topbar-item ${isNotApplicable ? 'div-tooltip-topbar has-tooltip-topbar top-tooltip' : ''}`} data-info={`${notApplicableLabel}`}>
    <a className="title is-6">KDSN </a>
    {kdsn ?? notAvailable}
  </p>)
}

export default function Header({ udid, HHID, userID, externalHHID, kdsn, t }: HeaderProps) {
  return (
    <div className="has-margin-top-md">
      <Card>
        <p className="is-inline-block topbar-item">
          <a className="title is-6">{t('sidebar.device_id')} </a>
          {udid}
        </p>

        <p className="is-inline-block topbar-item">
          <a className="title is-6">Household Id </a>
          { HHID || t('general.not_available')}
        </p>

        {showKDSN() 
          ? KDSNField(kdsn, t('general.not_applicable'), t('general.not_available'))
          : ''
        }

        <p className="is-inline-block topbar-item">
          <a className="title is-6">{`${t('general.user.id')} `}</a>
          {userID || t('general.not_available')}
        </p>

        <p className="is-inline-block topbar-item">
          <a className="title is-6">{`${t('general.user.external_id')} `}</a>
          {externalHHID || t('general.not_available')}
        </p>
      </Card>
    </div>
  )
}
