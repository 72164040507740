import { ChevronDown } from 'assets/svg'
import { t } from 'i18next'
import React from 'react'

type PerPageProps = {
  pageSize: number
  options?: Array<number>
  setPageSize: React.Dispatch<React.SetStateAction<number>>
}

export default function PerPage({ pageSize, options = [5, 10, 20], setPageSize }: PerPageProps) {
  return (
    <div className='search-records-per-page'>
      <span>{t('general.records_per_page')}</span>
      <div className="current-pagesize">
        <span>{pageSize}</span>
        <ChevronDown className="icon-svg icon-tiny" />
        <div className="dropdown-options">
          {options.map((itemsNumber) => (
            <button
              key={itemsNumber}
              className={
                itemsNumber === pageSize
                  ? 'button-link dropdown-item active-item'
                  : 'button-link dropdown-item'
              }
              onClick={() => setPageSize(itemsNumber)}
            >
              {itemsNumber}
            </button>
          ))}
        </div>
      </div>
    </div>
  )
}
