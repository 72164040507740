export const SETTINGS_FETCH_ASSET_VALUES = 'SETTINGS_FETCH_ASSET_VALUES';
export const SETTINGS_ASSETS_VALUES = 'SETTINGS_ASSETS_VALUES';
export const SETTINGS_SET_FIELDS = 'SETTINGS_SET_FIELDS';
export const SETTINGS_CLEAR_CACHE = 'SETTINGS_CLEAR_CACHE';
export const SETTINGS_FETCH_ASSET_VALUES_FAILURE = 'SETTINGS_FETCH_ASSET_VALUES_FAILURE';
export const SETTINGS_UPDATE_FIELDS = 'SETTINGS_UPDATE_FIELDS';
export const SETTINGS_COMMAND_EXECUTION_REQUEST = 'SETTINGS_COMMAND_EXECUTION_REQUEST';
export const SETTINGS_COMMAND_EXECUTION = 'SETTINGS_COMMAND_EXECUTION';
export const SETTINGS_COMMAND_EXECUTION_FAILURE = 'SETTINGS_COMMAND_EXECUTION_FAILURE';
export const SETTINGS_CLEAR_FIELDS = 'SETTINGS_CLEAR_FIELDS';
export const SETTINGS_PARENTAL_PIN_ERROR = 'SETTINGS_PARENTAL_PIN_ERROR';
export const SETTINGS_ACTIONS_TOGGLE_ACCORDION = 'SETTINGS_ACTIONS_TOGGLE_ACCORDION';
export const SETTINGS_ACTIONS_TOGGLE_OPTIONS = 'SETTINGS_ACTIONS_TOGGLE_OPTIONS';
export const SETTINGS_ACTIONS_RESET = 'SETTINGS_ACTIONS_RESET';
export interface SettingsAction {
  type: string;
  payload?: any;
}

export interface SettingsState {
  assetsValues?: AssetValues;
  configurations?: Configurations[];
  hasChanged?: boolean;
  actions?: Actions;
}

export interface Configurations {
  component: string;
  fields: SettingsField[];
  hasChanged: boolean;
}

export interface AssetValues {
  lumberjack_window: AssetValue[];
  setUILanguage: AssetValue[];
  setPreferredAudioLanguage: AssetValue[];
  lumberjack_ui: AssetValue[];
  setParentalMode: AssetValue[];
  hideSetParentalMode: AssetValue[];
  setActiveStandbyTimer: AssetValue[];
  setLocalPaddingTime: AssetValue[];
  changeChannelVisibility: AssetValue[];
  setPreferredSubtitleLanguage: AssetValue[];
  audioState: AssetValue[];
  subtitles: AssetValue[];
  hdmiAudioMode: AssetValue[];
  unsubscribedChannels: AssetValue[];
  parentalModeVisibility: AssetValue[];
  visualImpAudio: AssetValue[];
}

export interface AssetValue {
  label: string;
  value?: any;
  selected?: boolean;
  active?: boolean;
}

export interface SettingsField {
  name: string;
  label: string;
  type: string;
  currentValue: any;
  value: any;
  available?: AssetValue[];
  previousValue?: string;
  disabled?: boolean;
  error?: string;
  selected?: boolean;
}

export interface CommandParameter {
  command: string;
  key: string;
  jsonParameters: any;
}
export interface Actions {
  fields: SettingsField[];
  hasChanged: boolean;
}

export interface Asset {
  assetName: string;
  label: string;
  opCo: string;
  value: any;
}
