import { add, remove } from 'core/spinner/redux/actions'
import { addToaster } from 'core/toaster/redux/actions'
import { Dispatch } from 'redux'
import { fetchHistory, generateDownloadURL } from './services'
import { DownloadURLResponse, HistoryFilter, LogsHistory, LogsHistoryActions, LogsHistoryResponse, RTF_HISTORY_FETCH, RTF_HISTORY_LINK_GENERATION } from './types'

function getHistorySuccess(payload: LogsHistoryResponse) {
  return {
    type: LogsHistoryActions.setList,
    payload,
  }
}

function clearList() {
  return {
    type: LogsHistoryActions.clearList,
  }
}

function setURL(payload: DownloadURLResponse) {
  return {
    type: LogsHistoryActions.setURL,
    payload,
  }
}

function clearURL() {
  return {
    type: LogsHistoryActions.clearURL,
  }
}

export function getHistory(id: string, filter: HistoryFilter) {
  return async function (dispatch: Dispatch) {
    try {
      dispatch(add(RTF_HISTORY_FETCH, {}))
      const { data } =  await fetchHistory(id, filter)
      dispatch(getHistorySuccess(data))
    } catch (error) {
      dispatch(addToaster({
        title: 'rtf_module.history_fetch',
        message: 'error_messages.VIPOC_ERROR_055',
        type: 'danger',
      }))
      dispatch(clearList())
    } finally {
      dispatch(remove(RTF_HISTORY_FETCH))
    }
  }
}

export function clearHistory() {
  return function (dispatch: Dispatch) {
    dispatch(clearList())
  }
}

export function getDownloadURL(id: string, selectedEntries: Array<LogsHistory>) {
  return async function (dispatch: Dispatch) {
    try {
      dispatch(add(RTF_HISTORY_LINK_GENERATION, {}));
      const { data } = await generateDownloadURL(id, selectedEntries.map(entry => entry.link))
      dispatch(setURL(data))
    } catch (error: any) {
      dispatch(clearURL())
      dispatch(
        addToaster({
          title: 'all_device_pages.menu_logs',
          message: error?.status === 400 ? 'error_messages.VIPOC_ERROR_058' : 'error_messages.VIPOC_ERROR_009',
          type: 'danger',
        })
      )
    } finally {
      dispatch(remove(RTF_HISTORY_LINK_GENERATION))
    }
  }
}

export function clearDownloadURL() {
  return function (dispatch: Dispatch) {
    dispatch(clearURL())
  }
}