import { OpenInNew } from 'assets/svg';
import { TFunction } from 'i18next';
import React, { Component, Fragment } from 'react';
import { SideMenuState } from './redux/types';
import './side-menu-styles.scss';
import { Link } from 'react-router-dom'
import qs from 'qs'

interface SideMenuComponentProps {
  id: string;
  sideMenu: SideMenuState;
  toggleCategory: (id: string, category: string) => void;
  toggleSubCategory: (id: string, category: string, subCategory: string) => void;
  t: TFunction;
  deviceId: string
}

export default class SideMenuComponent extends Component<SideMenuComponentProps> {
  render() {
    const { sideMenu, id, toggleCategory, toggleSubCategory, t, deviceId } = this.props;
    const menu = sideMenu.sideMenu.get(id);

    return (
      <div className="side-menu-container">
        <div className="columns flex-wrap">
          {menu &&
            menu.categories.map((category, key) => (
              !category.linkTo ?
                <Fragment key={key}>
                  <div
                    className={`column side-menu-category is-12 ${
                      category.isActive && 'side-menu-category-active'
                    }`}
                    onClick={() => toggleCategory(id, category.name)}
                  >
                    <span className="has-padding-left-sm">{t(`settings.tab_${category.name}`)}</span>
                  </div>

                  {category.subCategories && category.isActive && (
                    <div>
                      {category.subCategories.map((subCategory: any, key) => (
                        <div
                          key={key}
                          className={`column is-12 is-relative side-menu-subcategory ${
                            subCategory.isActive && 'side-menu-subcategory-active'
                          }`}
                          onClick={() => toggleSubCategory(id, category.name, subCategory.name)}
                        >
                          <span className="has-padding-left-md">
                            {t(`settings.tab_${subCategory.name}`)}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </Fragment> :
                <Link to={{ 
                  pathname: category.linkTo,
                  search: qs.stringify({ deviceId })
                 }} key={key} className="column side-menu-category is-12">
                  <span className="has-padding-left-sm">{t(`settings.tab_${category.name}`)}</span>
                  <OpenInNew />
                </Link>
            ))}
        </div>
      </div>
    );
  }
}
