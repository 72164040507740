import { combineReducers } from 'redux'
import devicesReducer from './devices'
import logsReducer from './logs'
import settingsReducer from './settings'

export default combineReducers({
  rtfDevices: devicesReducer,
  rtfLogs: logsReducer,
  rtfSettings: settingsReducer,
})
