import React from 'react'
import { t } from 'i18next'
import { TableProps } from '../types'
import SortIcon from '../components/SortIcon'

type WithCheckBoxProps<T, K> = TableProps<T, K> & {
  configMap: Array<string>
}

export default function Simple<T, K>({ header, items, configMap, onSortCallback, sort }: WithCheckBoxProps<T, K>) {
  return (
    <div className="simple-table-with-checkbox">
      <div className="simple-table-header">
        <div className="columns">
          {header.map((config) => <div className='column' key={config.key}>
            {t(config.label)}
            {onSortCallback ? SortIcon({ field: config, sort, onSortCallback }) : ''}
          </div>)}
        </div>
      </div>
      <div>
        <div className="simple-table-body">
          {items.map((item: any, rowIndex) => <div 
          className={`columns ${rowIndex % 2 !== 0 && 'stripped'} simple-table-with-checkbox-row`}
          key={rowIndex}>
            {configMap.map((key) => <div className='column' key={item[key]}>
              {item[key]}
            </div>)}
          </div>)}
        </div>
      </div>
    </div>
  )
}
