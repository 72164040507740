import React from 'react'
import Icon from '@mdi/react'
import { mdiCircle } from '@mdi/js'
import { iconSize } from '../../constants'
import { t } from 'i18next'
import { formatDate, getCASLicenseServerStatusColor, getClientDataBrokerCheckColor, getDate, getDeviceAuthenticationTokenStatusColor, getVIPConnectivityCheckStatusColor } from '../../utils'

type StatusSectionProps = {
  STBMD: string
  VIPConnectivityCheck: number
  lastTechnicalDataEvent: string
  clientDataBrokerCheck: number
  authenticationTokenDate: string
  dvsd: string
}

export default function StatusSection({ VIPConnectivityCheck, STBMD, clientDataBrokerCheck, lastTechnicalDataEvent, authenticationTokenDate, dvsd }: StatusSectionProps) {
  const currentTime = Date.now()

  return (
    <div className="section">
      <h3 className="section-title">{t('device_checks.labels_status')}</h3>
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_vip_connectivity_check')}:</div>
        <div className="column is-6">
          <div className="status-column">
            <Icon path={mdiCircle} size={iconSize} color={getVIPConnectivityCheckStatusColor(VIPConnectivityCheck, STBMD, currentTime)}/>
            <span>{getDate(VIPConnectivityCheck)}</span>
          </div>
        </div>
      </div>
      <div className="divider" />
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_client_data_broker_check')}:</div>
        <div className="column is-6">
          <div className="status-column">
            <Icon path={mdiCircle} size={iconSize} color={getClientDataBrokerCheckColor(clientDataBrokerCheck * 1000, currentTime)}/>
            <span>{getDate(clientDataBrokerCheck)}</span>
          </div>
        </div>
      </div>
      <div className="divider" />
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_device_authentication_token')}:</div>
        <div className="column is-6">
          <div className="status-column">
            <Icon path={mdiCircle} size={iconSize} color={getDeviceAuthenticationTokenStatusColor(authenticationTokenDate, currentTime)}/>
            <span>{getDate(new Date(authenticationTokenDate).getTime())}</span>
          </div>
        </div>
      </div>
      <div className="divider" />
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_last_technical_data_event')}:</div>
        <div className="column is-6">{formatDate(lastTechnicalDataEvent)}</div>
      </div>
      <div className="divider" />
      <div className="columns">
        <div className="column is-6">{t('device_checks.labels_cas_license_server')}:</div>
        <div className="column is-6">
          <div className="status-column">
            <Icon path={mdiCircle} size={iconSize} color={getCASLicenseServerStatusColor(dvsd, currentTime)}/>
            <span>{formatDate(dvsd)}</span>
          </div>
        </div>
      </div>
      <div className="divider" />
    </div>
  )
}
