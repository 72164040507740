import { EMPTY_RTF_DEVICE, RTFDevicesActions, RTFDevicesPayload, RTFDevicesState } from './types'

const initialState: RTFDevicesState =  {
  current: EMPTY_RTF_DEVICE,
}

export default function (state = initialState, action: RTFDevicesPayload): RTFDevicesState {
  switch (action.type) {
    case RTFDevicesActions.setCurrent:
      return { ...state, current: { ...action.payload } }

    case RTFDevicesActions.clearCurrent:
        return { ...state, current: EMPTY_RTF_DEVICE }

    default:
      return state
  }
}
