import DateUtils from 'core/utils/date-utils'
import { t } from 'i18next'
import { dangerColor, defaultColor, eighteenHoursInMs, oneHourInMs, successColor, thirtySixHoursInMs, twentyMinutesInMs, twentyTwoHoursInMs, warningcolor } from './constants'

export function getDate(date: number | string) {
  if(!date) return t('general.not_available')

  if(typeof date === 'number') {
    date = date.toString()
  }

  return DateUtils.formatDate(Number(date.padEnd(13, '0')))
}

export function formatDate(date: string | number) {
  const dateLabel = DateUtils.formatDate(date)

  if(!date || dateLabel === 'Invalid date') {
    return t('general.not_available')
  }

  return dateLabel
}

export function getVIPConnectivityCheckStatusColor(VIPConnectivityCheck: number, STBMD: string, currentTime: number) {
  if(VIPConnectivityCheck) {
    const valuesToGreenStatus = ['Streaming', 'On UI', 'Active']
    if(valuesToGreenStatus.includes(STBMD)) {
      return successColor
    }

    const valuesToOrangeStatus = ['Active-Standby', 'Deep-standby', 'Standby']
    if(valuesToOrangeStatus.includes(STBMD)) {
      return warningcolor
    }

    if(currentTime - VIPConnectivityCheck > oneHourInMs) {
      return dangerColor
    }
  }

  return defaultColor
}

export function getClientDataBrokerCheckColor(clientDataBrokerCheck: number, currentTime: number) {
  if(!clientDataBrokerCheck) {
    return defaultColor
  }

  if(currentTime - clientDataBrokerCheck > twentyMinutesInMs) {
    return dangerColor
  }

  return successColor
}

export function getDeviceAuthenticationTokenStatusColor(authenticationTokenDate: string, currentTime: number) {
  if(!authenticationTokenDate) {
    return defaultColor
  }

  const authenticationTokenTime = new Date(authenticationTokenDate).getTime()
  const timeDiff = currentTime - authenticationTokenTime

  if(timeDiff <= eighteenHoursInMs) {
    return successColor
  }

  if(timeDiff > eighteenHoursInMs && timeDiff <= twentyTwoHoursInMs) {
    return warningcolor
  }

  return dangerColor
}

export function getCASLicenseServerStatusColor(dvsd: string, currentTime: number) {
  if(!dvsd) return defaultColor

  const dvsdTime = new Date(dvsd).getTime()
  if(!dvsdTime) return defaultColor

  const timeDifference = currentTime - dvsdTime

  if (timeDifference <= twentyTwoHoursInMs) {
    return successColor
  }

  if (timeDifference <= thirtySixHoursInMs) {
    return warningcolor
  }

  return dangerColor
}
