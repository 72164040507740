import { Sort, SortAsc, SortDesc } from 'assets/svg'

export const SORT_ICONS = {
  default: Sort,
  ASC: SortAsc,
  DESC: SortDesc,
}

export function sortLocalList<T, K extends keyof T>(list: Array<T>, key: K, order: 'ASC' | 'DESC' = 'ASC') {
  const sortedAscList = [...list].sort(function (firstElement, secondElement) {
    if (firstElement[key] < secondElement[key]) {
      return -1
    }

    if (firstElement[key] > secondElement[key]) {
      return 1
    }

    return 0
  })
  
  return order === 'ASC' ? sortedAscList : sortedAscList.reverse()
}

export function getCheckedEntriesOfCurrentPage(checkedRows: Array<number>, page: number, perPage: number) {
  return [...checkedRows].filter(checked => {
    const greaterThanOrEqualCondition = (page - 1) * perPage
    const lessThanCondition = page * perPage

    return checked >= greaterThanOrEqualCondition && checked < lessThanCondition
  }).map(row => {
    return row - ((page - 1) * perPage)
  })
}

export function addOffsetToEntry(selectedRow: number, page: number, perPage: number) {
  return selectedRow + ((page - 1) * perPage) 
}
