export const ACTIVITY_LOG_SCAN = 'ACTIVITY_LOG_SCAN';
export const ACTIVITY_LOG_FAILURE = 'ACTIVITY_LOG_FAILURE';
export const ACTIVITY_LOG_SUCCESS = 'ACTIVITY_LOG_SUCCESS';
export const ACTIVITY_LOG_INFO = 'ACTIVITY_LOG_INFO';
export const ACTIVITY_LOG_CANCEL = 'ACTIVITY_LOG_CANCEL';
export const ACTIVITY_LOG_CHANGE_PAGE = 'ACTIVITY_LOG_CHANGE_PAGE';
export const ACTIVITY_LOG_CLEAR_CACHE = 'ACTIVITY_LOG_CLEAR_CACHE';
export const ACTIVITY_LOG_SET_COMMANDS_LIST = 'ACTIVITY_LOG_SET_COMMANDS_LIST';

export type ACTIVITY_LOG_SCAN = typeof ACTIVITY_LOG_SCAN;
export type ACTIVITY_LOG_FAILURE = typeof ACTIVITY_LOG_FAILURE;
export type ACTIVITY_LOG_SUCCESS = typeof ACTIVITY_LOG_SUCCESS;
export type ACTIVITY_LOG_INFO = typeof ACTIVITY_LOG_INFO;
export type ACTIVITY_LOG_CANCEL = typeof ACTIVITY_LOG_CANCEL;
export type ACTIVITY_LOG_CHANGE_PAGE = typeof ACTIVITY_LOG_CHANGE_PAGE;
export type ACTIVITY_LOG_CLEAR_CACHE = typeof ACTIVITY_LOG_CLEAR_CACHE;
export type ACTIVITY_LOG_SET_COMMANDS_LIST = typeof ACTIVITY_LOG_SET_COMMANDS_LIST;

export interface ActivityLogAction {
  type:
    | ACTIVITY_LOG_SCAN
    | ACTIVITY_LOG_FAILURE
    | ACTIVITY_LOG_SUCCESS
    | ACTIVITY_LOG_CANCEL
    | ACTIVITY_LOG_CHANGE_PAGE
    | ACTIVITY_LOG_CLEAR_CACHE
    | ACTIVITY_LOG_SET_COMMANDS_LIST;
  payload?: any;
}

export interface ActivityLogResultItem {
  id: string;
  eventName: string;
  eventType: string;
  lastManaged: string;
  status: string;
  commandTraceId: string;
  logResponse: string;
  logRequest: string;
}

export interface ActivityLogResult {
  totalElements: number;
  items: Array<ActivityLogResultItem>;
  itemsToshow: Array<ActivityLogResultItem>;
}

export interface ActivityLogState {
  results?: ActivityLogResult;
  commandList?: [];
}