import { SideMenu } from './types';

const categories = {
  categories: [
    {
      name: 'configurations',
      isActive: true,
      subCategories: [
        {
          name: 'audio_subtitle',
          isActive: true
        },
        {
          name: 'hdmi',
          isActive: false
        },
        {
          name: 'advanced',
          isActive: false
        },
        {
          name: 'parental_mode',
          isActive: false
        },
        {
          name: 'accessibility',
          isActive: false
        },
        {
          name: 'startup_standby',
          isActive: false
        },
        {
          name: 'update',
          isActive: false
        }
      ]
    },
    {
      name: 'actions',
      isActive: false
    },
    {
      name: 'logs',
      isActive: false,
      linkTo: '/operational/rtf'
    }
  ]
};

export const settingsSideMenu = () => {
  return categories as SideMenu
}
