import React, { Component, Fragment } from 'react';
import { TFunction } from 'i18next';
import Topbar from '../topbar/topbar-container';
import SideMenu from 'core/side-menu/side-menu-container';
import './settings-styles.scss';
import ConfigurationsComponent from './configurations/configurations-container';
import { GeneralDeviceState } from '../general/redux/types';
import { SettingsState } from './redux/types';
import { SideMenuState } from 'core/side-menu/redux/types';
import ActionsComponent from './actions/actions-container';
interface SettingsComponentProps {
  t: TFunction;
  id: string;
  hhid: string;
  general: GeneralDeviceState;
  settings: SettingsState;
  sideMenu: SideMenuState;
  fetchAssetValues: () => void;
  setFields: (allFieldsData: any) => void;
  sideMenuClearCache: () => void;
  calls: (id: string, fetchAll?: boolean, settingsCall?: boolean) => void;
}

export default class SettingsComponent extends Component<SettingsComponentProps> {
  componentDidMount() {
    const { id, sideMenuClearCache, calls, general } = this.props;

    if (general && general.shadow === undefined) {
      calls(id, true, true);
    } else {
      calls(id, true, false);
    }
    sideMenuClearCache();
  }

  getComponentFromMenu = (categories: any) => {
    const category = categories.find((category: any) => category.isActive);
    if (category.subCategories && category.subCategories?.length !== 0) {
      const subCategory = category.subCategories.find((subCategory: any) => subCategory.isActive);
      return subCategory.name;
    } else {
      return category.name;
    }
  };

  render() {
    const { id, sideMenu, hhid } = this.props;
    const menu = sideMenu.sideMenu.get('settings');

    return (
      <Fragment>
        <div className="columns width-full">
          <div className="column">
            <Topbar id={id}></Topbar>
          </div>
        </div>

        <div className="tile has-padding-left-none">
          <div className="tile is-vertical sidemenu-container has-padding-right-sm">
            <SideMenu id={'settings'} deviceId={id}/>
          </div>

          <div className="tile is-vertical has-padding-left-md">
            <div className="is-relative height-full">
              {menu && this.getComponentFromMenu(menu.categories) === 'audio_subtitle' && (
                <ConfigurationsComponent id={id} componentId={'audio_subtitle'} />
              )}

              {menu && this.getComponentFromMenu(menu.categories) === 'hdmi' && (
                <ConfigurationsComponent id={id} componentId={'hdmi'} />
              )}

              {menu && this.getComponentFromMenu(menu.categories) === 'advanced' && (
                <ConfigurationsComponent id={id} componentId={'advanced'} />
              )}

              {menu && this.getComponentFromMenu(menu.categories) === 'parental_mode' && (
                <ConfigurationsComponent id={id} componentId={'parental_mode'} hhid={hhid} />
              )}

              {menu && this.getComponentFromMenu(menu.categories) === 'accessibility' && (
                <ConfigurationsComponent id={id} componentId={'accessibility'} />
              )}

              {menu && this.getComponentFromMenu(menu.categories) === 'startup_standby' && (
                <ConfigurationsComponent id={id} componentId={'startup_standby'} />
              )}

              {menu && this.getComponentFromMenu(menu.categories) === 'update' && (
                <ConfigurationsComponent id={id} componentId={'update'} />
              )}

              {menu && this.getComponentFromMenu(menu.categories) === 'actions' && (
                <ActionsComponent id={id} />
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
