import React, { useState } from 'react'
import SearchComponent from './components/Search'
import CardComponent from 'core/card'
import { TFunction } from 'i18next'
import { OnGetDeviceCallback } from '../RtfComponent'

type DeviceProps = {
  onGetDevice: OnGetDeviceCallback
  t: TFunction
  deviceIdQuery: string
}

export default function DeviceComponent({ onGetDevice, t, deviceIdQuery = '' }: DeviceProps) {
  const [udid, setUdid] = useState(deviceIdQuery)

  return (
    <CardComponent>
      <SearchComponent udid={udid} setUdid={setUdid} onGetDevice={onGetDevice} t={t}/>
    </CardComponent>
  )
}
