import React from 'react'
import FloatingLabel from './components/FloatingLabel'
import { InputFieldTypes, InputProps } from './types'
import Datepicker from './variations/Datepicker'
import SelectInput from './variations/Select'
import TextInput from './variations/Text'
import './index.scss'

export default function Input(props: InputProps) {

  switch (props.type) {
    case InputFieldTypes.text:
      return (
        <div className="generic-input">
          <TextInput {...props}>
            {props.labelParams && <FloatingLabel {...props.labelParams} value={props.value}/>}
          </TextInput>
        </div>
      )

    case InputFieldTypes.select:
      return (
        <div className="generic-input">
          <SelectInput {...props}> 
            {props.labelParams && <FloatingLabel {...props.labelParams} value={props.value}/>}
          </SelectInput>
        </div>
      )

    case InputFieldTypes.datepicker:
      return (
        <div className="generic-input">
          <Datepicker {...props}>
            {props.labelParams && <FloatingLabel {...props.labelParams} value={props.value}/>}
          </Datepicker>
        </div>
      )
  }
}
