import { ApplicationState } from 'application/application-redux';
import apiClient from 'core/api';
import AuthService from 'core/auth';
import { addToaster } from 'core/toaster/redux/actions';
import { add as addSpinner, remove as removeSpinner } from 'core/spinner/redux/actions';
import {
  CommandParameter,
  SETTINGS_CLEAR_CACHE,
  SETTINGS_CLEAR_FIELDS,
  SETTINGS_COMMAND_EXECUTION_REQUEST,
  SETTINGS_COMMAND_EXECUTION,
  SETTINGS_FETCH_ASSET_VALUES,
  SETTINGS_FETCH_ASSET_VALUES_FAILURE,
  SETTINGS_SET_FIELDS,
  SETTINGS_UPDATE_FIELDS,
  SETTINGS_ASSETS_VALUES,
  SETTINGS_PARENTAL_PIN_ERROR,
  SETTINGS_ACTIONS_TOGGLE_ACCORDION,
  SETTINGS_ACTIONS_TOGGLE_OPTIONS,
  SETTINGS_ACTIONS_RESET,
} from './types';
import { handleCommandError } from 'core/utils/error-utils';
import { envs } from 'application/envHandler';

/**************** FIELDS - SET/UPDATE/RESET ****************/

export function setFields(allFieldsData: any) {
  return {
    type: SETTINGS_SET_FIELDS,
    payload: {
      allFieldsData
    }
  };
}

export function updateFields(
  componentId: string,
  field: { name: string; newValue: any; assets?: string }
) {
  return {
    type: SETTINGS_UPDATE_FIELDS,
    payload: {
      componentId,
      field
    }
  };
}

export function clearSettingsCache() {
  return {
    type: SETTINGS_CLEAR_CACHE
  };
}

export function clearFields(componentId?: string) {
  return {
    type: SETTINGS_CLEAR_FIELDS,
    payload: {
      componentId
    }
  };
}

export function toggleAccordion(index: number) {
  return {
    type: SETTINGS_ACTIONS_TOGGLE_ACCORDION,
    payload: { index }
  };
}

export function toggleAccordionOptions(option: string) {
  return {
    type: SETTINGS_ACTIONS_TOGGLE_OPTIONS,
    payload: { option }
  };
}

export function resetActions() {
  return {
    type: SETTINGS_ACTIONS_RESET
  };
}

/**************** ASSETS VALUES ****************/

function successFetchAssetValues(data: any) {
  return {
    type: SETTINGS_FETCH_ASSET_VALUES,
    payload: {
      data
    }
  };
}

function failureFetchAssetValues() {
  return {
    type: SETTINGS_FETCH_ASSET_VALUES_FAILURE
  };
}

export function fetchAssetValuesSync(dispatch: Function) {
  dispatch(addSpinner(SETTINGS_ASSETS_VALUES, {}));
  return apiClient.get(`${envs.REACT_APP_API_URL}/assets-values`).then(
    (response) => {
      if (!response.data) {
        dispatch(
          addToaster({
            title: 'all_device_pages.menu_settings',
            message: 'error_messages.VIPOC_ERROR_040',
            type: 'danger'
          })
        );
      }
      dispatch(removeSpinner(SETTINGS_ASSETS_VALUES));
      return dispatch(successFetchAssetValues(response.data));
    },
    (rejection) => {
      dispatch(removeSpinner(SETTINGS_ASSETS_VALUES));
      dispatch(
        addToaster({
          title: 'all_device_pages.menu_settings',
          message: 'error_messages.VIPOC_ERROR_040',
          type: 'danger'
        })
      );
      return dispatch(failureFetchAssetValues());
    }
  );
}

export function fetchAssetValues() {
  return async (dispatch: Function) => {
    await AuthService.refreshToken();
    dispatch(fetchAssetValuesSync(dispatch));
  };
}

/**************** COMMANDS **************/

function runCommandSuccess(field: any) {
  return {
    type: SETTINGS_COMMAND_EXECUTION,
    payload: {
      field
    }
  };
}

export function runCommands(id: string, command: CommandParameter, toasterTitle?: string) {
  return async (dispatch: any, getState: () => ApplicationState) => {
    dispatch({
      type: SETTINGS_COMMAND_EXECUTION_REQUEST,
      payload: command.key
    });
    const { t } = getState().i18n;
    apiClient
      .post(`${envs.REACT_APP_API_URL}/command`, {
        command: command.command,
        deviceId: id,
        jsonParameters: command.jsonParameters
      })
      .then((result: any) => {
        const { jobId } = result.data.responseJson;
        if (result.data.httpCode === '400') {
          handleCommandError(dispatch, jobId, toasterTitle || 'all_device_pages.menu_settings', t);
        } else {
          dispatch(runCommandSuccess(command.key));
          if (
            command.key === 'reboot' ||
            command.key === 'partial_reset' ||
            command.key === 'factory_reset'
          ) {
            dispatch(
              addToaster({
                title: toasterTitle || 'settings.tab_actions',
                message: t('alert_info_messages.VIPOC_INFO_013')
                  .replace('{0}', t(`settings.label_${command.key}`))
                  .replace('{1}', jobId),
                type: 'success'
              })
            );
          } else {
            dispatch(
              addToaster({
                title: toasterTitle || 'all_device_pages.menu_settings',
                message: t('alert_info_messages.VIPOC_INFO_014')
                  .replace('{0}', t(`settings.label_${command.key}`))
                  .replace('{1}', jobId),
                type: 'success'
              })
            );
          }
        }
      })
      .catch((rejection) => {
        const message: string =
          rejection === 'error_messages.permission_denied'
            ? rejection
            : 'error_messages.VIPOC_ERROR_022';
        dispatch(
          addToaster({
            title: toasterTitle || 'all_device_pages.menu_settings',
            message,
            type: 'danger'
          })
        );
      });
  };
}

/**************** PARENTAL PIN ****************/

export function setParentalPinError() {
  return {
    type: SETTINGS_PARENTAL_PIN_ERROR
  };
}

export function changeParentalPin(deviceId: string, parameter: any, hhid?: string) {
  return async function (dispatch: any, getState: () => ApplicationState) {
    dispatch({
      type: SETTINGS_COMMAND_EXECUTION_REQUEST,
      payload: 'parentalPin'
    });

    const { t } = getState().i18n;

    apiClient
      .post(
        `${envs.REACT_APP_API_URL}/integration/device/${deviceId}/household/${hhid}/change-parental-pin?pinCode=${parameter}`
      )
      .then((result: any) => {
        if (result.data.httpCode === '400') {
          const { jobId } = result.data.responseJson;
          handleCommandError(dispatch, jobId, 'all_device_pages.menu_settings', getState().i18n.t);
        } else {
          dispatch({
            type: SETTINGS_COMMAND_EXECUTION,
            payload: { field: 'new_parental_pin' }
          });
          if (result.data.responseJson.objectType === 'KalturaOTTUserDynamicData') {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_settings',
                message: t('alert_info_messages.VIPOC_INFO_012'),
                type: 'success'
              })
            );
          } else if (result.data.responseJson.error.objectType === 'KalturaAPIException') {
            dispatch(
              addToaster({
                title: 'all_device_pages.menu_settings',
                message: t('error_messages.VIPOC_ERROR_022'),
                type: 'danger'
              })
            );
          }
        }
      })
      .catch((rejection) => {
        const message: string =
          rejection === 'error_messages.permission_denied'
            ? rejection
            : 'error_messages.VIPOC_ERROR_022';
        dispatch(
          addToaster({
            title: 'all_device_pages.menu_settings',
            message,
            type: 'danger'
          })
        );
      });
  };
}
