import { DeviceShadow } from 'modules/operational/device/detail/general/redux/types'

export const RTF_DEVICE_FETCH = 'RTF_DEVICE_FETCH'
export const EMPTY_RTF_DEVICE = {
  udid: '',
  HHID: '',
  userID: '',
  kdsn: '',
  externalHHID: '',
  hardwareModel: '',
  softwareVersion: '',
  connected: false,
}

export const EMPTY_RTF_DEVICE_RESPONSE = {
  udid: '',
  householdID: '',
  userID: '',
  externalID: '',
  domainExternalId: '',
  deviceShadow: {},
}

export type RTFDevicesState = {
  current: RTFDeviceData
}

export enum RTFDevicesActions {
  setCurrent,
  clearCurrent,
}

export type RTFDevicesPayload = {
  type: RTFDevicesActions.setCurrent
  payload: RTFDeviceData
} | {
  type: RTFDevicesActions.clearCurrent
}

export type RTFDeviceDataResponse = {
  udid: string
  householdID: string
  userID: string
  externalID: string //KDSN
  domainExternalId: string // External HHID
  deviceShadow: DeviceShadow
}

export type RTFDeviceData = {
  udid: string
  HHID: string
  userID: string
  kdsn: string
  externalHHID: string
  hardwareModel: string
  softwareVersion: string
  connected: boolean
}

export type FetchRTFDevicesBody = {
  devices: Array<RTFDeviceDataResponse>
  lastEvaluatedUdid: string
}

export type AxiosFetchDeviceResponse = {
  data: FetchRTFDevicesBody
}

export type GetDeviceFunction = (udid: string) => Promise<void>

export type ClearCurrentDeviceFunction = () => void
