import React from 'react'
import { TableProps } from './types'
import './index.scss'
import WithCheckBox from './Variations/WithCheckBox'
import Simple from './Variations/Simple'

export default function Table<T, K>({ header, items, selectMultipleRows, onSortCallback, sort }: TableProps<T, K>) {
  const configMap = header.map(config => config.key)
  
  if (selectMultipleRows) {
    return <WithCheckBox configMap={configMap} selectMultipleRows={selectMultipleRows} header={header} items={items} onSortCallback={onSortCallback} sort={sort}/>
  }

  return <Simple  configMap={configMap} header={header} items={items} onSortCallback={onSortCallback} sort={sort}/>
}
