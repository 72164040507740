import { RTFSettingsTab } from 'modules/operational/rtf/config/sidebar'
import { ExecuteCommandType, RTFSettings, RunCommandsFunction } from 'modules/operational/rtf/redux/settings/types'
import React from 'react'
import FlushLogs from './components/FlushLogs'
import LogLevels from './components/LogLevels'
import LogProfiles from './components/LogProfiles'
import ActivityLog from './components/ActivityLog'
import LogsHistory from './components/LogsHistory'
import { TFunction } from 'i18next'
import { RTFDeviceData } from 'modules/operational/rtf/redux/devices/types'
import { CloseModalFunction, OpenModalFunction } from 'core/modal/redux/types'
import './index.scss'
import { ClearDownloadURLFunction, GetDownloadURLFunction, GetHistoryFunction, LogsHistoryState } from 'modules/operational/rtf/redux/logs/history/types'
import { ActivityLogState, CancelCommandFunction, ClearActivityLogStateFunction, GetActivityLogFunction } from 'modules/operational/rtf/redux/logs/activity/types'

type ContentProps = {
  tab: RTFSettingsTab
  settings: RTFSettings
  t: TFunction
  device: RTFDeviceData
  openModal: OpenModalFunction
  closeModal: CloseModalFunction
  runCommands: RunCommandsFunction
  executeCommand: ExecuteCommandType
  history: LogsHistoryState
  getHistory: GetHistoryFunction
  getDownloadURL: GetDownloadURLFunction
  clearDownloadURL: ClearDownloadURLFunction
  getActivityLog: GetActivityLogFunction
  activity: ActivityLogState
  cancelCommand: CancelCommandFunction
  clearActivityLogState: ClearActivityLogStateFunction
}

export default function Content({ 
  tab,
  settings,
  t,
  device,
  openModal,
  closeModal,
  runCommands,
  executeCommand,
  history,
  getHistory,
  getDownloadURL,
  clearDownloadURL,
  getActivityLog,
  activity,
  cancelCommand,
  clearActivityLogState,
 }: ContentProps) {
  switch (tab) {
    case RTFSettingsTab.logProfiles:
      return <LogProfiles 
        settings={settings.logProfilesFields}
        t={t}
        closeModal={closeModal}
        connected={device.connected}
        deviceId={device.udid}
        openModal={openModal}
        runCommands={runCommands}
        executeCommand={executeCommand}
      />

    case RTFSettingsTab.logLevels:
      return <LogLevels 
        settings={settings.logLevelsFields}
        t={t}
        closeModal={closeModal}
        connected={device.connected}
        deviceId={device.udid}
        openModal={openModal}
        runCommands={runCommands}
        executeCommand={executeCommand}
      />

    case RTFSettingsTab.flushLogs:
      return <FlushLogs 
        settings={settings.flushLogsFields}
        t={t}
        closeModal={closeModal}
        connected={device.connected}
        deviceId={device.udid}
        openModal={openModal}
        runCommands={runCommands}
        executeCommand={executeCommand}
      />
  
    case RTFSettingsTab.activityLog:
      return <ActivityLog
        deviceId={device.udid}
        activity={activity}
        getActivityLog={getActivityLog}
        cancelCommand={cancelCommand}
        clearActivityLogState={clearActivityLogState}
        t={t}
      />

    case RTFSettingsTab.logsHistory:
      return <LogsHistory
        history={history}
        getHistory={getHistory}
        deviceId={device.udid}
        t={t}
        getDownloadURL={getDownloadURL}
        clearDownloadURL={clearDownloadURL}
      />

    default:
      return <></>
  }
}
