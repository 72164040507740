import React from 'react'
import './index.scss'

type ButtonConainerProps = {
  children: React.ReactNode
  gridColumn?: number
}

export default function ButtonContainer({ children, gridColumn = 1 }: ButtonConainerProps) {
  return (
    <div style={{ gridColumn: `span ${gridColumn}` }}>
      <div className="rtf-button-container">
        {children}
      </div>
    </div>
  )
}
