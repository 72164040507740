import { TableObject } from './types';

export const tableDvrSelected: TableObject = {
  selectedRows: [],
  selectedRow: '',
  selectAll: false,
  hasSelectedItem: false,
  orderDirection: 'desc',
  orderIndex: 0
};

export const tablechannelSelected: TableObject = {
  selectedRows: [],
  selectedRow: '',
  selectAll: false,
  hasSelectedItem: false
};

export const tablechannelAscRegionsSelected: TableObject = {
  selectedRows: [],
  selectedRow: '',
  selectAll: false,
  hasSelectedItem: false
};

export const tablechannelAscNodesSelected: TableObject = {
  selectedRows: [],
  selectedRow: '',
  selectAll: false,
  hasSelectedItem: false
};

export const tableRegionalizationDisassociateSelected: TableObject = {
  selectedRows: [],
  selectedRow: '',
  selectAll: false,
  hasSelectedItem: false
};

export const tableRegionalizationAssociateSelected: TableObject = {
  selectedRows: [],
  selectedRow: '',
  selectAll: false,
  hasSelectedItem: false
};

export const tableAdvancedSearchSelected: TableObject = {
  selectedRows: [],
  selectedRow: '',
  selectAll: false,
  hasSelectedItem: false,
  orderDirection: 'desc',
  orderIndex: 0
};


export const tableProvisionDevices: TableObject = {
  selectedRows: [],
  selectedRow: '',
  selectAll: false,
  hasSelectedItem: false,
  orderDirection: 'desc',
  orderIndex: 0
};