import { add, remove } from 'core/spinner/redux/actions'
import { addToaster } from 'core/toaster/redux/actions'
import { Dispatch } from 'redux'
import { fetchDevice } from './services'
import { EMPTY_RTF_DEVICE_RESPONSE, RTFDeviceDataResponse, RTFDevicesActions, RTF_DEVICE_FETCH } from './types'

function getDeviceSuccess(payload: RTFDeviceDataResponse) {
  return {
    type: RTFDevicesActions.setCurrent,
    payload: {
      udid: payload.udid,
      HHID: payload.householdID,
      userID: payload.userID,
      kdsn: payload.externalID,
      externalHHID: payload.domainExternalId,
      hardwareModel: payload?.deviceShadow?.reported?.hardware?.mod || '',
      softwareVersion: payload?.deviceShadow?.reported?.software?.ver || '',
      connected: payload?.deviceShadow?.reported?.status?.connected === 'true',
    },
  }
}

function setDeviceEmpty() {
  return {
    type: RTFDevicesActions.clearCurrent,
  }
}

export function getDevice(udid: string) {
  return async function (dispatch: Dispatch) {
    try {
      dispatch(add(RTF_DEVICE_FETCH, {}))
      const { data } =  await fetchDevice(udid)
      dispatch(getDeviceSuccess(data.devices[0] || EMPTY_RTF_DEVICE_RESPONSE))
    } catch (error) {
      dispatch(addToaster({
        title: 'rtf_module.device_search',
        message: 'error_messages.VIPOC_ERROR_013',
        type: 'danger',
      }))
      dispatch(setDeviceEmpty())
    } finally {
      dispatch(remove(RTF_DEVICE_FETCH))
    }
  }
}

export function clearCurrentDevice() {
  return function (dispatch: Dispatch) {
    return dispatch(setDeviceEmpty())
  }
}
