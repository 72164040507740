import { add, remove } from 'core/spinner/redux/actions'
import { addToaster } from 'core/toaster/redux/actions'
import { Dispatch } from 'redux'
import { fetchActivityLog, sendCommandCancel } from './services'
import { ActivityLogActions, ActivityLogFilter, ActivityLogResponse, RTF_ACTIVITY_CANCEL_COMMAND, RTF_ACTIVITY_FETCH } from './types'

function getActivityLogSuccess(payload: ActivityLogResponse) {
  return {
    type: ActivityLogActions.setList,
    payload,
  }
}

function getActivityLogFailure() {
  return {
    type: ActivityLogActions.clearList,
  }
}

function clearActivityLogsList() {
  return {
    type: ActivityLogActions.clearList,
  }
}

function cancelledCommand(payload: string) {
  return {
    type: ActivityLogActions.cancelCommand,
    payload,
  }
}

export function getActivityLog(id: string, filter?: ActivityLogFilter) {
  return async function(dispatch: Dispatch) {
    try {
      dispatch(add(RTF_ACTIVITY_FETCH, {}))
      const { data } = await fetchActivityLog(id, filter)
      dispatch(getActivityLogSuccess(data))
    } catch (error) {
      dispatch(addToaster({
        title: 'rtf_module.activity_fetch',
        message: 'error_messages.VIPOC_ERROR_019',
        type: 'danger',
      }))
      dispatch(getActivityLogFailure())
    } finally {
      dispatch(remove(RTF_ACTIVITY_FETCH))
    }
  }
}

export function clearActivityLogState() {
  return function (dispatch: Dispatch) {
    return dispatch(clearActivityLogsList())
  }
}

export function cancelCommand(deviceId: string, jobId: string) {
  return async function (dispatch: Dispatch) {
    try {
      dispatch(add(RTF_ACTIVITY_CANCEL_COMMAND, {}))
      await sendCommandCancel(deviceId, jobId)
      dispatch(cancelledCommand(jobId))
    } catch (error) {
      const message = error === 'error_messages.permission_denied'
        ? error
        : 'error_messages.VIPOC_ERROR_033';

      dispatch(
        addToaster({
          title: 'all_device_pages.menu_activity_log',
          message,
          type: 'danger'
        })
      )
    } finally {
      dispatch(remove(RTF_ACTIVITY_CANCEL_COMMAND))
    }
  }
}
