import React from 'react'
import { SORT_ICONS } from '../helpers'
import { HeaderField, SortObject } from '../types'

type SortIconProps<T, K> = {
  field: HeaderField
  sort: SortObject<K>
  onSortCallback?: (sort: SortObject<T>) => void
}

type SortToggleMap = {
  [k in 'ASC' | 'DESC']: 'ASC' | 'DESC'
}


export default function SortIcon<T, K>({ field, onSortCallback, sort }: SortIconProps<T, K>) {
  const { key, sortable } = field
  const { fieldKey, order } = sort

  if (!sortable) return <></>


  if(onSortCallback) {
    const sortToggleMap: SortToggleMap = {
      ASC: 'DESC',
      DESC: 'ASC'
    }
    
    if (fieldKey === key) {
      const fieldKeyToOrder = fieldKey as unknown as keyof T
      const Icon = SORT_ICONS[order]
      return <Icon className='svg-cursor-pointer' onClick={() => onSortCallback({ fieldKey: fieldKeyToOrder, order: sortToggleMap[order]})}/>
    }
    
    const fieldKeyToOrder = key as unknown as keyof T
    return <SORT_ICONS.default className='svg-cursor-pointer' onClick={() => onSortCallback({ fieldKey: fieldKeyToOrder, order: 'ASC'})}/>
  }
}
