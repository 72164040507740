import React, { useCallback, useEffect, useState } from 'react'
import GridContainer from './common/GridContainer'
import Field from './common/Field'
import DatePicker from 'core/date/datepicker'
import moment from 'moment'
import { TFunction } from 'i18next'
import Table from 'components/Table'
import { ClearDownloadURLFunction, GetDownloadURLFunction, GetHistoryFunction, LogsHistory, LogsHistoryState } from 'modules/operational/rtf/redux/logs/history/types'
import { HeaderConfig, SortObject } from 'components/Table/types'
import Pagination from 'components/Pagination'
import { getLocalPaginatedList, getTotalPages } from 'components/Pagination/helpers'
import NoRecords from 'core/no-records'
import { sortLocalList } from 'components/Table/helpers'
import { getSelectedLogsHistoryEntries } from 'modules/operational/rtf/redux/logs/history/services'

type LogsHistoryProps = {
  t: TFunction
  history: LogsHistoryState
  getHistory: GetHistoryFunction
  deviceId: string
  getDownloadURL: GetDownloadURLFunction
  clearDownloadURL: ClearDownloadURLFunction
}

const TABLE_CONFIG: HeaderConfig = [
  {
    label: 'logs.column_file',
    key: 'filename',
    sortable: true,
    defaultSort: true,
    sortedby: 'ASC'
  },
  {
    label: 'logs.column_file_date',
    key: 'date',
    sortable: true,
  },
]

const PER_PAGE = 10

function handleSelectRows(selectedRow: number, selectedRows: Array<number>, setSelectedRows: React.Dispatch<React.SetStateAction<Array<number>>>) {
  if(selectedRows.includes(selectedRow)) {
    return setSelectedRows(selectedRows.filter(previouslySelected => previouslySelected !== selectedRow))
  }

  setSelectedRows([...selectedRows, selectedRow])
}

export default function LogsHistoryComponent({ t, history, getHistory, deviceId, getDownloadURL, clearDownloadURL }: LogsHistoryProps) {
  const [startDate, setStartDate] = useState(moment().toISOString())
  const [endDate, setEndDate] = useState(moment().toISOString())
  const [selectedRows, setSelectedRows] = useState<Array<number>>([])
  const [page, setPage] = useState(1)
  const [sort, setSort] = useState<SortObject<LogsHistory>>({
    fieldKey: TABLE_CONFIG[0].key as keyof LogsHistory,
    order: TABLE_CONFIG[0].sortedby || 'ASC',
  })
  const [list, setList] = useState(history.list)
  
  const handleOnSubmit = useCallback(() => {
      getHistory(deviceId, { start: startDate, end: endDate })
      setPage(1)
      setSort({
        fieldKey: TABLE_CONFIG[0].key as keyof LogsHistory,
        order: TABLE_CONFIG[0].sortedby || 'ASC',
      })
    },
    [startDate, endDate],
  )

  useEffect(() => {
    return () => {
      setPage(1)
    }
  }, [])

  useEffect(() => {
    setList(sortLocalList(history.list, sort.fieldKey, sort.order))
  }, [history.list])

  const onSort = (sort: SortObject<LogsHistory>) => {
    setSort(sort)
    setPage(1)
    setSelectedRows([])

    const sortedHistoryList = sortLocalList(history.list, sort.fieldKey, sort.order)    
    setList(sortedHistoryList)
  }

  const handleGenerateURL = () => {
    getDownloadURL(deviceId, getSelectedLogsHistoryEntries(list, selectedRows))
    setSelectedRows([])
    setPage(1)
  }

  const handleDownload = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    window.location.href = history.download.link
    clearDownloadURL()
  }, [history.download.link])

  return (
    <GridContainer>
      <Field>
        <div className='rtf-logs-history-header-container'>
          <div className="rtf-search-container">
            <p>{t('general.select_date')}: </p>
            <DatePicker
              isRange
              opened
              value={{
                start: startDate,
                end: endDate,
              }}
              onChangePeriod={(date: any) => {
                setStartDate(date.start)
                setEndDate(date.end)
              }}
              />
            <button className="button is-primary" onClick={handleOnSubmit}>{t('general.search')}</button>
          </div>
          <div className="rtf-download-container">
            <button className="button is-primary" disabled={!selectedRows.length} onClick={handleGenerateURL}>{t('logs.button_enable_download')}</button>
            <button className="button is-primary" disabled={!history.download.expiration || history.download.expiration < Date.now()} onClick={handleDownload}>{t('general.download')}</button>
          </div>
        </div>
        <div className="rtf-logs-history-content-container">
        {history.list.length ?
          <>
            <Table
              header={TABLE_CONFIG}
              items={getLocalPaginatedList(list, page, PER_PAGE)}
              onSortCallback={onSort}
              sort={sort}
              selectMultipleRows={{ 
                handler: handleSelectRows,
                selectedRows,
                setSelectedRows,
                currentPage: page,
                recordsPerPage: PER_PAGE,
                totalRecords: history.totalItems
              }}
            />
            <Pagination page={page} setPage={setPage} totalPages={getTotalPages(history.totalItems, PER_PAGE)} displayExtraPage={3}/>
          </>
          :
          <NoRecords t={t}/>
        }
        </div>
      </Field>
    </GridContainer>
  )
}
