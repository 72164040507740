import { CloseModalFunction, OpenModalFunction } from 'core/modal/redux/types'
import { TFunction } from 'i18next'
import { showModalAndExecCommand } from 'modules/operational/rtf/redux/settings/actions'
import { ExecuteCommandType, ResetStatesFunction, RTFFlushLogsSettings, RTFLogLevelsSettings, RunCommandsFunction } from 'modules/operational/rtf/redux/settings/types'
import React, { useCallback, useState } from 'react'
import ButtonContainer from './common/ButtonContainer'
import Field from './common/Field'
import GridContainer from './common/GridContainer'

type FlushLogsProps = {
  settings: RTFFlushLogsSettings
  t: TFunction
  deviceId: string
  connected: boolean
  openModal: OpenModalFunction
  closeModal: CloseModalFunction
  runCommands: RunCommandsFunction
  executeCommand: ExecuteCommandType
}

function handleCheckboxOnChange(flush: string, setFlush: React.Dispatch<React.SetStateAction<string>>, value: string){
  if(flush === value) {
    return setFlush('')
  }

  setFlush(value)
}

function execFlushLogs(
  deviceId: string,
  connected: boolean,
  flush: string,
  openModal: OpenModalFunction,
  closeModal: CloseModalFunction,
  runCommands: RunCommandsFunction,
  executeCommand: ExecuteCommandType,
  resetStates: ResetStatesFunction,
) {
  const commandConfig = {
    command: 'loggingFlush',
    key: 'flush_logs',
    jsonParameters: {
      mode: flush,
    }
  }

  showModalAndExecCommand(deviceId, openModal, closeModal, runCommands, commandConfig, connected, executeCommand, resetStates)
}

export default function FlushLogs({
  settings,
  t,
  deviceId,
  connected,
  openModal,
  closeModal,
  runCommands,
  executeCommand,
}: FlushLogsProps) {
  const [flush, setFlush] = useState('')

  const resetStates = useCallback(() => {
    setFlush('')
  }, [])

  return (
    <GridContainer>
      <Field>
        <div className="columns flex-wrap has-padding-xs has-margin-bottom-none">
          <div className="column is-12">
            <h6 className="text is-h6 is-highlighted">{t(`settings.label_flush_logs`)}</h6>
          </div>
          <div className="column is-12 has-padding-top-none has-padding-bottom-none">
            <h6 className="text is-h6 is-bold">{t(`settings.column_value`)}</h6>
          </div>
        </div>
        <div className="columns flex-wrap has-padding-md">
          {settings.flush.options.map((item, key: number) => (
              <div key={key} className="column has-padding-top-lg is-4">
                <div className="has-checkbox checkbox">
                  <input
                    className="is-small"
                    type="checkbox"
                    name={t(item.label)}
                    checked={flush === item.value}
                    onChange={() => handleCheckboxOnChange(flush, setFlush, item.value)}
                  />

                  <label>{t(item.label)}</label>
                </div>
              </div>
            ))}
        </div>
      </Field>
      <ButtonContainer>
        <button
          className={`button-link dropdown-link has-padding-right-md ${isDirty(flush) ? 'button-action' : ''}`}
          disabled={!isDirty(flush)}
          onClick={() => resetStates()}
        >
          {t(`general.clear`)}
        </button>
        <button
          className="button is-rounded is-larger is-primary"
          disabled={!isValid(flush)}
          onClick={() => {
            execFlushLogs(
              deviceId,
              connected,
              flush,
              openModal,
              closeModal,
              runCommands,
              executeCommand,
              resetStates,
            )
          }}
        >
          {t('settings.button_apply')}
        </button>
      </ButtonContainer>
    </GridContainer>
  )
}

function isDirty(flush: string) {
  return flush !== ''
}

function isValid(flush: string) {
  return flush !== ''
}
